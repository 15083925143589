export default {
  en: '英語',
  zh: '中文',
  home: {
    NewPortal: '新ポータル',
    SecureIBPortal: '安全なIBポータル',
    DASHBOARD: 'ダッシュボード',
    MARKETING_TOOLS: 'マーケティングツール',
    IB_REPORT: 'IBリポート',
    REBATE_REPORT: 'リベートリポート',
    IB_ACCOUNTS: 'IBアカウント',
    PAYMENT_HISTORY: '取引履歴',
    IB_FAQ: 'IB よくある質問',
    RebateAccountNumber: 'リベート口座番号',
    totalBalance: '総合収支',
    totalCommission: '合計手数料',
    APPLY_FOR_A_REBATE: '適用する',
    availableBalance: '利用可能残高',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '送金',
    MonthtoDate: '過去１カ月間',
    UPDATE: '更新',
    REBATE: 'リベート',
    TOTAL_VOLUME: '出来高合計',
    NET_FUNDING: '純資産',
    DEPOSITS: '預入金',
    WITHDRAW: '出金する',
    OPEND_ACCOUNT: '開設されたアカウント',
    PERFORMANCE: 'パフォーマンス',
    OpendAccount: '開設されたアカウント',
    select_ib: 'IBアカウントを選択してください',
    MOST_TRADED_INSTRUMENTS: '最も取引されている金融証書',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '今月のトップパフォーマンスアカウント',
    RECENTLY_OPENED_ACCOUNTS: '最近開設されたアカウント',
    ACCOUNT_MANAGER: '顧客サポート',
    Account: 'アカウント',
    Name: '名前',
    Volume: '出来高',
    Rebate: 'リベート',
    Balance: 'バランス',
    ContactClient: 'クライアントに連絡',
    YouCannotAskForCommission: "You can't ask for a commission",
    applicationFailedPleaseTryAgain:
      'アプリケーションエラーが発生しました、しばらくしてからもう一度お試しください',
    applyRebateSuccessTip:
      'おめでとうございます。リベートリクエストを受け取りました。| {amount} は1営業日以内にリベートアカウント{account} に送金されます。 | リベートが処理されたら、資金を出金したり送金したりできます。',
    HOVER: '前日のリベートは、AESTの毎日09:00～11:00の間に計算および処理されます。',
    blacklist:
      '現在、この口座でリベートをすることはできません。アカウントマネージャーまでお問い合わせください。',
    ACCOUNTS_PROFIT:
      'この IB 口座のすべての取引口座の過去の合計損益。 読み込みには少し時間がかかる場合があります。',
    PROFIT: 'アカウント損益',
    reload: '再読み込む ',
  },
  liveChat: { header: 'ライブチャット' },
  contactUs: {
    header: 'お問い合わせ',
    followUs: 'フォローする',
    tip: ' {platform}の専門的な多言語カスタマーサポートチームは、お客様を支援し、比類のない取引体験の提供を支援する準備ができています。',
    chatNow: '今すぐChatしましょう',
    globalOffice: 'グローバル Office',
  },
  ibAccounts: {
    header: 'IBアカウント',
    ibAccount: 'IBアカウント',
    subIbs: 'サブIBアカウント',
    tradinglist: 'トレーディングリスト',
    openlist: 'オープンリスト',
    closedlist: 'クローズドリスト',
    tradinglistColumn: {
      ticketid: '注文番号',
      opentime: '注文時間',
      type: '取引種別',
      volume: '数量',
      item: '通貨ペア',
      openprice: '始値',
      closeprice: '終値',
      closetime: '決済時間',
      commission: '手数料',
      profit: '損益',
      buy: '買う',
      sell: '売る',
    },
  },
  withdraw: {
    default: {
      selectCC: 'クレジットカードを選択',
      selectBA: '銀行口座を選択',
      anotherCC: 'クレジットカードを手動で入力してください',
      anotherBA: '銀行口座を追加',
      remember: '自分のアカウントを記憶',
      verificationCode:
        '認証コードが{email}へ送信されました。さらにサポートが必要な場合は、{supportEmail}へご連絡ください',
      fetchTradeAccFailed: '取引口座の取得に失敗しました',
      failed: '引き出しに失敗しました。しばらくしてからもう一度やり直してください',
      tokenExpired: 'トークンが期限切れです',
    },
    alert:
      'AML法により、出金に使用する方法は、入金に使用した方法と同じでなければなりません。引き出し金額がアカウントの基本通貨の100単位未満の場合、銀行から手数料として20単位が請求されます。',
    NetellerChargeAlert: 'Netellerは、引き出しごとに{percentageFee}％の取引手数料を請求します。',
    FasaPayChargeAlert: 'FasaPayは、引き出しごとに0.5％の取引手数料を請求します。',
    SkrillChargeAlert: 'Skrillは、引き出しごとに{percentageFee}％の取引手数料を請求します。',
    cryptoAlert:
      '当サイトがBUSDTを介して出入金をできないということにご注意ください。アドレスと仮想通貨が当サイトの取り扱っているチェーンと通貨に一致していることをお確かめください。一致していない場合、その資金が失われることになります。',
    REBATE_WITHDRAW_REQUEST: 'リベート出金リクエスト',
    PleaseSelectDesiredWithdrawMethod: '希望する引き出し方法を選択してください',
    BankName: '銀行名',
    BankAccountsName: '銀行アカウント名',
    FasaPayAccountName: 'FasaPayアカウント名',
    AustralianBankName: 'オーストラリアの銀行名',
    BankAccountNumber: '銀行の口座番号',
    FasaPayAccountNumber: 'FasaPayアカウント番号',
    BankAccountBeneficiary: '銀行口座受取人',
    BSB: 'BSB',
    skrillEmail: 'Skrill Eメール',
    NetellerEmail: 'Neteller Eメール',
    PerfectMoneyEmail: 'Perfect Money Eメール',
    SticpayEmail: 'SticPay Eメール',
    BitcoinEmail: 'Bitcoin ウォレットアドレス',
    BitwalletEmail: 'Bitwallet Eメール',
    USDTEmail: 'Tether ウォレットアドレス',
    BankBranch: '銀行支店',
    Province: '州',
    City: '市',
    BankAddress: '銀行住所',
    bankCity: '銀行の市',
    bankProvince: '銀行の都道府県',
    AccountNumberIBAN: '口座番号/IBAN',
    BankBeneficiaryName: '銀行の受取人名',
    BankAccountBeneficiaryName: '銀行口座受取人名',
    AccountHoldersAddress: '口座名義人の住所',
    SelectWithdrawCard: '出金するカードを選択してください',
    Swift: 'SWIFT',
    SwiftAud: 'SWIFT（オーストラリアアカウントではない場合）',
    ABA_SortCodeABA: 'ABA/ソートコード',
    ImportantNotes: '重要事項',
    YourPaymentGreaterThanAvailableBalance: 'お支払いが利用可能残高を超えています',
    CannotUseMoreThanTwoDecimalValues: '小数点以下2桁以上の数値と負の数値は使用できません',
    YourPaymentIsBeingProcessed: '出金申請が送信されました',
    Itcannotbe0: '0にはできません',
    noCardWarn:
      '引き出しに利用できるカードがありません、最初に引出しの詳細でカードを登録してください',
    amtLarger: '金額は空白にできません。{minLimit} {currency}以上でなければなりません',
    blackList:
      '申し訳ありませんが現在お引き出しのリクエストを送信できません。さらなる情報は{supportEmail}へお問い合わせください。',
    status: {
      withdrawalSubmitted: '提出されました',
      withdrawalCanceled: 'キャンセルされました',
      withdrawalAuditing: '処理中',
      withdrawalRejected: '却下されました',
      withdrawalAccepted: '処理中',
      withdrawalPayFailed: '失敗しました',
      withdrawalSuccess: '処理されました',
      withdrawalFailed: '失敗しました',
      withdrawalPending: '保留中',
      withdrawalPartialFailed: '失敗しました',
      transferSubmitted: '処理中',
      transferSuccess: '処理されました',
      transferRejected: '却下されました',
      transferFailed: '失敗しました',
      transferProcessing: '処理中',
      withdrawalReversedFailed: '失敗した逆',
    },
    formErrorMsg: {
      bankName: '銀行名は必須です',
      bsb: 'BSBは必須です',
      beneName: '銀行受取人の名前は必須です',
      accNum: '銀行口座番号は必須です',
      swift: 'Swiftは必須です',
      bankAddress: '銀行の住所は必須です',
      holderAddress: '所有者の住所は必須です',
      amt: '金額は必須です',
      firstSixDigits: '最初の6桁を入力してください',
      lastFourDigits: '下4桁を入力してください',
      branchName: '支店名は必須です',
      email: 'Eメールアドレスは必須です',
      accName: 'アカウント名は必須です',
      province: '銀行の州は必須です',
      city: '銀行の市は必須です',
      bankCard: 'カードを選択してください',
      emailFormat: '正しいメールアドレスを入力してください',
      amt0: '金額を0にすることはできません',
      bitcoin: 'Bitcoinンウォレットアドレスが必須です',
      addressLength:
        'ビットコインアドレスは２６～42文字の英数字からなっておりますので、もう一度お試しください。',
      usdt: 'USDTンウォレットアドレスが必須です',
      beneficiaryNameReq: '銀行受取人の名前が必要です',
    },
    transfer: {
      info1:
        '{platform}は月はじめの引き出し手数料を無料とさせていただきます。同月の追加引き落としには、引き落とし通貨の20ユニット分の銀行手数料がかかります(USC/HKD/JPY, の場合は20 USD相当額)',
      info2:
        '銀行名義はパスポートに記載されている通りに正確に入力してください（省略は禁止されています）',
      info3:
        '銀行口座はIBAN形式が望ましいですが、EU圏以外の方は銀行預金残高証明を正確に記入してください。',
      label: {
        upload: '銀行の明細書のコピー（過去3か月まで）をアップロードしてください',
        upload2:
          '取引明細書の日付または最終取引日が現在月から過去3か月内であることを確認してください。それを超える場合は、取引明細書を再度アップロードしてください。',
      },
      formValidation: { upload: '銀行取引明細書をアップロードしてください' },
      minAmount: '最低引き出し額は 100 {currency} です',
    },
    transitNumber: '乗り継ぎ番号',
    institutionNumber: '機関番号',
    beneficiaryName: '受取人名',
  },
  security: {
    contactSupport:
      '認証方法にアクセスできませんか？{contact_email}またはLivechat にお問い合わせください',
    securityAuthenticator: 'セキュリティ認証',
    verificationRequired: '確認コードを入力してください',
    resend: '再送信',
    authenticatorTooltip: 'Authenticatorアプリの検証コードは30秒ごとに更新されます',
    verifyPreEmailNote: 'コードは{email}に送信されます',
    verifyPostEmailNote: 'コードは{email}に送信されました',
    enableNow: '今すぐ有効にする',
    verificationTitle: '確認が必要です',
    verificationDesc: '続行する前にセキュリティ認証アプリを有効にしてください。',
  },
  rebate: {
    status: { processed: '処理されました', processing: '処理中', rejected: '却下されました' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'リベート転送要求',
    transferAmountIsGreaterThanAvailableBalance: '送金金額が利用可能な残高を超えています',
    yourRebateTransferHasBeenProcessed: 'リベート転送が送信されました',
    PleaseEnterTransferInformation: '正確な送金情報を入力してください',
    tradingAccount: '取引アカウント',
    rebateAccount: 'リベートアカウント',
    account: 'アカウント',
    blackList:
      'こちらの口座には口座間送金の制限がかけられています。詳しくは{supportEmail}までお問い合わせください。',
  },
  common: {
    liveChat: { desc: '助けが必要ですか？ライブチャット' },
    lang: {
      english: '英語',
      chinese: '中国語',
      french: 'フランス語',
      thai: 'タイ語',
      german: 'ドイツ語',
      spanish: 'スペイン語',
      malay: 'マレー語',
      viet: 'ベトナム語',
      indo: 'インドネシア語',
      arabic: 'アラビア語',
    },
    button: {
      iKnow: '理解しました',
      confirmCamelCase: '確認する',
      confirm: '確認する',
      submit: '提出する',
      cancel: 'キャンセル',
      sendCode: 'コードを送信',
      resendCode: 'コードを再送する',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: '出金が一時的にサポートされていません',
      limitPassword:
        'ログインパスワードが変更されたため、安全上の理由から、出金は一時的に{day}日間サポートされていません。この制限は{hour}時間後に解除される予定です。',
      limitUserLogin:
        'ログイン用メールアドレス/電話番号が変更されたため、出金は一時的に{day}日間サポートされていません。この制限は{hour}時間後に解除される予定です。',
      limitSecurityAuthenticator:
        'あなたのセキュリティ認証アプリの変更のため、アカウントのセキュリティを確保するため、一時的に引き出しは {day} 日間サポートされません。 この制限は {hour} 時間で解除される予定です。',
      banktransfer: '国際銀行振込',
      aus: '銀行振込 - オーストラリア',
      int: '銀行振込 - 海外',
      chn: '銀行振込 - 中国',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay送金',
      fasapay: 'FasaPay',
      creditcard: 'クレジットカード',
      bpay: 'BPAY',
      creditdebitcard: 'クレジット/デビットカード',
      bankwiretransferinternational: '銀行電信送金（国際）',
      banktransferaustralia: '銀行振込（オーストラリア）',
      banktransferinternational: '国際銀行振込',
      banktransferchina: '銀行振込（国際）',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'ブローカーからブローカーへの転送',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'インターネットバンキング（タイ）',
      internetbankingnigeria: 'インターネットバンキング（ナイジェリア）',
      internetbankingvietnam: 'インターネットバンキング（ベトナム）',
      internetbankingmalaysia: 'インターネットバンキング（マレーシア）',
      internetbankingindonesia: 'インターネットバンキング（インドネシア）',
      internetbankingphilippine: 'インターネットバンキング（フィリピン）',
      internetbankingsouthafrica: 'インターネットバンキング（南アフリカ）',
      internetbankingkorea: 'インターネットバンキング（韓国）',
      internetbankinguganda: 'インターネットバンキング (ウガンダ)',
      internetbankingrwanda: 'インターネットバンキング (ルワンダ)',
      internetbankingzambia: 'インターネットバンキング (ザンビア)',
      internetbankingcongo: 'インターネットバンキング (コンゴ)',
      internetbankingcameroon: 'インターネットバンキング (カメルーン)',
      internetbankingburundi: 'インターネットバンキング (ブルンジ)',
      internetbankingkenya: 'インターネットバンキング (ケニア)',
      internetbankingghana: 'インターネットバンキング (ガーナ)',
      internetbankingtanzania: 'インターネットバンキング (タンザニア)',
      internetbankingcanada: 'インターネットバンキング (カナダ)',
      internetbankingbrazil: 'インターネットバンキング (ブラジル)',
      internetbankingjapan: 'インターネットバンキング (日本)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'ベトナム銀行振込',
      nigeriabanktransfer: 'ナイジェリア銀行振込',
      polipayment: 'Poli支払い',
      bankwiretransferaustralia: '銀行電信送金（オーストラリア）',
      thailandinstantbankwiretransfer: 'タイの即時銀行電信送金',
      malaysiainstantbankwiretransfer: 'マレーシアの即時銀行電信送金',
      banktransferbpaypolipay: '銀行振込/ BPay / /PoliPay',
      cryptocurrencybitcoin: '暗号通貨-Bitcoin',
      cryptocurrencyusdt: '暗号通貨-USDT',
      cryptocurrencyethcps: '暗号通貨-ETH',
      cryptocurrencyusdccps: '暗号通貨-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'ナイジェリア銀行振込',
      vietnaminstantbankwiretransfer: 'ベトナムの即時銀行電信送金',
      indonesiainstantbankwiretransfer: 'インドネシアの即時銀行電信送金',
      philippinesinstantbankwiretransfer: 'フィリピンの即時銀行電信送金',
      southafricainstantbankwiretransfer: '南アフリカの即時銀行電信送金',
      indiainstantbanktransfer: 'インド即時銀行電信送金',
      indiabanktransfer: 'インド銀行振込',
      southkoreabanktransfer: '韓国インスタント銀行電信送金',
      ugandabanktransfer: 'ウガンダ国内銀行送金',
      rwandabanktransfer: 'ルワンダ国内銀行送金',
      zambiabanktransfer: 'ザンビア国内銀行送金',
      congobanktransfer: 'コンゴ国内銀行送金',
      cameroonbanktransfer: 'カメルーン国内銀行送金',
      burundibanktransfer: 'ブルンジ国内銀行送金',
      kenyabanktransfer: 'ケニア国内銀行送金',
      ghanabanktransfer: 'ガーナ国内銀行送金',
      tanzaniabanktransfer: 'タンザニア国内銀行送金',
      brazilbanktransfer: 'ブラジル銀行振込',
      banktransferbpaypolipayaustralia: '銀行振込/ BPay / PoliPay （オーストラリア）',
      banktransferbpaypolipayinternational: '銀行振込/ BPay / PoliPay （国際）',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'カナダの国内銀行送金',
      cryptobitcoin: '暗号通貨-Bitcoin',
      cryptotetheromni: '暗号通貨-Tether(OMNI)',
      cryptotethererc20: '暗号通貨-Tether(ERC20)',
      cryptotethertrc20: '暗号通貨-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'アカウントに転送する',
      E_WALLET: '電子財布',
      CRYPTOCURRENCY: '暗号通貨',
      LOCAL_TRANSFER: 'ローカル転送',
      japanbanktransfer: '日本の銀行に出金',
      internetbankingmexico: 'インターネットバンキング (メキシコ)',
      internetbankinguae: 'インターネットバンキング（UAE）',
      ewallet: '電子ウォレット',
      internetbankingtaiwan: 'インターネットバンキング (台湾)',
      internetbankingeu: 'インターネットバンキング（EU）',
    },
    country: { international: '海外', australia: 'オーストラリア' },
    field: {
      country_region: '国/地域',
      address: '住所',
      phone: '電話番号',
      nat: '国籍',
      country: '居住国',
      save: '投資',
      annIncome: '年収',
      emailAdd: 'Eメールアドレス',
      yes: 'はい',
      no: 'いいえ',
      enterVerificationCode: '認証コードを入力してください',
      ebuyAccName: 'EBuy口座名',
      ebuyEmailAddress: 'EBuyメールアドレス',
      skrillAccName: 'Skrill口座名',
      skrillEmailAddress: 'Skrillメールアドレス',
      netellerAccName: 'Neteller口座名',
      netellerEmailAddress: 'Netellerメールアドレス',
      imptNotes: '重要事項',
      paymentAccName: '{name} 口座名',
      paymentAccNum: '{name} 口座番号',
      accType: 'アカウントタイプ',
      accName: '口座名',
      accNum: '口座番号',
      bankBranchCode: '支店コード',
      documentType: '文書の種類',
      docID: '文書ID',
      accDigit: '口座番号',
      bankName: '銀行名',
      bankAccNumKate: 'カタカナでの銀行口座名',
      swiftCode: 'SWIFTコード',
      bankAddress: '銀行の住所',
      bankHolderAddress: '口座名義人の住所',
      bankBeneficiaryName: '銀行の受取人名',
      bankAccNum: '銀行の口座番号',
    },
    keys: {
      SUBMIT: '提出する',
      FROM: 'から',
      UPDATE: '更新',
      TO: 'まで',
      DATE: '開始日',
      pleaseSelect: '選択してください',
      amount: '金額',
      rebateAccount: 'リベートアカウント',
      comingSoon: '近日公開',
      tradingHistory: '取引履歴',
      noData: 'データなし',
      NAME: '名前',
      EMAIL: 'Eメール',
      ACCTYPE: 'アカウントタイプ',
      PLATFORM: 'プラットフォーム',
      BASECURRENCY: '基本通貨',
      DATERANGE: '日付範囲',
      STATUS: 'ステータス',
      DEST: '振込先',
      ACCNUM: '口座番号',
      BALANCE: '残高',
      PROFITLOST: '損益',
      MARGINLVL: 'マージンレベル',
      ACCEQUITY: 'アカウントの公平性',
      CREDIT: 'クレジット',
      NONE: 'なし',
      ALL: 'すべて',
      actualAmonut: '実際金額',
      currency: '通貨',
      method: '方法',
      REGISTRATION: '登録日',
      FTD: 'FTD日',
      CURRENTCAMPAIGN: '現在のキャンペーン',
      blacklisted: 'ブラックリストに載った',
      FIRST_TRANS_IN: '最初のトランスインデート',
      createTime: '提出時間',
      lastUpdateTime: '処理時間',
    },
    dateRange: {
      today: '今日',
      yesterday: '昨日',
      lastSevenDays: '過去7日間',
      lastThirtyDays: '過去30日間',
      weekToDate: '過去1週間',
      monthToDate: '過去1カ月間',
      quarterToDate: '過去の四半期',
      previousWeek: '前週',
      previousMonth: '前月',
      previousQuarter: '前四半期',
    },
    products: { forex: 'FX', commodities: 'コモディティ', indices: '指標', crypto: '仮想通貨' },
    formValidation: {
      dynamicReq: '{dynamic} は必須です',
      verCodeReq: '認証コードが必要です',
      alphanumericReq: '英数字を入力してください',
      answerReq: '選択してください',
      sameAsPOA: '情報はPOAと同じである必要があります',
      sameAsID: '情報は身分証明書と同一である必要があります',
      notBlank: '該当しない場合は空白のままにしてください',
      digits12WithoutDash: 'ハイフンなしで12桁の数字を入力してください',
      enterAsPerId: 'IDに従って入力してください',
      numberReq: '番号を入力してください',
      addressReq: '住所を入力してください',
      suburbReq: '市または郊外を入力してください',
      stateReq: '都道府県は必須です',
      postcodeReq: '郵便番号を入力してください',
      countryReq: '居住国を選択してください',
      AlphanumericOnlyNotSpace: '英数字のみ。スペースは許可されていません。',
      AlphanumericOnly: '英数字のみ。',
      NumbersSymbol: '数字と記号のみ。',
      NumbersOnlyNotSpace: '数字のみ。スペースは許可されていません。',
      NumbersOnly: '数字のみ。',
      LettersSymbol: '英字と記号のみ。',
      LettersSymbolNotSpace: '英字と記号のみ。スペースは許可されていません。',
      LettersOnlyNotSpace: '英字のみ。スペースは許可されていません。',
      NotSpace: 'スペースは許可されていません。',
    },
  },
  report: {
    title: { rebateReport: 'リベートリポート' },
    rebate: {
      column: {
        name: '名前',
        accountNumber: '口座番号',
        accountType: 'アカウントタイプ',
        volume: '出来高',
        rebate: 'リベート',
        totalRebate: 'リベート合計',
        date: '日付',
        instrument: '金融証書',
      },
      dropdown: { allInstruments: '全金融証書', all: 'すべて', instruments: '金融証書' },
      others: {
        rebate: 'リベート',
        instrumentsTraded: '取引された金融証書',
        lots: 'ロット',
        totalRebate: 'リベート合計',
        rebates: 'リベート',
      },
    },
  },
  ibReport: {
    header: 'IBリポート',
    netFunding: '純資産',
    deposits: '預入金',
    withdraw: '出金する',
    openedAccs: '開設されたアカウント',
    funding: '資金調達',
    withdrawals: '出金する',
    appliedAmount: '申請額',
    depositAmount: '入金額',
    withdrawalAmount: '出金額',
    timeLimitMessage: 'ダウンロードの最大クエリ範囲は90日です。新しい日付範囲を更新してください',
    successInfo: 'ダウンロードが成功しました',
    download: 'ダウンロード',
    comfirm: '確認',
    defaultTimeMessage: 'ダウンロードの最大クエリ範囲は90日です',
  },
  lead: { header: 'リード', ibAccount: 'IBアカウント', demoAccounts: 'デモ口座' },
  pendingAccounts: {
    header: '保留中のアカウント',
    errorDoc:
      '以下の申請者は、オンライン申請フォームに記入しましたが、アカウントを完成するための身分証明書(ID)をまだ提出していません。',
    acceptedDocType: '使用できるIDの種類は次のとおりです：',
    primaryDoc: '主要ID',
    passport: '有効な国際パスポートのコピー（署名ページ）',
    id: '政府発行の国民IDカード',
    dl: '有効な運転免許証のコピー',
    address: '補助ID',
    addressList: [
      '最近の公共料金請求書のコピー（6か月以内）',
      'クレジットカードの明細書のコピー（6か月以内）',
      '銀行取引明細書のコピー（6か月以内）',
    ],
    tip: '申請者に、承認された主要または補助ID書類のコピー1通を提出するようにしてもらってください',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '取引履歴',
    rebateHistory: 'リベート履歴',
    withdrawHistory: '出金履歴',
    transferHistory: '転送履歴',
    cancelWithdraw: '出金依頼をキャンセルしますか?',
    cancelError:
      '出金は現在処理中です。 そのため、キャンセルのお申し出はお受けできません。 詳細については、ライブ チャット機能を使用してご連絡ください。',
  },
  fileUploader: {
    supportedFormat: 'サポートされているファイル形式：png、jpg、jpeg、bmp、pdf、doc、docx',
    supportedFormatCustom: 'サポートされているファイル形式： {files}',
    maxSize: '最大アップロードファイルサイズ：{maxSize} MB',
    maxFileNum: '最大ファイル数：{maxFileNum}',
    selectFile: 'ファイルを選択',
    maxFileSize: '{maxSize}MB を超えるファイルはアップロードできません。',
    sureToDelete: {
      title: '確認はお住みですか',
      content: 'アップロードしたファイルを削除してもよろしいですか?',
    },
  },
  transferHistory: {
    payStatus: {
      processing: '処理中',
      paid: '支払済み',
      unpaid: '未払い',
      rejected: '却下されました',
    },
  },
  referralLinks: {
    header: '参照リンク',
    ibAcc: 'IBアカウント',
    campLang: 'キャンペーン言語',
    cpy: 'コピー',
    alreadyCPY: 'コピーしました！',
    liveAcc: 'ライブアカウント',
    demoAcc: 'デモアカウント',
    homePage: 'ホームページ',
    Share: 'シェア',
    subIbReferral: 'サブIB推薦',
  },
  settings: { ibProfile: 'IBプロフィール', info: '情報', reg: 'に登録済み' },
  myProfile: {
    changeDetails: '既存のプロファイルの詳細を更新したい場合は、{mailTo}までお問い合わせください。',
  },
  unfundedAcc: {
    header: '資金提供されないアカウント',
    allActivationAccs: '有効化されたすべてのアカウント',
    neverFundedAccs: '決して資金提供されないアカウント',
    prevFundedZero: 'ゼロバランス',
  },
  multiib: { level: 'レベル {level}', noib: 'このアカウントにはサブIBはありません。' },
  header: { bckToCP: 'クライアントポータルに戻る', logout: 'ログアウト' },
  menu: {
    dashboard: 'ダッシュボード',
    ibReport: 'IBリポート',
    rebateReport: 'リベートリポート',
    accManagement: 'アカウント',
    iblevel: 'マルチレベル',
    ibAccs: '活性化されたアカウント',
    leads: 'リード',
    pendingAccs: '保留中のアカウント',
    unfundedAccs: '資金提供されないアカウント',
    payHistory: '取引履歴',
    ibProfile: 'IBプロフィール',
    refLinks: '参照リンク',
    contactUs: 'お問い合わせ',
    switchCP: 'クライアントポータルに切り替える',
  },
  footer: {
    riskWarn:
      'リスク警告：CFD取引はファンドに高いリスクをもたらし、損失を引き起こす可能性があります。損失に耐えることができるファンドとのみ取引する必要があります。 CFD取引は、すべての投資家に適しているとは限りません。関連するリスクを十分に理解し、適切な対策を講じて管理してください。 関連するリスク開示文書を注意深くお読みください。',
  },
  responseMsg: {
    410: 'パラーメータの検証に失敗しました',
    411: '不正なパスワード',
    420: 'ユーザーが存在しません',
    421: 'パスワードのリセットが拒否されました',
    431: 'リベートの計算が進行中です。しばらくしてから再度お試しください',
    490: '重複したカード情報を提出しないでください',
    500: 'エラーが発生しました。',
    501: 'アプリケーションが失敗しました。後でもう一度やり直してください。',
    505: 'ファイルのアップロードに失敗しました',
    520: 'ログインに失敗しました。もう一度やり直してください。',
    521: 'ユーザーは既にこのデバイスにログインしています！',
    522: 'もう一度ログインしてください',
    523: 'もう一度ログインしてください',
    524: '残念ながらあなたのEメールアドレスは認識されませんでした',
    525: 'ユーザーネームまたはパスワードが違います',
    527: 'ユーザーがIBではありません',
    528: 'ユーザーが存在しません',
    529: 'ユーザーネームまたはパスワードが違います',
    530: 'ログインセッションが無効です',
    540: '取引口座が見つかりませんでした',
    541: 'リベートアカウントが見つかりません',
    542: '追加のアカウントを開くことは許可されていません',
    544: 'お客様は、身分証明書が認証されるまで追加のアカウントを申請することができません。',
    550: '口座番号が間違っています',
    551: '残高が不足しています',
    553: 'アカウントのクレジットにより、出金・送金できません。さらにサポートが必要な場合は、{email}にお問い合わせください',
    554: 'リクエストされた金額はゼロまたは空白です',
    564: '国名が決済チャネルと一致しません',
    565: '不正な銀行コード',
    566: 'お支払い方法が見つかりません',
    581: 'プロモーションへのオプトインに成功しました。資金を引き出すには、預金をする必要がありますのでご注意ください',
    582: 'あなたはイベントに参加しており、ポジションを持っているため、退会申請を提出することはできません。',
    584: '該当するリベートはありません',
    590: '送金口座が見つかりません',
    591: '入金口座が見つかりません',
    593: 'お客様の口座には預金があるため、選択した口座からの資金送金手続きを行うことができませんでした。',
    594: 'この口座からの資金の送金は禁止されています',
    595: 'あなたは私たちの活動に参加しているので、あなたの転送要求は現在処理できません。',
    647: '認証コードが正しくありません',
    1101: 'IB共有口座が10個ありますが、追加できません。',
    1102: '有効にできません。アカウントはライブ アカウントまたはデモ アカウントとして作成されています。',
    1103: 'アカウントが無効になりました',
    1202: '検証が期限切れです、もう一度お試しください',
    1204: '別の電子メールアドレスでお試しください',
    1208: '確認コードは繰り返し送信できません',
    1209: '検証コードの送信エラー',
    session_timeout: 'セッションタイムアウト、ログインページにリダイレクトします。',
  },
  verificationStatus: {
    title: { default: 'あと少しで完了します', LV3: 'ようこそ' },
    dueToAML:
      'すぐに取引を開始する準備ができます！お客様の個人情報を確認することで、お客様の口座を保護することができます。',
    accOpeningVerification: {
      title: 'ライブ口座の開設',
      content: 'ライブ口座を作成して、アカウントへの資金提供を開始してください。',
    },
    identityVerification: {
      title: '本人確認',
      content: '取引を開始するには、本人確認書類を提出して確認してください。',
    },
    poaVerification: {
      title: '住所の認証',
      content: '出金を行う前に POA を確認する必要があります。',
    },
    kyc: {
      depositNow: '今すぐ入金',
      withdrawNow: '今すぐ出金する',
      createNow: '今すぐ口座を開設する',
      start: '開始',
      complete: '完了',
      pending: '保留中',
      rejected: '却下',
      resubmit: '再提出',
    },
    resubmitNote:
      'お客様の書類は内容が不足しているか、間違っている可能性があります。再提出して下さい。',
    rejectedNote: '質問はございませんか？ {liveChat} を通してご連絡ください。',
    liveChat: 'ライブチャット',
    uploadTemplate: {
      idGuideTitle: '身分証明書のガイドライン',
      uploadGuideTitle: 'ガイドラインをアップロード',
      desc1: '政府発行',
      desc2: 'オリジナルのフルサイズの未編集のドキュメント',
      desc3: '単色の背景にドキュメントを配置する',
      desc4: '読みやすい、明るい、色のついた画像',
      desc5: '白黒画像は不可',
      desc6: '編集済み、期限切れの文書は不可',
      bottomDesc:
        'アップロードできるファイルはjpg、png、pdfで、ファイルサイズは5MBを超えることはできません。',
    },
  },
  register: {
    progressStatus: { title: '検証の進行状況', estimatedTimeTitle: '予定時刻', min: '分' },
    tabs: {
      personalDetails: '個人の詳細',
      moreAboutYou: 'あなたについての詳細',
      accountConfig: 'アカウント設定',
      confirmYourId: 'IDを確認',
      fundYourAcc: 'アカウントに資金を入金する',
      personalInformation: '個人情報',
      createLiveAccount: 'ライブ口座の開設',
      financialInfo: '財務情報',
      iDProof: '身分証明書',
      addressProof: '住所証明',
    },
    btn: {
      next: '次',
      back: '戻る',
      fundnow: '今すぐ資金を入金',
      upload: 'アップロードする',
      done: '完了',
      bTCP: 'クライアントポータルに戻る',
      proceedToUpload: 'アップロードに進む',
      completeID: '本人認証を完了',
      completePOA: 'POA検証を完了する',
      depositViaOther: '他の方法で入金する',
      withdrawViaOther: '他のチャネル経由での出金',
    },
    authority: {
      dialog1:
        'クライアントポータルのこのセクションにアクセスするには、ライブアカウントが必要です。',
      dialog2:
        '今すぐライブアカウントを開設して、さまざまな取引ツールやプロモーションなどを含むクライアントポータルへのフルアクセスのロックを解除してください。',
      openLiveAcc: 'ライブ口座を開設する',
    },
    demo: {
      congrad: 'おめでとうございます！',
      title: '60日間のDEMO MT{platform} アカウントが有効になりました',
      title2: 'あなたのDEMOアカウントの詳細',
      li: { login: 'ログイン', srv: 'サーバー', expDate: '有効期限' },
      emailSent: 'ログインの詳細は、登録したEメールにも送信されました。',
      dl: 'MT {platform}取引プラットフォームをダウンロード',
      improveQ: '取引を改善したいですか？',
      improveWay:
        'ライブ口座をわずか数分で開設するだけで、さまざまな独占取引ツールやプロモーションのロックを解除できます。',
      proTrade: 'プロのマーケティングレポート',
      customerService: '24時間年中無休の1対1のカスタマーサービス',
      competitivePromotionals: '競争力のあるプロモーション',
      secure: '3Dセキュアトランザクション',
      smartTradeTool: 'スマートトレーダーツール',
      openLiveAcc: 'ライブ口座を開設する',
    },
    personalDetails: {
      page1: {
        pageTitle: '個人の詳細',
        userTitle: '称号',
        firstName: '名',
        lastName: '姓',
        email: 'Eメール',
        dob: '生年月日',
        idType: 'ID種類',
        referral: '誰かに紹介されましたか？（オプション）',
        middleName: 'ミドルネーム',
        nationality: '国籍',
        phoneNum: '電話番号',
        date: '日',
        month: '月',
        year: '年',
        placeOfBirth: '出生地',
        idNum: 'ID番号',
        passportNum: 'パスポート番号',
        driverLicenseNum: '運転免許証番号',
        titleDropdown: { mr: '様', mrs: '様', ms: '様', miss: '様', dr: '医師', prof: '教授' },
        idTypeDropdown: {
          passport: 'パスポート',
          idCard: '国民IDカード',
          driverLicense: '運転免許証',
        },
      },
      page2: {
        pageTitle: '本住居住所',
        mainResidency: '主な居住国',
        streetNumber: '街路番号',
        streetName: '通りの名前',
        address: '住所',
        provinceOrState: '都道府県',
        cityOrSuburb: '市/郊外',
        postcode: '郵便番号',
        usQuestion:
          'あなたは税務上の米国籍/居住者ですか、それとも米国の関係（銀行口座など）がありますか？',
      },
    },
    moreAboutYou: {
      pageTitle1: '雇用と財務の詳細',
      pageTitle2: 'トレーディングの知識と経験',
      complianceExplain: '以下の質問は、AML / CTF要件に準拠するために収集されます。',
    },
    accountConfig: {
      pageTitle: 'アカウント設定',
      chooseTradingPlatform: '取引プラットフォームを選択',
      chooseAccType: 'アカウントの種類を選択',
      chooseAccCurrency: 'アカウント通貨を選択',
    },
    confirmYourId: {
      pageTitle: 'IDを確認',
      description:
        '規制対象のFXブローカーとして、当社は、あなたの身元を確認する必要があります。身分証明書と住所証明書の確認のために、次のいずれかのドキュメントをアップロードしてください。または、確認書類を{mailTo}にEメールで送信することもできます',
      poidTitle: '身元の証明',
      porTitle: '居住証明書',
      uploadDescription: 'ドキュメントタイプを選択し、適切にアップロードしてください',
      note: '重要：文書の名前には、氏名を明確に示す必要があります。',
      pending: { upload: 'アップロード保留中', verification: '確認保留中' },
      poidTypes: { photoId: '写真付きID', passport: 'パスポート', drivingLicence: '運転免許証' },
      porTypes: '<li>公共料金</li>\n      <li>銀行取引明細書</li>\n      <li>住民票</li>',
    },
    finishPage: {
      title:
        '{individualUserName}様、{platformName}のライブアカウントにお申し込みいただきありがとうございます！',
      contentDefault:
        'お申し込みを受け取り、ライブアカウント<b>{account}</b>を設定しました。 <br />\n     まもなく、ログインの詳細とアカウントを表示するためのリンクが記載されたEメールが<b>{email}</b> に届きます <br />',
      contentWithoutId3WithAccount:
        'ライブ取引口座は現在処理中です。まもなく詳細情報が記載されたEメールが届きます。<br />\n      ライブ口座処理を迅速に追跡するために、今すぐ口座に資金を入金し、優先的な開設を受け取ることができます。<br />',
      contentWithoutId3WithoutAccount:
        'ライブ取引口座は現在処理中です。まもなく詳細情報が記載されたEメールが届きます。<br />',
    },
    accOpeningVerification: {
      accOpenVer: '口座開設の認証',
      personalInformation: {
        narrative: 'ライブ口座を開設するには個人情報が必要です',
        firstName: '氏名',
        middleName: 'ミドルネーム',
        lastName: '名',
        gender: '性別',
        genderList: { male: '男性', female: '女性', other: 'その他' },
        dob: '生年月日',
        day: '日',
        month: '月',
        year: '年',
        mobile: '携帯電話番号',
        countryName: '居住国',
        nationality: '国籍',
      },
      createLiveAccount: {
        narrative:
          'いくつかの手順を完了して口座を開設し、資金調達を開始しましょう。アカウントの種類の詳細については、<a href="{link}" target="_blank" alt>こちらをご覧ください</a>。',
        choose: '{dynamic}',
        tradingPlatform: 'その他の取引プラットフォームを選択する',
        accountType: '口座の種類を選択してください',
        accountCurrency: '口座の通貨の選択',
        islamicTooltip:
          '{platform}では、イスラム教の信仰を持っていることを証明するための資料の提出を要求する場合があります。{platform} では、検証が失敗した場合にアカウント設定を修正する権利を留保します。',
        successInfo: {
          title: '取引口座の作成',
          content:
            'おめでとうございます！無事に口座開設が完了しました。<br /> 取引を開始するには、今すぐ入金して本人確認を完了してください。',
        },
        successInfo2: {
          title: '取引口座の作成',
          content: '財務プロフィール情報を入力して、口座の検証の次のステップを開始してください',
          start: '開始',
        },
        generalInfo: {
          title: '提出していただいた情報の審査中です。',
          content:
            '{platform}の口座を開設していただきありがとうございます。提出していただいた情報が承認されると、口座への資金提供を開始できます。',
        },
        rejectedInfo: {
          title: '却下',
          content:
            '残念ながら、 {platform} の口座開設のリクエストが却下されたことをお知らせいたします。',
        },
      },
    },
    identityVerification: {
      title: '本人確認',
      subTitle: '身元情報',
      narrative: '詳細を入力してください',
      firstName: '氏名',
      middleName: 'ミドルネーム',
      lastName: '名',
      nationality: '国籍',
      idType: '身分証明書の種類',
      idSerialNumber: '身分証明書シリアル番号',
      passportNum: 'パスポート番号',
      driverLicenseNum: '運転免許証番号',
      upload: {
        title: '書類をアップロード',
        desc: '書類に写真、氏名、生年月日、発行日が記載されていることを確認してください。',
        guideline: 'ガイドラインをアップロード',
        positive: 'アップロードポジティブ',
        signaturePage: '署名のアップロードページ',
      },
      idTypeDropdown: {
        passport: 'パスポート',
        idCard: '国民IDカード',
        driverLicense: '運転免許証',
        other: 'その他',
      },
      additionalInfo: {
        title: '追加の情報が必要です',
        content: '取引を開始するには本人確認を行ってください',
      },
      successInfo: {
        title: '本人確認完了',
        content:
          'おめでとうございます！あなたの身分証明書は承認されました。すでに入金済みの方は、MT4/MT5または {platform} アプリで取引を開始することができます。',
      },
      generalInfo: {
        title: '提出していただいた情報の審査中です。',
        content:
          '{platform} の口座を開設していただきありがとうございます。身元情報が確認されると取引を開始できます。',
      },
      rejectedInfo: {
        title: '却下',
        content:
          '残念ながら、 {platform} の口座開設のリクエストが却下されたことをお知らせいたします。',
      },
      identifyingRequiredInfo: {
        title: '追加の情報が必要です',
        content:
          '本人確認書類の一部が不足しているか、間違っている可能性があります。続行するには、再アップロードしてください。',
      },
      streetNumber: '街路番号',
      streetName: '通りの名前',
    },
    poaVerification: {
      proofOfAddressVer: '住所の認証',
      subTitle: '住所情報',
      narrative: '詳細を入力してください',
      dueToAML: 'AML要件により、引き出しを行う前に住所を確認する必要があります。',
      nationality: '国籍',
      country: '居住国',
      address: '住所',
      postalCode: '郵便番号',
      citySuburb: '市区町村',
      city: '市区町村',
      provinceState: '都道府県',
      upload: {
        title: 'POA証明書',
        inst1: '公共料金請求書(電気、水道、ガス)',
        inst2: '銀行またはクレジットカードの明細書',
        desc: '出金用のPOA情報は過去 {months} か月以内に発行されたものである必要があります',
        guideline: 'ガイドラインをアップロード',
        uploadFilesPhoto: 'ファイル/写真をアップロードする',
      },
      additionalInfo: {
        title: '追加の情報が必要です',
        content: '出金を行う前に POA を確認する必要があります。',
      },
      successInfo: {
        title: '住所証明が確認されました',
        content: 'おめでとうございます！POAが確認されました。出金ができるようになりました。',
      },
      generalInfo: {
        title: '提出していただいた情報の審査中です。',
        content:
          '{platform} と取引していただきありがとうございます。提出が承認されると、取り消しプロセスを開始できます。',
      },
      identifyingRequiredInfo: {
        title: '追加の情報が必要です',
        content:
          '本人確認書類の一部が不足しているか、間違っている可能性があります。続行するには、再アップロードしてください。',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'お客様の財務情報',
        subTitle: '雇用と財務の詳細',
        subTitle2: '取引の知識と経験',
        employmentStatus: '雇用形態',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '週あたりの{title}',
        estimatedAnnualIncome: '推定年収',
        estimatedSavingsAndInvestments: '推定貯蓄額と投資額',
        estimatedIntendedDeposit: '推定予定入金額',
        sourceOfFunds: '資金源',
        numberOfTrades: '取引数',
        tradingAmount: '取引額',
      },
      iDProof: {
        ver: '身分証明をアップロードする',
        subTitle: '身元情報',
        narrative: '詳細を入力してください',
      },
      addressProof: {
        ver: '住所証明（POA）をアップロードする',
        subTitle: '住所情報',
        narrative: '詳細を入力してください',
        upload: {
          title: '住所を証明する書類として認められているもの',
          inst1: '公共料金請求書(電気、水道、ガス)',
          inst2: '銀行またはクレジットカードの明細書',
          desc: '出金用のPOA情報は過去 {months} か月以内に発行されたものである必要があります',
          guideline: 'ガイドラインをアップロード',
          uploadFilesPhoto: 'ファイル/写真をアップロードする',
        },
      },
      successInfo: {
        title: '銀行認証が完了しました',
        content: 'おめでとうございます！銀行口座の詳細が正常に確認されました。',
      },
      generalInfo: {
        title: '提出していただいた情報の審査中です。',
        content:
          '銀行送金のための書類はまだ審査中です。すぐに取引を開始したい場合は、別のチャネルを介して入金することもできます。',
      },
      rejectedInfo: { title: '却下', content: '銀行振込による入金のリクエストは却下されました。' },
      identifyingRequiredInfo: {
        title: '追加の情報が必要です',
        content:
          '本人確認書類の一部が不足しているか、間違っている可能性があります。続行するには、再アップロードしてください。',
      },
    },
  },
  ibProfile: {
    updateSharedIb: {
      addNewSharedIb: '新しいIB共有口座を追加する ',
      editSharedIb: 'IB共有口座の編集',
      maxSharedIbAccountError: 'IB共有アカウントが10個ありますが、追加できません。',
      email: 'IB共有口座のメールアドレス',
      emailValidate: '有効なメールアドレスを入力してください',
      emailError: 'メールアドレスは既に存在しますが、別のメールアドレスを試してください',
      password: 'パスワード',
      passwordValidate: 'パスワードを入力してください',
      newPassword: '新しいパスワードを入力してください',
      passwordValidateNum: '8〜16文字',
      passwordValidateTips: '大文字と小文字の英字(A-Z、a-z)、数字(0-9)、特殊文字(例:!@#S%^&)',
      passwordValidateWarning:
        '8〜16文字,大文字と小文字の英字(A-Z、a-z)、数字(0-9)、特殊文字(例:!@#S%^&)',
      sharedIbAccountPermission: 'IB共有口座の権限',
      remark: '備考',
      remarkTips: 'アカウントの目的またはアカウント所有者を記録できます',
      status: 'IB共有口座のステータス',
      cancel: 'キャンセル',
      confirm: '確認',
      disabled: '無効',
      enabled: '有効',
      resetPasswordSuccess: 'パスワードリセット成功',
      resetPassword: 'パスワードリセット',
      emailPlaceholder: 'メールアドレスを入力してください',
      addSuccess: '共有口座が作成されました',
      permissionIdsTips: '共有口座に対して以下の権限を許可するかどうかを設定できます：',
      permissionIdsFirst:
        '<b>口座データ</b>：口座データページ（IB口座、最近開設した口座）を閲覧できます。権限が許可されていない場合、データは非表示になります。',
      permissionIdsSecond:
        '<b>IBレポートデータ</b>：IBデータページ（ダッシュボード、マルチレベルIB - 口座）を表示します。権限が許可されていない場合、データは非表示になります。',
      permissionIdsThird:
        '<b>資金許可</b>：IB資金の操作（リベート申請、リベート送金、リベート出金）、リベートの表示 (リベート レポート、残高、および資金レコード)。許可されていない場合は操作できません。',
      newPassowrdReset: '新しいパスワードを入力してください',
      passwordSame: '新しいパスワードは以前のパスワードと同じにすることはできません。',
      enterNewPassword: '新しいパスワードを入力してください',
      changePasswordTips: '以前のパスワードは無効となり、新しいパスワードにリセットされます',
      fundsChangeTips:
        '資金許可が下りると、IB共有口座はメインIB口座の残高を移したり、出金したりできるようになります。許可しますか？',
      editSuccess: '変更成功',
    },
    title: 'IBプロフィール',
    subTitle: '共有IB',
    sharedIbList: {
      sharedIbAccount: 'IB共有口座',
      maxSharedIbAccount: 'IB共有口座は最大10個',
      add: '口座追加',
      accountHistory: 'IB共有口座履歴',
      setting: '設定',
      delete: '削除',
      enable: '有効にする',
      disable: '無効にする',
      enabled: '有効',
      disabled: '無効',
      sharedAccountEnabled: 'IB共有口座が有効になりました。',
      sharedAccountDisabled: 'IB共有口座は無効化されています。',
      sharedAccountCopied: 'IB共有口座がコピーされました ',
      deleteTips: 'IB共有口座は削除するとログインできなくなりますが、削除しますか？',
      sharedAccountDeleted: 'IB共有口座は削除されました。',
      copyTips: 'IB共有口座がコピーされました ',
      resetPassword: 'パスワードリセット',
      remark: '備考',
      cancel: 'キャンセル',
      accountData: '口座データ',
      IBData: 'IBレポートデータ',
      fundOperations: '資金許可',
      accountHistoryBtn: 'IB共有口座履歴',
    },
    sharedIbHistory: {
      allHistory: 'すべての履歴が表示されました',
      lastUpdate: '最終更新日',
      refreshTips: '履歴が更新されました',
      refresh: 'リフレッシュ',
      chooseAccount: 'アカウントを選択',
      device: 'デバイス',
      login: 'ログイン',
      commission: 'リベート申請',
      withdraw: 'リベートを出金',
      transfer: 'リベートを振込',
      fromTo: '{fromAccount}から{toAccount}まで、金額{total}',
      viewMore: '詳しく見る',
    },
    npPermissionTips: 'この口座は許可されていません',
  },
  agreement: {
    title: 'ブローカー契約のご紹介',
    rebateAccount: 'リベート口座',
    description: '同意する]をクリックすることで、同意書に記載されているすべての情報、サービス規約および条件を読み、理解し、同意したことを確認します。',
    agree: '同意する',
    signed: 'リベート口座 {accountID}. 署名： {signedTime}',
    IBAgreement: 'IB契約',
    confirmAgreement: 'IB契約を確認するには、IBマネージャーまでご連絡ください。'
  }
};
