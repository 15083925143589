export default {
  en: 'ภาษาอังกฤษ',
  zh: 'ภาษาจีน',
  home: {
    NewPortal: 'พอร์ทัลลูกค้าใหม่',
    SecureIBPortal: 'พอร์ทัล IB แบบปลอดภัย',
    DASHBOARD: 'แดชบอร์ด',
    MARKETING_TOOLS: 'เครื่องมือทางการตลาด',
    IB_REPORT: 'รายงาน IB',
    REBATE_REPORT: 'รายงานค่าคอมมิชชั่น',
    IB_ACCOUNTS: 'บัญชี IB',
    PAYMENT_HISTORY: 'ประวัติการทำธุรกรรม',
    IB_FAQ: 'คำถามที่พบบ่อยเกี่ยวกับผู้แนะนำโบรกเกอร์ (IB)',
    RebateAccountNumber: 'บัญชี IB',
    totalBalance: 'ยอดคงเหลือในบัญชีทั้งหมด\r\n',
    totalCommission: 'ค่าคอมมิชชั่นทั้งหมด',
    APPLY_FOR_A_REBATE: 'ส่งคำร้องเพื่อรับค่าคอมมิชชั่น',
    availableBalance: 'ยอดเงินคงเหลือ',
    WITHDRAW_REBATE: 'สั่งถอนค่าคอมมิชชั่น',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'โอนรีเบตไปยังบัญชีของคุณ',
    MonthtoDate: 'เดือนนี้',
    UPDATE: 'อัปเดตข้อมูล',
    REBATE: 'ค่าคอมมิชชั่น',
    TOTAL_VOLUME: 'ปริมาณการซื้อขายทั้งหมด',
    NET_FUNDING: 'ยอดฝากสุทธิ',
    DEPOSITS: 'ยอดฝากทั้งหมด',
    WITHDRAW: 'ยอดถอนทั้งหมด',
    OPEND_ACCOUNT: 'จำนวนบัญชีที่เปิด',
    PERFORMANCE: 'ผลงาน',
    OpendAccount: 'จำนวนบัญชีที่เปิด',
    select_ib: 'โปรดเลือกบัญชี IB',
    MOST_TRADED_INSTRUMENTS: 'ผลิตภัณฑ์ยอดนิยม',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'บัญชีที่ทำผลงานสูงสุดสำหรับเดือนนี้',
    RECENTLY_OPENED_ACCOUNTS: 'บัญชีที่เพิ่งเปิดล่าสุด',
    ACCOUNT_MANAGER: 'ฝ่ายบริการลูกค้า',
    Account: 'บัญชี',
    Name: 'ชื่อ',
    Volume: 'ปริมาณการซื้อขาย',
    Rebate: 'ค่าคอมมิชชั่น',
    Balance: 'ยอดคงเหลือ',
    ContactClient: 'ติดต่อลูกค้า',
    YouCannotAskForCommission: 'ท่านไม่สามารถรับค่าคอมมินชั่นได้',
    applicationFailedPleaseTryAgain: 'การสมัครล้มเหลว กรุณาลองใหม่อีกครั้ง',
    applyRebateSuccessTip:
      'ขอแสดงความยินดี เราได้รับคำสั่งถอนค่าคอมมิชชั่นของท่านแล้ว |เงินจำนวน {amount} จะถูกโอนไปยังบัญชีรับค่าคอมมิชชั่น {account} ภายใน 1 วันทำการ | โดยท่านสามารถถอนหรือโอนเงินได้เมื่อค่าคอมมิชชั่นได้ถูกดำเนินการเรียบร้อยแล้ว',
    HOVER:
      'ค่าคอมมิชชั่นของวันก่อนหน้าจะถูกคำนวณและดำเนินการระหว่างเวลา 09:00 ถึง 11:00 ตามเวลาของประเทศออสเตรเลียในแต่ละวัน',
    blacklist: 'ไม่สามารถรับเงินคืนเข้าไปยังบัญชีนี้ได้ในขณะนี้ โปรดติดต่อผู้จัดการบัญชีของคุณ',
    ACCOUNTS_PROFIT:
      'กำไร/ขาดทุนรวมในอดีตของบัญชีซื้อขายทั้งหมดด้วยบัญชี IB นี้ การโหลดอาจใช้เวลาสักครู่',
    PROFIT: 'กำไร/ขาดทุนของบัญชี',
    reload: 'โหลดใหม่',
  },
  liveChat: { header: 'ไลฟ์แชท' },
  contactUs: {
    header: 'ติดต่อเรา',
    followUs: 'ตามเรามา',
    tip: 'ทีมสนับสนุนลูกค้าของ {platform} ที่พูดได้หลายภาษาแบบมืออาชีพพร้อมที่จะช่วยเหลือท่านและช่วยให้ท่านได้รับประสบการณ์การซื้อขายที่หาที่เปรียบไม่ได้\r\n',
    chatNow: 'มาคุยกันเลยตอนนี้',
    globalOffice: 'สำนักงานทั่วโลก',
  },
  ibAccounts: {
    header: 'บัญชี IB',
    ibAccount: 'บัญชี IB',
    subIbs: 'บัญชี IB ย่อย',
    tradinglist: 'รายการธุรกรรม',
    openlist: 'เปิดเมนู',
    closedlist: 'ปิดรายการแล้วนะคะ',
    tradinglistColumn: {
      ticketid: 'รหัสตั๋ว',
      opentime: 'เวลาเปิดทำการ',
      type: 'พิมพ์',
      volume: 'ปริมาณ',
      item: 'รายการ',
      openprice: 'ราคาเปิด',
      closeprice: 'ราคาปิด',
      closetime: 'เวลาปิด',
      commission: 'คณะกรรมการ',
      profit: 'กำไร',
      buy: 'ซื้อ',
      sell: 'ขายแล้ว',
    },
  },
  withdraw: {
    default: {
      selectCC: 'เลือกบัตรเครดิต',
      selectBA: 'เลือกบัญชีธนาคาร',
      anotherCC: 'กรอกบัตรเครดิตด้วยตนเอง',
      anotherBA: 'เพิ่มบัญชีธนาคาร',
      remember: 'จดจำบัญชีของฉัน',
      verificationCode:
        'รหัสการยืนยันได้ถูกส่งไปที่ {email} แล้ว โปรดติดต่อ {supportEmail} หากคุณต้องการความช่วยเหลือเพิ่มเติม',
      fetchTradeAccFailed: 'การเรียกบัญชีซื้อขายล้มเหลว',
      failed: 'ถอนเงินล้มเหลว โปรดลองอีกครั้ง',
      tokenExpired: 'Token หมดอายุ',
    },
    alert:
      'เนื่องจากกฎหมาย AML วิธีที่คุณใช้ในการถอนต้องเป็นวิธีเดียวกับวิธีที่ใช้ในการฝากเงินของคุณ หากจำนวนเงินที่ถอนน้อยกว่า 100 หน่วยของสกุลเงินหลักของบัญชีคุณจะถูกเรียกเก็บค่าธรรมเนียม 20 หน่วยจากธนาคาร',
    NetellerChargeAlert:
      'Neteller เรียกเก็บค่าธรรมเนียมการทำธุรกรรม {percentageFee}% สำหรับทุกการถอน',
    FasaPayChargeAlert: 'FasaPay เรียกเก็บค่าธรรมเนียมการทำธุรกรรม 0.5% สำหรับการถอนทุกครั้ง',
    SkrillChargeAlert: 'Skrill เรียกเก็บค่าธรรมเนียมการทำธุรกรรม {percentageFee}% สำหรับทุกการถอน',
    cryptoAlert:
      'โปรดทราบว่าเราไม่สามารถฝากหรือถอนผ่าน BUSDT ได้ โปรดตรวจสอบให้แน่ใจว่าที่อยู่และสกุลเงินดิจิทัลตรงกับห่วงโซ่และสกุลเงินที่เรายอมรับ มิฉะนั้นคุณอาจสูญเสียเงิน',
    REBATE_WITHDRAW_REQUEST: 'คำร้องขอถอนเงินค่าคอมมิชชั่น',
    PleaseSelectDesiredWithdrawMethod: 'กรุณาเลือกช่องทางการถอนเงินที่ต้องการ',
    BankName: 'ชื่อธนาคาร',
    BankAccountsName: 'ชื่อบัญชี',
    FasaPayAccountName: 'ชื่อบัญชี FasaPay',
    AustralianBankName: 'ชื่อธนาคารออสเตรเลีย',
    BankAccountNumber: 'เลขบัญชี',
    FasaPayAccountNumber: 'เลขบัญชี FasaPay',
    BankAccountBeneficiary: 'ธนาคารผู้รับผลประโยชน์',
    BSB: 'รหัส BSB',
    skrillEmail: 'อีเมล Skrill',
    NetellerEmail: 'อีเมล Neteller',
    PerfectMoneyEmail: 'อีเมล Perfect Money',
    SticpayEmail: 'อีเมล SticPay',
    BitcoinEmail: 'ที่อยู่ Bitcoin Wallet',
    USDTEmail: 'ที่อยู่ Tether Wallet',
    BankBranch: 'สาขาของธนาคาร',
    Province: 'จังหวัด',
    City: 'เมือง',
    BankAddress: 'ที่อยู่ธนาคาร ',
    bankCity: 'เมือง',
    bankProvince: 'จังหวัด',
    AccountNumberIBAN: 'เลขบัญชี/IBAN',
    BankBeneficiaryName: 'ชื่อผู้รับผลประโยชน์',
    BankAccountBeneficiaryName: 'ชื่อธนาคารผู้รับผลประโยชน์',
    AccountHoldersAddress: 'ที่อยู่ของเจ้าของบัญชี',
    SelectWithdrawCard: 'เลือกบัตรสั่งถอน',
    Swift: 'รหัส Swift',
    SwiftAud: 'Swift (ไม่ใช่บัญชีสกุลเงินดอลลาร์ออสเตรเลีย)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: 'หมายเหตุสำคัญ',
    YourPaymentGreaterThanAvailableBalance: 'การชำระเงินของท่านมีจำนวนมากกว่ายอดคงเหลือในบัญชี',
    CannotUseMoreThanTwoDecimalValues:
      'ท่านไม่สามารถใช้ตัวเลขหรือตัวเลขติดลบมากกว่าทศนิยมสองตำแหน่งได้',
    YourPaymentIsBeingProcessed: 'คำสั่งถอนได้ถูกดำเนินการเป็นที่เรียบร้อย',
    Itcannotbe0: 'ต้องไม่เป็น 0 ',
    noCardWarn:
      'ท่านไม่มีบัตรที่พร้อมใช้งานสำหรับการสั่งถอน กรุณาเชื่อมโยงบัตรของท่านในส่วนของรายละเอียดการถอนก่อน',
    amtLarger: 'จำนวนต้องไม่เป็น 0 และต้องมากกว่าหรือเท่ากับ {minLimit} {currency}',
    blackList:
      'ขออภัย เราไม่สามารถส่งคำขอการถอนเงินได้ในขณะนี้ โปรดติดต่อ {supportEmail} สำหรับข้อมูลเพิ่มเติม',
    status: {
      withdrawalSubmitted: 'คำร้องขอถูกส่งเป็นที่เรียบร้อย\r\n',
      withdrawalCanceled: 'สถานะถูกยกเลิก',
      withdrawalAuditing: 'กำลังอยู่ในขั้นดำเนินการ',
      withdrawalRejected: 'ถูกปฏิเสธ',
      withdrawalAccepted: 'กำลังอยู่ในขั้นดำเนินการ',
      withdrawalPayFailed: 'สถานะไม่สำเร็จ ',
      withdrawalSuccess: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      withdrawalFailed: 'สถานะไม่สำเร็จ ',
      withdrawalPending: 'รอดำเนินการ\r\n',
      withdrawalPartialFailed: 'สถานะไม่สำเร็จ ',
      transferSubmitted: 'กำลังอยู่ในขั้นดำเนินการ',
      transferSuccess: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      transferRejected: 'ถูกปฏิเสธ',
      transferFailed: 'สถานะไม่สำเร็จ ',
      transferProcessing: 'กำลังอยู่ในขั้นดำเนินการ',
      withdrawalReversedFailed: 'ล้มเหลวแบบย้อนกลับ',
    },
    formErrorMsg: {
      bankName: 'จำเป็นต้องระบุชื่อบัญชี',
      bsb: 'จำเป็นต้องระบุรหัส BSB',
      beneName: 'จำเป็นต้องระบุชื่อผู้รับผลประโยชน์',
      accNum: 'จำเป็นต้องระบุเลขบัญชี',
      swift: 'จำเป็นต้องกรอกรหัส Swift',
      bankAddress: 'จำเป็นต้องต้องระบุที่อยู่ธนาคาร',
      holderAddress: 'จำเป็นต้องระบุที่อยู่ของผู้ถือบัตร',
      amt: 'จำเป็นต้องกรอกจำนวนเงิน',
      firstSixDigits: 'กรุณากรอกตัวเลข 6 หลักแรก',
      lastFourDigits: 'กรุณากรอกตัวเลข 4 หลักสุดท้าย',
      branchName: 'จำเป็นต้องระบุสาขา',
      email: 'จำเป็นต้องระบุอีเมล',
      accName: 'จำเป็นต้องกรอกชื่อบัญชี',
      province: 'จำเป็นต้องระบุที่อยู่จังหวัดของธนาคาร',
      city: 'จำเป็นต้องระบุประเทศของธนาคาร',
      bankCard: 'กรุณาเลือกบัตร',
      emailFormat: 'กรุณากรอกที่อยู่อีเมลที่ถูกต้อง',
      amt0: 'จำนวนไม่สามารถเป็น 0 ',
      bitcoin: 'กรุณากรอกที่อยู่ Bitcoin Wallet',
      addressLength: 'ที่อยู่กระเป๋าเงิน Bitcoin จะต้องประกอบด้วยตัวเลขและตัวอักษร 26-42 ตัว',
      usdt: 'กรุณากรอกที่อยู่ USDT Wallet',
      beneficiaryNameReq: 'ต้องระบุชื่อผู้รับประโยชน์ธนาคาร',
    },
    transfer: {
      info1:
        '{platform} จะยกเว้นค่าธรรมเนียมธนาคารในการถอนเงินครั้งแรกของเดือน การถอนเงินเพิ่มเติมภายในเดือนเดียวกันจะมีค่าธรรมเนียมธนาคาร 20 หน่วยของสกุลเงินที่ถอนเกิดขึ้น (สำหรับ USC/HKD/JPY ค่าธรรมเนียมจะเท่ากับ 20 USD)',
      info2:
        'กรุณากรอกชื่อผู้รับผลประโยชน์ของธนาคารให้ตรงตามหนังสือเดินทาง (ห้ามใช้ตัวย่อ โปรดกรอกชื่อเต็มตามชื่อย่อในใบแจ้งยอด)',
      info3:
        'แนะนำให้ใช้บัญชีธนาคารในรูปแบบ IBAN หากคุณไม่ได้มาจากภูมิภาคยุโรป โปรดกรอกใบแจ้งยอดธนาคารให้ตรงกัน',
      label: {
        upload: 'โปรดอัปโหลดสำเนารายการเงินฝากถอนในบัญชีเงินฝาก (ไม่เกิน 3 เดือน)',
        upload2:
          'โปรดตรวจสอบว่าวันที่ในรายการเคลียร์บัญชีหรือวันทำธุรกรรมล่าสุดอยู่ในช่วงจากเดือนปัจจุบันถึงสามเดือนที่ผ่านมา หากเกินระยะเวลานี้ กรุณาอัพโหลดรายการเคลียร์บัญชีอีกครั้ง',
      },
      formValidation: { upload: 'โปรดอัปโหลดรายการเงินฝากถอนในบัญชีเงินฝาก' },
      minAmount: 'จํานวนเงินถอนขั้นต่ําคือ 100 {currency}',
    },
    transitNumber: 'หมายเลขขนส่ง',
    institutionNumber: 'หมายเลขสถาบัน',
    beneficiaryName: 'ชื่อผู้รับผลประโยชน์',
  },
  security: {
    contactSupport:
      'ไม่สามารถใช้งานการยืนยันตัวตนใช่หรือไม่? กรุณาติดต่อ{contact_email} หรือ Livechat',
    securityAuthenticator: 'การยืนยันตัวตนเพื่อความปลอดภัย',
    verificationRequired: 'กรุณาใส่รหัสยืนยัน',
    resend: 'ส่งอีกครั้ง',
    authenticatorTooltip: 'รหัสยืนยันจาก Authenticator APP จะรีเฟรชทุก 30 วินาที',
    verifyPreEmailNote: 'รหัสจะถูกส่งไปที่ {email}',
    verifyPostEmailNote: 'รหัสถูกส่งไปที่ {email}',
    enableNow: 'เปิดใช้งานตอนนี้',
    verificationTitle: 'ต้องการการยืนยัน',
    verificationDesc: 'โปรดเปิดใช้งาน Security Authenticator APP ก่อนทำต่อไป',
  },
  rebate: {
    status: {
      processed: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      processing: 'อยู่ระหว่างการดำเนินการ',
      rejected: 'ถูกปฏิเสธ',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'คำร้องขอโอนเงินค่าคอมมิชชั่น',
    transferAmountIsGreaterThanAvailableBalance: 'จำนวนการโอนเงินของท่านมากกว่ายอดคงเหลือในบัญชี',
    yourRebateTransferHasBeenProcessed: 'การโอนเงินค่าคอมมิชชั่นได้ถูกดำเนินการเป็นที่เรียบร้อย',
    PleaseEnterTransferInformation: 'กรุณากรอกข้อมูลการโอนเงินที่ถูกต้อง\r\n',
    tradingAccount: 'บัญชีซื้อขาย',
    rebateAccount: 'บัญชีค่าคอมมิชชั่น',
    account: 'บัญชี',
    blackList: 'บัญชีนี้ถูกจำกัดการโอนเงินระหว่างบัญชีอื่น โปรดติดต่อ {supportEmail}',
  },
  common: {
    liveChat: { desc: 'ต้องการความช่วยเหลือหรือไม่ แชทสด' },
    lang: {
      english: 'ภาษาอังกฤษ',
      chinese: 'ภาษาจีน',
      french: 'ภาษาฝรั่งเศษ',
      thai: 'ภาษาไทย',
      german: 'ภาษาเยอรมัน',
      spanish: 'ภาษาสเปน',
      malay: 'ภาษามลายู',
      viet: 'ภาษาเวียดนาม',
      indo: 'ภาษาอินโดนีเซีย',
      arabic: 'ภาษาอาหรับ',
    },
    button: {
      iKnow: 'ฉันเข้าใจ',
      confirmCamelCase: 'ยืนยัน',
      confirm: 'ยืนยัน',
      submit: 'ส่ง',
      cancel: 'ยกเลิก',
      sendCode: 'ส่งรหัส',
      resendCode: 'ส่งรหัสอีกครั้ง',
      ok: 'ตกลง',
    },
    withdrawChannel: {
      limitTitle: 'การถอนเงินไม่สามารถทำได้ชั่วคราว',
      limitPassword:
        'เนื่องจากการเปลี่ยนแปลงรหัสผ่านการเข้าสู่ระบบของคุณ การถอนเงินจึงไม่สามารถทำได้ชั่วคราวเป็นเวลา {day} วัน เนื่องจากเหตุผลด้านความปลอดภัย คาดว่าจะสามารถกลับมาใช้งานใน {hour} ชั่วโมง.',
      limitUserLogin:
        'เนื่องจากมีการเปลี่ยนแปลงอีเมล/เบอร์โทรศัพท์สำหรับเข้าสู่ระบบ การถอนเงินจึงไม่สามารถทำได้ชั่วคราวสำหรับ {day} วัน คาดว่าจะสามารถกลับมาใช้งานใน {hour} ชั่วโมง',
      limitSecurityAuthenticator:
        'ด้วยการปรับเปลี่ยนของแอปพลิเคชันการยืนยันความปลอดภัยของคุณ การถอนเงินจะไม่รองรับชั่วคราวเป็นเวลา {day} วันเพื่อให้แน่ใจว่าบัญชีของคุณมีความปลอดภัย การจำกัดนี้คาดว่าจะถูกยกเลิกในระยะเวลา {hour} ชั่วโมง',
      banktransfer: 'โอนเงินผ่านธนาคารระหว่างประเทศ',
      aus: 'โอนเงินผ่านธนาคารระหว่างประเทศ - ประเทศออสเตรเลีย',
      int: 'โอนเงินผ่านธนาคาร - ระหว่างประเทศ',
      chn: 'โอนเงินผ่านธนาคาร - ประเทศจีน',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'โอนผ่าน UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'บัตรเครดิต',
      bpay: 'Bpay',
      creditdebitcard: 'บัตรเครดิต/เดบิต',
      bankwiretransferinternational: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      banktransferaustralia: 'โอนเงินผ่านธนาคารระหว่างประเทศ(ออสเตรเลีย)',
      banktransferinternational: 'โอนเงินผ่านธนาคารระหว่างประเทศ',
      banktransferchina: 'โอนเงินผ่านธนาคาร(ระหว่างประเทศ)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'โอนเงินระหว่างโบรกเกอร์',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (ธนาคารในประเทศไทย)',
      internetbankingnigeria: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(ไนจีเรีย)',
      internetbankingvietnam: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(เวียดนาม)',
      internetbankingmalaysia: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต(มาเลเซีย)',
      internetbankingindonesia: 'ธุรกรรมผ่านอินเตอร์เน็ต (อินโดนีเซีย)',
      internetbankingkorea: 'บริการธนาคารทางอินเทอร์เน็ต (เกาหลี)',
      internetbankingphilippine: 'ธุรกรรมผ่านอินเตอร์เน็ต (ฟิลิปปินส์)',
      internetbankingsouthafrica: 'ธุรกรรมผ่านอินเตอร์เน็ต (แอฟริกาใต้)',
      internetbankinguganda: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (ยูกันดา)',
      internetbankingrwanda: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (รวันดา)',
      internetbankingzambia: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แซมเบีย)',
      internetbankingcongo: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (คองโก)',
      internetbankingcameroon: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แคเมอรูน)',
      internetbankingburundi: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (บุรุนดี)',
      internetbankingkenya: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (เคนยา)',
      internetbankingghana: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (กานา)',
      internetbankingtanzania: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แทนซาเนีย)',
      internetbankingcanada: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (แคนาดา)',
      internetbankingbrazil: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (บราซิล)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'โอนเงินผ่านธนาคารเวียดนาม',
      nigeriabanktransfer: 'โอนเงินผ่านธนาคารไนจีเรีย',
      polipayment: 'ชำระเงินผ่าน POLi',
      bankwiretransferaustralia: 'โอนเงินผ่านธนาคาร(ออสเตรเลีย)',
      thailandinstantbankwiretransfer: 'โอนเงินผ่านธนาคารไทย',
      malaysiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารมาเลเซีย',
      banktransferbpaypolipay: 'โอนเงินผ่านธนาคารระหว่างประเทศ / BPay / PoliPay',
      nigeriainstantbankwiretransfer: 'โอนเงินผ่านธนาคารไนจีเรีย',
      vietnaminstantbankwiretransfer: 'โอนเงินผ่านธนาคารเวียดนาม',
      indonesiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารอินโดนีเซีย',
      philippinesinstantbankwiretransfer: 'โอนเงินผ่านธนาคารฟิลิปปินส์',
      southafricainstantbankwiretransfer: 'การโอนเงินผ่านธนาคารทันทีของแอฟริกาใต้',
      indiainstantbanktransfer: 'อินเดียโอนเงินผ่านธนาคารทันที',
      indiabanktransfer: 'โอนเงินผ่านธนาคารอินเดีย',
      southkoreabanktransfer: 'เกาหลีใต้โอนเงินผ่านธนาคารทันที',
      ugandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศยูกันดา',
      rwandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศรวันดา',
      zambiabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแซมเบีย',
      congobanktransfer: 'โอนเงินผ่านธนาคารในประเทศคองโก',
      cameroonbanktransfer: 'โอนเงินผ่านธนาคารในประเทศแคเมอรูน',
      burundibanktransfer: 'โอนเงินผ่านธนาคารในประเทศบุรุนดี',
      kenyabanktransfer: 'โอนเงินผ่านธนาคารในประเทศเคนยา',
      ghanabanktransfer: 'โอนเงินผ่านธนาคารในประเทศกานา',
      tanzaniabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแทนซาเนีย',
      brazilbanktransfer: 'โอนเงินผ่านธนาคารบราซิล',
      banktransferbpaypolipayaustralia: 'โอนเงินผ่านธนาคารระหว่างประเทศ/BPay/PoliPay(ออสเตรเลีย)',
      banktransferbpaypolipayinternational:
        'โอนเงินผ่านธนาคารระหว่างประเทศ/BPay/PoliPay (ระหว่างประเทศ)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'โอนเงินผ่านธนาคารในประเทศแคนาดา',
      cryptobitcoin: 'สกุลเงินดิจิตัล Crypto-Bitcoin',
      cryptotetheromni: 'สกุลเงินดิจิตัล Crypto-Tether(OMNI)',
      cryptotethererc20: 'สกุลเงินดิจิตัล Crypto-Tether(ERC20)',
      cryptotethertrc20: 'สกุลเงินดิจิตัล Crypto-Tether(TRC20)',
      cryptocurrencybitcoin: 'สกุลเงินดิจิตัล Crypto-Bitcoin',
      cryptocurrencyusdt: 'สกุลเงินดิจิตัล Crypto--USDT',
      cryptocurrencyethcps: 'สกุลเงินดิจิตัล Crypto-ETH',
      cryptocurrencyusdccps: 'สกุลเงินดิจิตัล Crypto-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'โอนเข้าบัญชี',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'การโอนเงินในท้องถิ่น',
      japanbanktransfer: 'โอนเงินผ่านธนาคารญี่ปุ่น',
      internetbankingmexico: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (เม็กซิโก)',
      internetbankinguae: 'ธนาคารออนไลน์ (สหราชอาณาจักร)',
      ewallet: 'กระเป๋าเงินอิเล็กทรอนิกส์',
      internetbankingtaiwan: 'ธนาคารออนไลน์ (ไต้หวัน)',
      internetbankingeu: 'ธนาคารออนไลน์ (EU)',
    },
    country: { international: 'ระหว่างประเทศ', australia: 'ออสเตรเลีย' },
    field: {
      country_region: 'ประเทศ/ภูมิภาค',
      address: 'ที่อยู่',
      phone: 'หมายเลขโทรศัพท์',
      nat: 'สัญชาติ',
      country: 'ประเทศที่อยู่อาศัย',
      save: 'ลงทุน',
      annIncome: 'รายได้ต่อปี',
      emailAdd: 'อีเมล',
      yes: 'ใช่',
      no: 'ไม่ใช่',
      enterVerificationCode: 'กรอกรหัสการยืนยัน',
      ebuyAccName: 'EBuy ชื่อบัญชี',
      ebuyEmailAddress: 'EBuy ที่อยู่อีเมล',
      skrillAccName: 'Skrill ชื่อบัญชี',
      skrillEmailAddress: 'Skrill ที่อยู่อีเมล',
      netellerAccName: 'Neteller ชื่อบัญชี',
      netellerEmailAddress: 'Neteller ที่อยู่อีเมล',
      imptNotes: 'หมายเหตุสำคัญ',
      paymentAccName: '{name} ชื่อบัญชี',
      paymentAccNum: '{name} หมายเลขบัญชี',
      documentType: 'ประเภทเอกสาร',
      accDigit: 'หมายเลขบัญชี',
      docID: 'รหัสเอกสาร',
      bankBranchCode: 'รหัสสาขา',
      accType: 'ประเภทบัญชี',
      accName: 'ชื่อบัญชี',
      accNum: 'หมายเลขบัญชี',
      bankName: 'ชื่อธนาคาร',
      swiftCode: 'รหัส Swift',
      bankAddress: 'ที่อยู่ธนาคาร (สำนักงานใหญ่)',
      bankHolderAddress: 'ที่อยู่เจ้าของบัญชี',
      bankBeneficiaryName: 'ชื่อบัญชีผู้รับเงิน',
      bankAccNum: 'หมายเลขบัญชีธนาคาร',
    },
    keys: {
      SUBMIT: 'ส่ง',
      FROM: 'จาก',
      UPDATE: 'อัปเดตข้อมูล',
      TO: 'ถึง',
      DATE: 'วันที่เปิดบัญชี',
      pleaseSelect: 'กรุณาเลือก',
      amount: 'จำนวน',
      rebateAccount: 'บัญชีค่าคอมมิชชั่น',
      comingSoon: 'เร็ว ๆ นี้',
      tradingHistory: 'ประวัติการซื้อขาย',
      noData: 'ไม่มีข้อมูลใด ๆ',
      NAME: 'ชื่อ',
      EMAIL: 'อีเมล',
      ACCTYPE: 'ประเภทบัญชี',
      PLATFORM: 'แพลตฟอร์ม',
      BASECURRENCY: 'สกุลเงิน',
      DATERANGE: 'ช่วงวันที่',
      STATUS: 'สถานะ',
      DEST: 'ปลายทาง',
      ACCNUM: 'เลขบัญชี ',
      BALANCE: 'จำนวนเงิน ',
      PROFITLOST: 'กำไร',
      MARGINLVL: 'ระดับมาร์จิ้น',
      ACCEQUITY: 'ทุนบัญชี',
      CREDIT: 'เครดิต',
      NONE: 'ไม่มี',
      ALL: 'ทั้งหมด',
      actualAmonut: 'จำนวนเงินจริง',
      currency: 'สกุลเงิน',
      method: 'ช่องทาง',
      REGISTRATION: 'วันที่ลงทะเบียน',
      FTD: 'วันที่ฝากเงินครั้งแรก',
      CURRENTCAMPAIGN: 'แคมเปญปัจจุบัน',
      blacklisted: 'ขึ้นบัญชีดํา',
      FIRST_TRANS_IN: 'การแปลงแรกในวันที่',
      createTime: 'เวลาส่ง',
      lastUpdateTime: 'เวลาดำเนินการ',
    },
    dateRange: {
      today: 'วันนี้',
      yesterday: 'เมื่อวาน',
      lastSevenDays: '7 วันย้อนหลัง',
      lastThirtyDays: '30 วันย้อนหลัง',
      weekToDate: 'สัปดาห์นี้',
      monthToDate: 'เดือนนี้',
      quarterToDate: 'ไตรมาสนี้',
      previousWeek: 'สัปดาห์ก่อนหน้า',
      previousMonth: 'เดือนก่อนหน้า',
      previousQuarter: 'ไตรมาสก่อนหน้า',
    },
    products: {
      forex: 'คู่สกุลเงิน',
      commodities: 'สินค้าโภคภัณฑ์',
      indices: 'ดัชนี',
      crypto: 'คริปโทเคอร์เรนซี ',
    },
    formValidation: {
      dynamicReq: '{dynamic} เป็นสิ่งจำเป็น',
      verCodeReq: 'จำเป็นต้องมีรหัสการยืนยัน',
      alphanumericReq: 'โปรดกรอกตัวอักษรและตัวเลข',
      answerReq: 'โปรดเลือก',
      sameAsPOA: 'สิ่งที่กรอกจะต้องเหมือนกับ POA',
      sameAsID: 'สิ่งที่กรอกจะต้องเหมือนกับเอกสารประจำ',
      notBlank: 'ปล่อยให้เว้นว่างหากไม่เกี่ยวข้อง',
      digits12WithoutDash: 'โปรดป้อนตัวเลข 12 หลักโดยไม่ต้องใส่ "-"',
      enterAsPerId: 'กรอกตามรหัสประจำตัว',
      numberReq: 'กรุณากรอกเลข',
      addressReq: 'กรุณากรอกที่อยู่',
      suburbReq: 'กรุณาระบุเมืองหรือเขต',
      stateReq: 'กรุณากรอกจังหวัด',
      postcodeReq: 'กรุณากรอกรหัสไปรษณีย์',
      countryReq: 'โปรดระบุประเทศ',
      AlphanumericOnlyNotSpace: 'อนุญาตเฉพาะตัวอักษรและตัวเลขเท่านั้น ไม่อนุญาตให้ใช้ช่องว่าง',
      AlphanumericOnly: 'เฉพาะตัวอักษรและตัวเลขเท่านั้น',
      NumbersSymbol: 'เฉพาะตัวเลขและสัญลักษณ์เท่านั้น',
      NumbersOnlyNotSpace: 'เฉพาะตัวเลขเท่านั้น ห้ามใช้ช่องว่าง',
      NumbersOnly: 'เฉพาะตัวเลขเท่านั้น',
      LettersSymbol: 'เฉพาะตัวอักษรและสัญลักษณ์เท่านั้น',
      LettersSymbolNotSpace: 'อนุญาตเฉพาะตัวอักษรและสัญลักษณ์เท่านั้น ห้ามใช้ช่องว่าง',
      LettersOnlyNotSpace: 'อนุญาตเฉพาะตัวอักษร ห้ามใช้ช่องว่าง',
      NotSpace: 'ไม่อนุญาตให้ใช้พื้นที่',
    },
  },
  report: {
    title: { rebateReport: 'รายงานค่าคอมมิชชัน' },
    rebate: {
      column: {
        name: 'ชื่อ',
        accountNumber: 'หมายเลขบัญชี',
        accountType: 'ประเภทบัญชี',
        volume: 'ปริมาณการซื้อขาย',
        rebate: 'ค่าคอมมิชชั่น',
        totalRebate: 'ค่าคอมมิชชั่นทั้งหมด',
        date: 'วันที่',
        instrument: 'ผลิตภัณฑ์การซื้อขาย',
      },
      dropdown: {
        allInstruments: 'ผลิตภัณฑ์ทั้งหมด',
        all: 'ทั้งหมด',
        instruments: 'ผลิตภัณฑ์การซื้อขาย',
      },
      others: {
        rebate: 'ค่าคอมมิชชั่น',
        instrumentsTraded: 'ผลิตภัณฑ์การซื้อขาย',
        lots: 'ล็อต',
        totalRebate: 'ค่าคอมมิชชั่นทั้งหมด',
        rebates: 'ค่าคอมมิชชั่น',
      },
    },
  },
  ibReport: {
    header: 'รายงาน IB',
    netFunding: 'ยอดฝากสุทธิ',
    deposits: 'ยอดฝากทั้งหมด',
    withdraw: 'ยอดถอนทั้งหมด',
    openedAccs: 'จำนวนบัญชีที่เปิด',
    funding: 'รายการธุรกรรม',
    withdrawals: 'ถอนเงิน',
    appliedAmount: 'จำนวนเงินที่ใช้',
    depositAmount: 'จำนวนเงินฝาก',
    withdrawalAmount: 'จำนวนเงินที่ถอน',
    timeLimitMessage: 'ช่วงการค้นหาสูงสุดสำหรับการดาวน์โหลดคือ 90 วัน โปรดอัปเดตช่วงวันที่ใหม่',
    successInfo: 'ดาวน์โหลดสำเร็จ',
    download: 'ดาวน์โหลด',
    comfirm: 'ยืนยัน',
    defaultTimeMessage: 'ช่วงการค้นหาสูงสุดสำหรับการดาวน์โหลดคือ 90 วัน',
  },
  lead: { header: 'รายชื่อลูกค้าที่สนใจ', ibAccount: 'บัญชี IB', demoAccounts: 'บัญชีทดลอง' },
  pendingAccounts: {
    header: 'บัญชีที่รอดำเนินการ',
    errorDoc:
      'รายชื่อลูกค้าด้านล่างนี้ได้ทำการกรอกแบบฟอร์มการเปิดบัญชีสำเร็จแล้ว แต่ยังไม่ได้ทำการอัปโหลดเอกสารยืนยันตัวตนโปรดติดตามสถานะลูกค้าของคุณเพื่อการเปิดบัญชีที่สมบูรณ์',
    acceptedDocType: 'รูปแบบเอกสารที่สามารถใช้ยืนยันตัวตนได้มี ดังนี้:',
    primaryDoc: 'เอกสารยืนยันตัวตน (ID)',
    passport:
      'หนังสือเดินทาง (พาสปอร์ต) โดยแสดงหน้าที่มีลายเซ็นและหน้าถัดไปอย่างชัดเจน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา',
    id: 'บัตรประจำตัวประชาชน โดยแสดงข้อมูลบนบัตรอย่างชัดเจนครบถ้วน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา ',
    dl: 'ใบขับขี่ โดยแสดงข้อมูลบนบัตรอย่างชัดเจนครบถ้วน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา',
    address: 'เอกสารยืนยันที่อยู่ (POA)',
    addressList: [
      'สำเนาบิลค่าสาธารณูปโภคล่าสุด (ไม่เกิน 6 เดือน)',
      'สำเนาใบแจ้งยอดบัตรเครดิต (ไม่เกิน 6 เดือน)',
      'สำเนาใบแจ้งยอดบัญชีธนาคาร (ไม่เกิน 6 เดือน)',
    ],
    tip: 'กรุณาแนบเอกสารและส่งมายัง ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'ประวัติการทำธุรกรรม',
    rebateHistory: 'ประวัติค่าคอมมิชชั่น',
    withdrawHistory: 'ประวัติการถอน',
    transferHistory: 'ประวัติการโอนเงิน',
    cancelWithdraw: 'คุณต้องการยกเลิกคำขอถอนเงินหรือไม่?',
    cancelError:
      'การถอนเงินของคุณกำลังดำเนินการอยู่ในขณะนี้ ดังนั้นเราจึงไม่สามารถยอมรับคำขอยกเลิกของคุณได้ สำหรับข้อมูลเพิ่มเติม คุณสามารถติดต่อเราโดยใช้คุณสมบัติการแชทสด',
  },
  fileUploader: {
    supportedFormat: 'ประเภทไฟล์ที่รองรับ: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'ประเภทไฟล์ที่รองรับ: {files}',
    maxSize: 'ขนาดไฟล์สูงสุดที่สามารถอัปโหลดได้ : {maxSize} MB',
    maxFileNum: 'จำนวนไฟล์สูงสุด: {maxFileNum}',
    selectFile: 'เลือกไฟล์',
    maxFileSize: 'ขนาดไฟล์ที่อัปโหลดต้องไม่เกิน {maxSize}MB',
    sureToDelete: {
      title: 'คุณแน่ใจหรือไม่?',
      content: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์ที่อัปโหลด？',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'กำลังดำเนินการ',
      paid: 'ชำระเงินแล้ว',
      unpaid: 'ยังไม่ได้ชำระเงิน',
      rejected: 'ถูกปฏิเสธ',
    },
  },
  referralLinks: {
    header: 'ลิงก์อ้างอิง',
    ibAcc: 'บัญชี IB',
    campLang: 'ภาษาเพื่อการโฆษณา',
    cpy: 'คัดลอกลิงก์',
    alreadyCPY: 'คัดลอกแล้ว',
    liveAcc: 'บัญชีซื้อขายจริง',
    demoAcc: 'บัญชีทดลองซื้อขาย',
    homePage: 'หน้าหลัก',
    Share: 'แบ่งปัน',
    subIbReferral: 'อ้างอิง IB ย่อย',
  },
  settings: { ibProfile: 'ข้อมูลส่วนตัว', info: 'ข้อมูล', reg: 'ลงทะเบียนเมื่อ' },
  myProfile: { changeDetails: 'หากท่านต้องการแก้ไขรายละเอียดโปรไฟล์ กรุณาติดต่อ {mailTo}' },
  unfundedAcc: {
    header: 'บัญชีที่ไม่เคยฝากเงิน',
    allActivationAccs: 'บัญชีการเปิดใช้งานทั้งหมด',
    neverFundedAccs: 'ลูกค้าที่ไม่เคยฝากเงิน',
    prevFundedZero: 'ยอดคงเหลือสุทธิ 0',
  },
  multiib: { level: 'IB ขั้นที่ {level}', noib: 'ไม่มี IB ย่อยภายใต้บัญชีนี้' },
  header: { bckToCP: 'กลับสู่หน้าพอร์ทัลลูกค้า', logout: 'ออกจากระบบ\r\n' },
  menu: {
    dashboard: 'กระดานข้อมูล',
    ibReport: 'รายงาน IB',
    rebateReport: 'รายงานค่าคอมมิชชัน',
    accManagement: 'บัญชี',
    iblevel: 'ระดับขั้น',
    ibAccs: 'บัญชีที่เปิดใช้งาน',
    leads: 'รายชื่อลูกค้าที่สนใจ',
    pendingAccs: 'บัญชีที่รอดำเนินการ',
    unfundedAccs: 'บัญชีที่ไม่เคยฝากเงิน',
    payHistory: 'ประวัติการทำธุรกรรม',
    ibProfile: 'ข้อมูลส่วนตัว',
    refLinks: 'ลิงก์อ้างอิง',
    contactUs: 'ติดต่อเรา',
    switchCP: 'สลับเป็นพอร์ทัลลูกค้า',
  },
  footer: {
    riskWarn:
      'คำเตือนความเสี่ยง: การซื้อขาย Contracts for Difference (CFDs) มีความเสี่ยงสูงต่อเงินทุนของคุณและอาจทำให้ขาดทุนได้คุณควรเทรดด้วยเงินที่คุณสามารถจ่ายได้เท่านั้น การซื้อขาย CFD อาจไม่เหมาะสำหรับนักลงทุนทุกคนโปรดตรวจสอบให้แน่ใจว่าคุณเข้าใจถึงความเสี่ยงที่เกี่ยวข้องและใช้มาตรการที่เหมาะสมเพื่อจัดการ โปรดอ่านเอกสารการเปิดเผยความเสี่ยงที่เกี่ยวข้องอย่างละเอียด',
  },
  responseMsg: {
    410: 'การตรวจสอบค่าตัวเลขล้มเหลว',
    411: 'รหัสผ่านไม่ถูกต้อง',
    420: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    421: 'ปฏิเสธการรีเซ็ตรหัสผ่าน',
    431: 'กำลังดำเนินการคำนวณเงินคืน โปรดรอสักครู่แล้วลองอีกครั้งในภายหลัง',
    490: 'โปรดอย่าส่งข้อมูลบัตรที่ซ้ำกัน',
    500: 'เกิดข้อผิดพลาด',
    501: 'แอปพลิเคชันล้มเหลว โปรดลองอีกครั้งภายหลัง',
    505: 'การอัปโหลดไฟล์ล้มเหลว',
    520: 'เข้าสู่ระบบล้มเหลว โปรดลองอีกครั้ง',
    521: 'ผู้ใช้งานลงชื่อเข้าใช้บนอุปกรณ์นี้แล้ว!',
    522: 'โปรดเข้าสู่ระบบอีกครั้ง',
    523: 'โปรดเข้าสู่ระบบอีกครั้ง',
    524: 'ไม่พบที่อยู่อีเมลของท่าน',
    525: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    527: 'ผู้ใช้งานไม่ใช่ IB',
    528: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    529: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    530: 'เซสชั่นเข้าสู่ระบบไม่ถูกต้อง',
    540: 'ไม่พบบัญชีการซื้อขายของท่าน',
    541: 'ไม่พบบัญชีค่าคอมมิชชั่น',
    542: 'คุณไม่สามารถเปิดบัญชีเพิ่มเติมได้',
    544: 'คุณไม่ได้รับอนุญาตให้สมัครบัญชีเพิ่มเติมจนกว่าจะได้รับการยืนยันหลักฐานยืนยันตัวตนของคุณ',
    550: 'หมายเลขบัญชีไม่ถูกต้อง',
    551: 'เงินไม่เพียงพอ',
    553: 'ท่านไม่สามารถถอนได้เนื่องจากเครดิตในบัญชีซื้อขายของท่าน โปรดติดต่อ {email} เพื่อขอความช่วยเหลือเพิ่มเติม',
    554: 'จำนวนเงินที่ร้องขอคือศูนย์หรือไม่มี',
    564: 'ประเทศไม่ตรงกับช่องทางการชำระเงิน',
    565: 'รหัสธนาคารไม่ถูกต้อง',
    566: 'ไม่พบวิธีการชำระเงิน',
    581: 'คุณเลือกเข้าร่วมการส่งเสริมการขายสำเร็จแล้ว โปรดทราบว่าคุณต้องทำการฝากเงินเพื่อถอนเงิน',
    582: 'คุณเข้าร่วมกิจกรรมและมีตำแหน่งดังนั้นคุณจึงไม่สามารถส่งใบสมัครถอนได้',
    584: 'ท่านไม่มีการคืนเงินที่เกี่ยวข้อง',
    590: 'ไม่พบบัญชีที่โอนออก',
    591: 'ไม่พบบัญชีที่โอนเข้า',
    593: 'เราไม่สามารถดำเนินการโอนเงินของคุณจากบัญชีที่เลือกได้เนื่องจากบัญชีของคุณมีเครดิต',
    594: 'ไม่อนุญาตให้โอนเงินออกจากบัญชีนี้',
    595: 'ในขณะที่คุณเข้าร่วมในกิจกรรมของเราคำขอโอนของคุณไม่สามารถดำเนินการได้ในขณะนี้',
    647: 'รหัสการยืนยันไม่ถูกต้อง',
    1101: 'ไม่สามารถเพิ่มบัญชีได้ เนื่องจากขณะนี้มีบัญชี IB อยู่ 10 บัญชีแล้ว',
    1102: 'ไม่สามารถเปิดใช้งานได้ บัญชีได้ถูกสร้างขึ้นเป็นบัญชีจริงหรือบัญชีทดลอง',
    1103: 'บัญชีถูกปิดใช้งาน',
    1202: 'การตรวจสอบหมดอายุ โปรดลองอีกครั้ง',
    1204: 'โปรดลองด้วยที่อยู่อีเมลอื่น',
    1208: 'ไม่สามารถส่งรหัสยืนยันซ้ำได้',
    1209: 'ข้อผิดพลาดในการส่งรหัสยืนยัน',
    session_timeout: 'หมดเวลา กำลังกลับไปหน้าเข้าสู่ระบบ',
  },
  verificationStatus: {
    title: { default: 'คุณเกือบจะเสร็จแล้ว', LV3: 'ยินดีต้อนรับ' },
    dueToAML:
      'คุณพร้อมที่จะเริ่มทำการซื้อทำการซื้อขายแล้ว! ช่วยเราปกป้องบัญชีของคุณด้วยการยืนยันข้อมูลส่วนบุคคลของคุณ',
    accOpeningVerification: {
      title: 'สร้างบัญชีจริง',
      content: 'สร้างบัญชีจริงเพื่อเริ่มฝากเงินในบัญชีของคุณ',
    },
    identityVerification: {
      title: 'การยืนยันตัวตน',
      content: 'ส่ง และยืนยันหลักฐานยืนยันตัวตนของคุณเพื่อเริ่มการซื้อขาย',
    },
    poaVerification: {
      title: 'การยืนยันที่อยู่',
      content: 'POA ต้องได้รับการยืนยันก่อนจึงจะสามารถถอนเงินได้',
    },
    kyc: {
      depositNow: 'ฝากเงินตอนนี้',
      withdrawNow: 'ถอนเงินตอนนี้',
      createNow: 'สร้างตอนนี้',
      start: 'เริ่มต้น',
      complete: 'เสร็จสิ้น',
      pending: 'รอดำเนินการ',
      rejected: 'ถูกปฏิเสธ',
      resubmit: 'ส่งใหม่',
    },
    resubmitNote: 'เอกสารระบุตัวตนของคุณบางส่วนอาจขาดหายไป หรือไม่ถูกต้อง กรุณาส่งใหม่',
    rejectedNote: 'มีคำถามเพิ่มเติม? โปรดติดต่อเราผ่านทาง{liveChat}',
    liveChat: 'ไลฟ์แชท',
    uploadTemplate: {
      idGuideTitle: 'แนวทางเอกสารยืนยันตัวตน',
      uploadGuideTitle: 'หลักเกณฑ์การอัปโหลด',
      desc1: 'ออกโดยรัฐบาล',
      desc2: 'เอกสารขนาดเต็มต้นฉบับที่ไม่ได้แก้ไข',
      desc3: 'วางเอกสารบนพื้นหลังสีเดียว',
      desc4: 'สามารถอ่านออกได้ แสงส่องสว่างดี และเป็นภาพสี',
      desc5: 'ไม่ใช่ภาพขาวดำ',
      desc6: 'ไม่ใช่เอกสารที่แก้ไข หรือหมดอายุ',
      bottomDesc: 'ไฟล์ที่อัปโหลดสามารถเป็น jpg, png หรือ pdf และขนาดไฟล์ต้องไม่เกิน 5MB',
    },
  },
  register: {
    progressStatus: {
      title: 'ความคืบหน้าการยืนยัน',
      estimatedTimeTitle: 'เวลาโดยประมาณที่จะเสร็จสมบูรณ์:',
      min: 'นาที',
    },
    tabs: {
      personalDetails: 'ข้อมูลส่วนบุคคล',
      moreAboutYou: 'เพิ่มเติมเกี่ยวกับท่าน',
      accountConfig: 'การกำหนดค่าบัญชี',
      confirmYourId: 'ยืนยันตัวตนของท่าน',
      fundYourAcc: 'ฝากเงินในบัญชีของท่าน',
      personalInformation: 'ข้อมูลส่วนบุคคล',
      createLiveAccount: 'สร้างบัญชีจริง',
      financialInfo: 'ข้อมูลทางการเงิน',
      iDProof: 'หลักฐานประจำตัวประชาชน',
      addressProof: 'หลักฐานที่อยู่',
    },
    btn: {
      next: 'ถัดไป',
      back: 'กลับ',
      fundnow: 'ฝากเงินตอนนี้',
      upload: 'อัปโหลด',
      done: 'เสร็จสิ้น',
      bTCP: 'กลับไปที่พอร์ทัลของลูกค้า',
      proceedToUpload: 'ดำเนินการอัปโหลด',
      completeID: 'ทำการยืนยันตัวตนของคุณให้เสร็จ',
      completePOA: 'ยืนยัน POA ให้เสร็จสมบูรณ์',
      depositViaOther: 'ฝากเงินผ่านช่องทางอื่น',
      withdrawViaOther: 'ถอนเงินผ่านช่องทางอื่น',
    },
    authority: {
      dialog1: 'ท่านต้องมีบัญชีเทรดจริงเพื่อเขาถึงส่วนนี้ของ Client Portal',
      dialog2:
        'เปิดบัญชีจริงเพื่อปลดล็อคการเข้าถึงพอร์ทัลลูกค้าของท่านอย่างเต็มรูปแบบซึ่งรวมถึงเครื่องมือการซื้อขาย โปรโมชั่นและอื่น ๆ อีกมากมาย',
      openLiveAcc: 'เปิดบัญชีซื้อขายจริง',
    },
    demo: {
      congrad: 'ขอแสดงความยินดี!',
      title: 'บัญชีทดลองเทรด MT{platform} อายุการใช้งาน 60 วันของท่านใช้งานได้แล้ว',
      title2: 'รายละเอียดบัญชีทดลองเทรดของท่าน',
      li: { login: 'เข้าสู่ระบบ', password: 'รหัสผ่าน', srv: 'เซิร์ฟเวอร์', expDate: 'วันหมดอายุ' },
      emailSent: 'รายละเอียดการเข้าสู่ระบบได้ถูกส่งไปยังอีเมลที่ท่านลงทะเบียนแล้ว',
      dl: 'ดาวน์โหลดแพลตฟอร์มการซื้อขาย MT {platform} ของท่าน',
      improveQ: 'คุณต้องการที่จะพัฒนาการซื้อขายของคุณหรือไม่',
      improveWay:
        'เพียงแค่ปิดบัญชีจริงในไม่กี่นาทีเพื่อปลดล็อคเครื่องมือการซื้อขายและโปรโมชั่นพิเศษมากมาย',
      proTrade: 'เครื่องมือซื้อขายแบบมืออาชีพ',
      customerService: 'บริการลูกค้าแบบตัวต่อตัวตลอด 24 ชั่วโมง',
      competitivePromotionals: 'การส่งเสริมการแข่งขัน',
      secure: 'ธุรกรรมปลอดภัย 3D',
      openLiveAcc: 'เปิดบัญชีซื้อขายจริง',
    },
    personalDetails: {
      page1: {
        pageTitle: 'ข้อมูลส่วนบุคคล',
        userTitle: 'คำนำหน้าชื่อ',
        firstName: 'ชื่อจริง',
        lastName: 'นามสกุล',
        email: 'อีเมล',
        dob: 'วันเกิด',
        idType: 'ประเภทบัตรประจำตัว',
        referral: 'ท่านได้รับคำแนะจากผู้ใดหรือไม่ (ไม่บังคับ)',
        middleName: 'ชื่อกลาง',
        nationality: 'สัญชาติ',
        phoneNum: 'หมายเลขโทรศัพท์',
        date: 'วัน',
        month: 'เดือน',
        year: 'ปี',
        placeOfBirth: 'สถานที่เกิด',
        idNum: 'หมายเลขประจำตัว',
        passportNum: 'หมายเลขพาสปอร์ต',
        driverLicenseNum: 'หมายเลขใบขับขี่',
        titleDropdown: {
          mr: 'นาย',
          mrs: 'นาง',
          ms: 'นางสาว',
          miss: 'นางสาว',
          dr: 'ดอกเตอร์',
          prof: 'ศาสตราจารย์',
        },
        idTypeDropdown: {
          passport: 'พาสปอร์ต',
          idCard: 'บัตรประชาชน',
          driverLicense: 'ใบอนุญาติขับขี่',
        },
      },
      page2: {
        pageTitle: 'ที่อยู่อาศัยหลัก',
        mainResidency: 'ประเทศที่อยู่อาศัยหลัก',
        streetNumber: 'เลขที่ถนน',
        streetName: 'ชื่อถนน',
        address: 'ที่อยู่',
        provinceOrState: 'จังหวัด/รัฐ',
        cityOrSuburb: 'เมือง / ชานเมือง',
        postcode: 'รหัสไปรษณีย์',
        usQuestion:
          'ท่านเป็นพลเมืองสหรัฐฯ / ถิ่นที่อยู่เพื่อวัตถุประสงค์ด้านภาษีหรือท่านมีเครือเส้นสาย / ความสัมพันธ์ในสหรัฐอเมริกา (เช่นบัญชีธนาคาร ฯลฯ )',
      },
    },
    moreAboutYou: {
      pageTitle1: 'การจ้างงานและรายละเอียดทางการเงิน',
      pageTitle2: 'ความรู้และประสบการณ์การซื้อขาย',
      complianceExplain: 'คำถามต่อไปนี้ถูกรวบรวมเพื่อให้สอดคล้องกับข้อกำหนดของ AML / CTF',
    },
    accountConfig: {
      pageTitle: 'การกำหนดค่าบัญชี',
      chooseTradingPlatform: 'เลือกแพลตฟอร์มการซื้อขาย',
      chooseAccType: 'เลือกประเภทบัญชี',
      chooseAccCurrency: 'เลือกสกุลเงินของบัญชี',
    },
    confirmYourId: {
      pageTitle: 'ยืนยันตัวตนของท่าน',
      description:
        'ในฐานะโบรกเกอร์ฟอเร็กซ์ที่ได้รับการควบคุม เราจำเป็นต้องยืนยันตัวตนของท่าน โปรดอัปโหลดหนึ่งในเอกสารต่อไปนี้สำหรับการยืนยันตัวตนและการยืนยันที่อยู่ หรือมิฉะนั้นท่านอาจส่งอีเมลเอกสารยืนยันไปที่ {mailTo}',
      poidTitle: 'หลักฐานยืนยันตัวตน',
      porTitle: 'หลักฐานยืนยันที่อยู่',
      uploadDescription: 'เลือกประเภทเอกสารและอัปโหลดตามนั้น',
      note: 'สำคัญ: ชื่อในเอกสารจะต้องแสดงชื่อเต็มของท่านอย่างชัดเจน',
      pending: { upload: 'รอการอัปโหลด', verification: 'รอการตรวจสอบ' },
      poidTypes: {
        photoId: 'ภายถ่ายตัวตน',
        passport: 'พาสปอร์ต',
        drivingLicence: 'ใบอนุญาติขับขี่',
      },
      porTypes:
        '<li> บิลค่าสาธารณูปโภค </li>\n      <li> รายการเดินบัญชีธนาคาร </li>\n     <li> ใบรับรองถิ่นที่อยู่ </li>',
    },
    finishPage: {
      title: '{individualUserName} ขอบคุณสำหรับการสมัครบัญชีเทรดจริง {platformName}!',
      contentDefault:
        'เราได้รับใบสมัครของท่านและตั้งค่าบัญชีเทรดจริงของท่าน <b> {บัญชี} </b> <br />\n      ในไม่ช้าท่านจะได้รับอีเมลที่ <b> {email} </b> ที่มีรายละเอียดการเข้าสู่ระบบและลิงก์เพื่อดูสถานะบัญชีของท่าน <br />',
      contentWithoutId3WithAccount:
        'ขณะนี้บัญชีซื้อขายของท่านอยู่ระหว่างดำเนินการและท่านจะได้รับอีเมลที่มีข้อมูลเพิ่มเติมในไม่ช้า <br />\n     หากต้องการติดตามการดำเนินการบัญชีเทรดจริงของท่านอย่างรวดเร็วท่านสามารถฝากเงินในบัญชีของท่านตอนนี้และรับการเปิดในลำดับต้น <br />',
      contentWithoutId3WithoutAccount:
        'ขณะนี้บัญชีซื้อขายของท่านอยู่ระหว่างดำเนินการและท่านจะได้รับอีเมลที่มีข้อมูลเพิ่มเติมในไม่ช้า <br />',
    },
    accOpeningVerification: {
      accOpenVer: 'การยืนยันการเปิดบัญชี',
      personalInformation: {
        narrative: 'ข้อมูลส่วนบุคคลของคุณจำเป็นสำหรับการเปิดบัญชีจริงของคุณ',
        firstName: 'ชื่อจริง',
        middleName: 'ชื่อกลาง',
        lastName: 'นามสกุล',
        gender: 'เพศ',
        genderList: { male: 'ชาย', female: 'หญิง', other: 'อื่น ๆ' },
        dob: 'วันเกิด',
        day: 'วัน',
        month: 'เดือน',
        year: 'ปี',
        mobile: 'หมายเลขโทรศัพท์มือถือ',
        countryName: 'ประเทศที่พำนัก',
        nationality: 'สัญชาติ',
      },
      createLiveAccount: {
        narrative:
          'ทำตามขั้นตอนอีกสองสามขั้นตอนเพื่อเปิดบัญชี และเริ่มการฝากเงิน ข้อมูลเพิ่มเติมเกี่ยวกับประเภทบัญชีของเรา<a href="{link}" target="_blank" alt>ที่นี่</a>.',
        choose: 'เลือก {dynamic}',
        tradingPlatform: 'แพลตฟอร์มเพื่อการซื้อขาย',
        accountType: 'ประเภทบัญชี',
        accountCurrency: 'สกุลเงินของบัญชี',
        islamicTooltip:
          '{platform} อาจขอให้คุณจัดเตรียมเอกสารประกอบเพื่อยืนยันว่าคุณนับถือศาสนาอิสลาม {platform} ขอสงวนสิทธิ์ในการแก้ไขการกำหนดค่าบัญชีของคุณหากการยืนยันล้มเหลว',
        successInfo: {
          title: 'สร้างบัญชีซื้อขายแล้ว',
          content:
            'ขอแสดงความยินดีด้วย!​ คุณได้ทำการเปิดบัญชีเรียบร้อยแล้ว <br /> ในการเริ่มซื้อขาย ทำการฝากเงินตอนนี้และทำการยืนยันตัวตนของคุณให้เสร็จ',
        },
        successInfo2: {
          title: 'สร้างบัญชีซื้อขายแล้ว',
          content: 'กรอกข้อมูลโปรไฟล์ทางการเงินของคุณเพื่อเริ่มขั้นตอนต่อไปในการยืนยันบัญชีของคุณ',
          start: 'เริ่มต้น',
        },
        generalInfo: {
          title: 'การส่งข้อมูลของคุณอยู่ระหว่างการตรวจสอบ',
          content:
            'ขอบคุณที่เปิดบัญชีกับ {platform} คุณสามารถเริ่มเติมเงินในบัญชีของคุณได้เมื่อการยื่นคำร้องของคุณได้รับการอนุมัติ',
        },
        rejectedInfo: {
          title: 'ถูกปฏิเสธ',
          content: 'เราเสียใจที่ต้องแจ้งให้คุณทราบว่าคำขอเปิดบัญชี {platform} ของคุณถูกปฏิเสธ',
        },
      },
    },
    identityVerification: {
      title: 'การยืนยันตัวตน',
      subTitle: 'ข้อมูลประจำตัว',
      narrative: 'กรอกรายละเอียด',
      firstName: 'ชื่อจริง',
      middleName: 'ชื่อกลาง',
      lastName: 'นามสกุล',
      nationality: 'สัญชาติ',
      idType: 'ประเภทของเอกสารยืนยันตัวตน',
      idSerialNumber: 'หมายเลขรหัสซีเรียล',
      passportNum: 'หมายเลขพาสปอร์ต',
      driverLicenseNum: 'หมายเลขใบขับขี่',
      upload: {
        title: 'อัปโหลดเอกสาร',
        desc: 'ตรวจสอบว่าเอกสารแสดงรูปถ่าย ชื่อนามสกุล วันเกิด และวันที่ออกเอกสารของคุณ',
        guideline: 'หลักเกณฑ์การอัปโหลด',
        positive: 'อัปโหลดในเชิงบวก',
        signaturePage: 'อัพโหลดรูปถ่ายด้านหลังบัตร',
      },
      idTypeDropdown: {
        passport: 'พาสปอร์ต',
        idCard: 'บัตรประชาชน',
        driverLicense: 'ใบอนุญาติขับขี่',
        other: 'อื่น ๆ',
      },
      additionalInfo: {
        title: 'ต้องการข้อมูลเพิ่มเติม',
        content: 'ยืนยันตัวตนของคุณเพื่อเริ่มซื้อขาย',
      },
      successInfo: {
        title: 'การยืนยันตัวตนเสร็จสมบูรณ์',
        content:
          'ขอแสดงความยินดีด้วย!​ รหัสของคุณได้รับการยืนยันแล้ว ตอนนี้คุณสามารถเริ่มทำการซื้อทำการซื้อขายบน MT4/MT5 หรือแอป {platform} หากคุณได้ทำการฝากเงินแล้ว',
      },
      generalInfo: {
        title: 'การส่งข้อมูลของคุณอยู่ระหว่างการตรวจสอบ',
        content:
          'ขอบคุณที่เปิดบัญชีกับ {platform} คุณสามารถเริ่มซื้อขายได้เมื่อข้อมูลประจำตัวของคุณได้รับการยืนยันแล้ว',
      },
      rejectedInfo: {
        title: 'ถูกปฏิเสธ',
        content: 'เราเสียใจที่ต้องแจ้งให้คุณทราบว่าคำขอเปิดบัญชี {platform} ของคุณถูกปฏิเสธ',
      },
      identifyingRequiredInfo: {
        title: 'ต้องการข้อมูลเพิ่มเติม',
        content:
          'เอกสารระบุตัวตนของคุณบางส่วนอาจขาดหายไป หรือไม่ถูกต้อง โปรดอัปโหลดอีกครั้งเพื่อดำเนินการต่อ',
      },
      streetNumber: 'เลขที่ถนน',
      streetName: 'ชื่อถนน',
    },
    poaVerification: {
      proofOfAddressVer: 'หลักฐานการยืนยันที่อยู่',
      subTitle: 'ข้อมูลที่อยู่',
      narrative: 'กรอกรายละเอียด',
      dueToAML: 'เนื่องจากข้อกำหนด AML ที่อยู่ของคุณจะต้องได้รับการยืนยันก่อนจึงจะสามารถถอนเงินได้',
      nationality: 'สัญชาติ',
      country: 'ประเทศที่พำนัก',
      address: 'ที่อยู่',
      postalCode: 'รหัสไปรษณีย์',
      citySuburb: 'เมือง/ชานเมือง',
      city: 'เมือง',
      provinceState: 'จังหวัด/รัฐ',
      upload: {
        title: 'หนังสือรับรอง POA',
        inst1: 'ค่าสาธารณูปโภค (ไฟฟ้า, น้ำ, แก๊ส)',
        inst2: 'ใบแจ้งความเคลื่อนไหวของบัญชีธนาคาร หรือบัตรเครดิต',
        desc: 'ข้อมูล POA สำหรับการถอนจะต้องออกภายใน {months} เดือนที่ผ่านมา',
        guideline: 'หลักเกณฑ์การอัปโหลด',
        uploadFilesPhoto: 'อัปโหลดไฟล์/ภาพถ่าย',
      },
      additionalInfo: {
        title: 'ต้องการข้อมูลเพิ่มเติม',
        content: 'POA ต้องได้รับการยืนยันก่อนจึงจะสามารถถอนเงินได้',
      },
      successInfo: {
        title: 'หลักฐานยืนยันที่อยู่',
        content:
          'ขอแสดงความยินดีด้วย!​ POA ของคุณได้รับการยืนยันแล้ว ตอนนี้คุณสามารถถอนเงินได้แล้ว',
      },
      generalInfo: {
        title: 'การส่งข้อมูลของคุณอยู่ระหว่างการตรวจสอบ',
        content:
          'ขอบคุณสำหรับการซื้อขายกับ {platform} คุณสามารถเริ่มกระบวนการถอนเงินได้เมื่อการยื่นคำร้องของคุณได้รับการอนุมัติ',
      },
      identifyingRequiredInfo: {
        title: 'ต้องการข้อมูลเพิ่มเติม',
        content:
          'เอกสารระบุตัวตนของคุณบางส่วนอาจขาดหายไป หรือไม่ถูกต้อง โปรดอัปโหลดอีกครั้งเพื่อดำเนินการต่อ',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'ข้อมูลทางการเงินของคุณ',
        subTitle: 'รายละเอียดการจ้างงาน และการเงิน',
        subTitle2: 'ความรู้ และประสบการณ์ด้านการซื้อขาย',
        employmentStatus: 'สถานะการจ้างงาน',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title}การซื้อขายต่อสัปดาห์',
        estimatedAnnualIncome: 'รายได้ต่อปีโดยประมาณ',
        estimatedSavingsAndInvestments: 'การออม และการลงทุนโดยประมาณ',
        estimatedIntendedDeposit: 'เงินฝากที่ตั้งใจไว้โดยประมาณ',
        sourceOfFunds: 'แหล่งที่มาของเงินทุน',
        numberOfTrades: 'จำนวน',
        tradingAmount: 'ปริมาณ',
      },
      iDProof: {
        ver: 'อัปโหลดหลักฐานยืนยันตัวตน',
        subTitle: 'ข้อมูลประจำตัว',
        narrative: 'กรอกรายละเอียด',
      },
      addressProof: {
        ver: 'อัปโหลดหลักฐานแสดงที่อยู่ (POA)',
        subTitle: 'ข้อมูลที่อยู่',
        narrative: 'กรอกรายละเอียด',
        upload: {
          title: 'เอกสารที่ยอมรับสำหรับหลักฐานที่อยู่',
          inst1: 'ค่าสาธารณูปโภค (ไฟฟ้า, น้ำ, แก๊ส)',
          inst2: 'ใบแจ้งความเคลื่อนไหวของบัญชีธนาคาร หรือบัตรเครดิต',
          desc: 'ข้อมูล POA สำหรับการถอนจะต้องออกภายใน {months} เดือนที่ผ่านมา',
          guideline: 'หลักเกณฑ์การอัปโหลด',
          uploadFilesPhoto: 'อัปโหลดไฟล์/ภาพถ่าย',
        },
      },
      successInfo: {
        title: 'การตรวจสอบธนาคารเสร็จสมบูรณ์',
        content: 'ขอแสดงความยินดีด้วย! คุณยืนยันรายละเอียดธนาคารของคุณสำเร็จแล้ว',
      },
      generalInfo: {
        title: 'การส่งข้อมูลของคุณอยู่ระหว่างการตรวจสอบ',
        content:
          'เอกสารของคุณสำหรับการโอนเงินผ่านธนาคารยังอยู่ในขั้นตอนการตรวจสอบ หากคุณต้องการเริ่มการซื้อขายตอนนี้คุณยังสามารถฝากเงินผ่านช่องทางอื่นได้',
      },
      rejectedInfo: {
        title: 'ถูกปฏิเสธ',
        content: 'คำขอของคุณในการฝากเงินด้วยการโอนเงินผ่านธนาคารถูกปฏิเสธ',
      },
      identifyingRequiredInfo: {
        title: 'ต้องการข้อมูลเพิ่มเติม',
        content:
          'เอกสารระบุตัวตนของคุณบางส่วนอาจขาดหายไป หรือไม่ถูกต้อง โปรดอัปโหลดอีกครั้งเพื่อดำเนินการต่อ',
      },
    },
  },
  ibProfile: {
    updateSharedIb: {
      resetPassword: 'รีเซ็ทรหัสผ่าน',
      emailPlaceholder: 'กรุณาใส่อีเมลให้ถูกต้อง',
      addSuccess: 'สร้างบัญชีที่ใช้ร่วมกันแล้ว',
      permissionIdsTips: 'คุณสามารถอนุญาตบัญชีที่ใช้ร่วมกันต่อไปนี้ได้หรือไม่:',
      permissionIdsFirst:
        '<b>ข้อมูลบัญชี</b>: ดูหน้าข้อมูลบัญชี (บัญชี IB, บัญชีที่เพิ่งเปิด) ข้อมูลจะถูกซ่อนหากไม่ได้รับอนุญาต',
      permissionIdsSecond:
        '<b>ข้อมููลรายงาน IB</b>: ดูหน้าข้อมูล IB (แดชบอร์ด, IB หลายระดับ - บัญชี) ข้อมูลจะถูกซ่อนหากไม่ได้รับอนุญาต',
      permissionIdsThird:
        '<b>การอนุญาตฝากเงิน</b>: ดำเนินการฝากของ IB (สมัครเพื่อรับรีเบต, โอนรีเบต, ถอนรีเบต) ,ดูส่วนลด (รายงานส่วนลด ยอดคงเหลือ และบันทึกกองทุน), การดำเนินการไม่สามารถทำได้หากไม่ได้รับอนุญาต',
      newPassword: 'โปรดใส่รหัสผ่านใหม่ชองคุณ',
      newPassowrdReset: 'โปรดใส่รหัสผ่านใหม่ชองคุณ',
      passwordSame: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเก่า',
      resetPasswordSuccess: 'รหัสผ่านถูกรีเซ็ตแล้ว',
      enterNewPassword: 'โปรดใส่รหัสผ่านใหม่ชองคุณ',
      changePasswordTips: 'รหัสผ่านเก่าจะไม่สามารถใช้ได้ และรีเซ็ตเป็นรหัสผ่านใหม่',
      fundsChangeTips:
        'หลังจากกองทุนได้รับการอนุญาตแล้ว บัญชีร่วมของIB จะสามารถโอนและถอนยอดเงินฝากในบัญชีหลักของ IB ได้ คุณจะอนุญาตหรือไม่ ? ',
      addNewSharedIb: 'เพิ่ิ่มบัญชี IB ที่ใช้งานร่วมกัน',
      editSharedIb: 'แก้ไขบัญชี IB ที่ใช้ร่วมกัน',
      maxSharedIbAccountError: 'ไม่สามารถเพิ่มบัญชีได้ เนื่องจากขณะนี้มีบัญชี IB อยู่ 10 บัญชีแล้ว',
      email: 'อีเมล',
      emailValidate: 'กรุณากรอกที่อยู่อีเมลที่ถูกต้อง',
      emailError: 'มีอีเมลนี้อยู่แล้ว โปรดลองอีเมลอื่น',
      password: 'รหัสผ่าน',
      passwordValidate: 'โปรดใส่รหัสผ่าน',
      passwordValidateNum: '8-16 ตัวอักษร',
      passwordValidateTips:
        'โปรดผสมระหว่างตัวอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก (A-Z, a-z) ตัวเลข (0-9) และอักขระพิเศษ (เช่น: !@#S%^&)',
      passwordValidateWarning:
        '8-16 ตัวอักษร,โปรดผสมระหว่างตัวอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก (A-Z, a-z) ตัวเลข (0-9) และอักขระพิเศษ (เช่น: !@#S%^&)',
      sharedIbAccountPermission: 'การอนุญาตบัญชี IB ที่ใช้ร่วมกัน',
      remark: 'โน๊ต',
      remarkTips: 'คุณสามารถบันทึกวัตถุประสงค์ หรือเจ้าของบัญชีได้',
      status: 'สถานะ',
      cancel: 'ยกเลิก',
      confirm: 'คอนเฟิร์ม',
      disabled: 'ปิดใช้งานแล้ว',
      enabled: 'เปิดใช้งานแล้ว',
      editSuccess: 'แก้ไขสำเร็จแล้ว ',
    },
    sharedIbList: {
      accountData: 'ข้อมูลบัญชี',
      IBData: 'ข้อมููลรายงาน IB ',
      fundOperations: 'การอนุญาตฝากเงิน',
      resetPassword: 'รีเซ็ทรหัสผ่าน',
      sharedIbAccount: 'บัญชี IB ที่ใช้ร่วมกัน',
      maxSharedIbAccount: 'บัญชี IB ที่ใช้ร่วมกัน สูงสุด 10 บัญชี',
      add: 'เพิ่ม',
      accountHistory: 'ประวัติบัญชี IB ที่ใช้ร่วมกัน',
      setting: 'ตั้งค่า',
      delete: 'ลบ',
      enable: 'เปิดใช้งาน',
      disable: 'ปิดใช้งาน',
      enabled: 'เปิดใช้งานแล้ว',
      disabled: 'ปิดใช้งานแล้ว',
      sharedAccountEnabled: 'เปิดใช้งานบัญชี IB ที่ใช้ร่วมกันแล้ว',
      sharedAccountDisabled: 'บัญชี IB ที่ใช้ร่วมกันถูกปิดใช้งานแล้ว',
      sharedAccountCopied: 'บัญชี IB ร่วมถูกคัดลอกแล้ว',
      deleteTips:
        'บัญชี IB ที่ใช้ร่วมกันจะไม่สามารถเข้าสู่ระบบได้หลังจากการลบ คุณต้องการลบออกหรือไม่?',
      sharedAccountDeleted: 'บัญชี IB ที่ใช้ร่วมกันถูกลบแล้ว',
      copyTips: 'บัญชี IB ร่วมถูกคัดลอกแล้ว',
      remark: 'โน๊ต',
      cancel: 'ยกเลิก',
      accountHistoryBtn: 'ประวัติ',
    },
    sharedIbHistory: {
      viewMore: 'ดูเพิ่มเติม',
      allHistory: 'ประวัติทั้งหมดได้ถูกแสดงแล้ว',
      lastUpdate: 'อัปเดตล่าสุด',
      refreshTips: 'อัปเดตประวัติแล้ว',
      refresh: 'รีเฟรช',
      chooseAccount: 'เลือกบัญชี',
      device: 'อุปกรณ์',
      login: 'ล็อคอิน',
      commission: 'ใช้รีเบท',
      withdraw: 'ถอนรีเบท',
      fromTo: 'ตั้งแต่ {fromAccount} ถึง {toAccount} จำนวน {total}',
      transfer: 'การโอนรีเบท',
    },
    npPermissionTips: 'ไม่ได้รับอนุญาตสำหรับบัญชีนี้',
    title: 'ข้อมูลส่วนตัว',
    subTitle: 'Shared IB',
  },
  agreement: {
    title: 'ยืนยันข้อตกลงผู้แนะนำโบรกเกอร์ (IB)',
    rebateAccount: 'บัญชีรีเบต',
    description: 'การคลิกตกลงแสดงว่าคุณได้อ่าน ทำความเข้าใจ และเห็นด้วยกับข้อมูล ข้อกำหนดและเงื่อนไขการให้บริการทั้งหมดในข้อตกลง',
    agree: 'ยอมรับ',
    signed: 'บัญชีรีเบต {accountID} ลงนามเมื่อ {signedTime}',
    IBAgreement: 'ข้อตกลง IB',
    confirmAgreement: 'กรุณาติดต่อผู้จัดการ IB เพื่อยืนยันเกี่ยวกับข้อตกลง IB'
  }
};
