export default {
  en: 'Inglés',
  zh: '中文',
  home: {
    NewPortal: 'Nuevo portal',
    SecureIBPortal: 'Portal IB seguro',
    DASHBOARD: 'PANEL DE CONTROL',
    MARKETING_TOOLS: 'HERRAMIENTAS DE MARKETING',
    IB_REPORT: 'INFORME IB',
    REBATE_REPORT: 'REPORTE DE COMISÍON',
    IB_ACCOUNTS: 'CUENTAS IB',
    PAYMENT_HISTORY: 'HISTORIAL DE TRANSACCIONES',
    IB_FAQ: 'Preguntas frecuentes del IB',
    RebateAccountNumber: 'Número de cuenta de comisíon',
    totalBalance: 'balance total',
    totalCommission: 'comisión total',
    APPLY_FOR_A_REBATE: 'SOLICITE A COMISÍON',
    availableBalance: 'Saldo disponible',
    WITHDRAW_REBATE: 'RETIRAR COMISÍON',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'CUENTA',
    MonthtoDate: 'Mes hasta la fecha',
    UPDATE: 'ACTUALIZAR',
    REBATE: 'COMISÍON',
    TOTAL_VOLUME: 'VOLUMEN TOTAL',
    NET_FUNDING: 'FINANCIAMIENTO NETO',
    DEPOSITS: 'DEPOSITOS',
    WITHDRAW: 'RETIRAR',
    OPEND_ACCOUNT: 'CUENTA ABIERTA',
    PERFORMANCE: 'ACTUACIÓN',
    OpendAccount: 'Cuenta abierta',
    select_ib: 'POR FAVOR SELECCIONE LA CUENTA IB',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MÁS COMERCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CUENTAS DE MAYOR RENDIMIENTO PARA ESTE MES',
    RECENTLY_OPENED_ACCOUNTS: 'CUENTAS RECIENTEMENTE ABIERTAS',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Cuenta',
    Name: 'Nombre',
    Volume: 'Volumen',
    Rebate: 'COMISÍON',
    Balance: 'balance ',
    ContactClient: 'Comuníquese con el cliente',
    YouCannotAskForCommission: 'No puedes pedir una comisión',
    applicationFailedPleaseTryAgain: 'La aplicación falló. Vuelve a intentarlo más tarde.',
    applyRebateSuccessTip:
      'Felicitaciones, hemos recibido su solicitud de reembolso. | {amount} se transferirá a la cuenta de reembolso {account} dentro de 1 día hábil. | Puede retirar o transferir fondos una vez que se procese su reembolso.',
    HOVER:
      'Las comisíones del día anterior se calculan y procesan entre las 09:00 y las 11:00 AEST todos los días.',
    blacklist:
      'Esta cuenta no puede efectuar reembolsos en este momento, por favor, contacte con con su gestor de cuenta.',
    ACCOUNTS_PROFIT:
      'Ganancias/pérdidas totales históricas de todas las cuentas comerciales con esta cuenta IB. La carga puede tardar un momento.',
    PROFIT: 'CUENTAS LUCRO',
    reload: 'Recargar',
  },
  liveChat: { header: 'Chat en vivo' },
  contactUs: {
    click: 'Pulse',
    assist: ' CONTACTE CON NOSOTROS',
    header: 'CONTÁCTENOS',
    followUs: 'Síguenos',
    tip: '{platform} el equipo profesional de atención al cliente multilingüe está listo para ayudarle a ofrecerle una experiencia de negocios sin igual.',
    chatNow: 'Hablemos ahora',
    globalOffice: 'Oficina Global',
  },
  ibAccounts: {
    header: 'CUENTAS IB',
    ibAccount: 'Cuenta IB',
    subIbs: 'Cuenta Sub IB',
    tradinglist: 'Lista de comercio',
    openlist: 'Lista abierta',
    closedlist: 'Lista cerrada',
    tradinglistColumn: {
      ticketid: 'Identificación de entradas',
      opentime: 'Tiempo abierto',
      type: 'Escribe',
      volume: 'Volumen',
      item: 'Artículo',
      openprice: 'Precio de apertura',
      closeprice: 'Precio de cierre',
      closetime: 'Hora de cierre',
      commission: 'Comisión',
      profit: 'Lucro',
      buy: 'Comprar',
      sell: 'Vender',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
      verificationCode:
        'El código de verificación ha sido enviado a {email}. Por favor, contacte con {supportEmail} si necesita más ayuda',
      fetchTradeAccFailed: 'Error al obtener la cuenta de trading',
      failed: 'Error al retirar. Por favor, inténtelo de nuevo más tarde',
      tokenExpired: 'El token ha expirado',
    },
    alert:
      'Debido a las leyes de AML, el método que utilice para retirar debe ser el mismo que el utilizado para su depósito. Si la cantidad retirada es inferior a 100 unidades de la moneda base de su cuenta, el banco cobrará una comisión de 20 unidades por su manejo. ',
    NetellerChargeAlert:
      'Neteller cobra una tarifa de transacción del {percentageFee}% por cada retiro.',
    FasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
    SkrillChargeAlert:
      'Skrill cobra una tarifa de transacción del {percentageFee}% por cada retiro.',
    cryptoAlert:
      'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
    REBATE_WITHDRAW_REQUEST: 'Solicitar la retirada de la comisión',
    PleaseSelectDesiredWithdrawMethod: 'Seleccione el método de retiro deseado',
    BankName: 'Nombre del banco',
    BankAccountsName: 'Nombre de cuentas bancarias',
    FasaPayAccountName: 'Nombre de la cuenta de FasaPay',
    AustralianBankName: 'Nombre del banco australiano',
    BankAccountNumber: 'Número de cuenta bancaria',
    FasaPayAccountNumber: 'Número de cuenta de FasaPay',
    BankAccountBeneficiary: 'Beneficiario de la cuenta bancaria',
    BSB: 'BSB',
    skrillEmail: 'Correo electrónico de Skrill',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Correo electrónico de Neteller',
    PerfectMoneyEmail: 'Correo electrónico de Perfect Money',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Dirección de la cartera de Bitcoin',
    USDTEmail: 'Tether Wallet Address',
    BankBranch: 'Sucursal bancaria',
    Province: 'Provincia',
    City: 'Ciudad',
    BankAddress: 'Dirección del banco',
    bankCity: 'Ciudad del banco',
    bankProvince: 'Provincia del banco',
    AccountNumberIBAN: 'Número de cuenta / IBAN',
    BankBeneficiaryName: 'Nombre del beneficiario del banco',
    BankAccountBeneficiaryName: 'Nombre del beneficiario de la cuenta bancaria',
    AccountHoldersAddress: 'Dirección del titular de la cuenta',
    SelectWithdrawCard: 'Seleccione su tarjeta de retiro',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift (cuentas sin AUD)',
    ABA_SortCodeABA: 'ABA / Código de clasificación',
    ImportantNotes: 'Notas importantes',
    YourPaymentGreaterThanAvailableBalance: 'Su pago es mayor que el saldo disponible',
    CannotUseMoreThanTwoDecimalValues:
      'No puede usar números y números negativos con más de dos lugares decimales',
    YourPaymentIsBeingProcessed: 'Su solicitud de retiro ha sido enviada',
    Itcannotbe0: 'No puede ser 0',
    noCardWarn:
      'No tiene ninguna tarjeta disponible para realizar retiros, primero vincule su tarjeta en Detalles del retiro',
    amtLarger: 'La cantidad no puede ser nula y debe ser mayor o igual a {minLimit} {currency}',
    blackList:
      'Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información',
    status: {
      withdrawalSubmitted: 'Presentada',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Procesando',
      withdrawalRejected: 'Rechazado',
      withdrawalAccepted: 'Procesando',
      withdrawalPayFailed: 'Ha fallado',
      withdrawalSuccess: 'Procesada',
      withdrawalFailed: 'Ha fallado',
      withdrawalPending: 'Pendiente',
      withdrawalPartialFailed: 'Ha fallado',
      transferSubmitted: 'Procesando',
      transferSuccess: 'Procesada',
      transferRejected: 'Rechazado',
      transferFailed: 'Ha fallado',
      transferProcessing: 'Procesando',
      withdrawalReversedFailed: 'Fracaso Revertido',
    },
    formErrorMsg: {
      bankName: 'Se requiere el nombre del banco',
      bsb: 'Se requiere BSB',
      beneName: 'Se requiere el nombre del beneficiario del banco',
      accNum: 'Se requiere el número de cuenta bancaria',
      swift: 'Se requiere Swift',
      bankAddress: 'Se requiere la dirección del banco',
      holderAddress: 'Se requiere la dirección del titular',
      amt: 'Se requiere la cantidad',
      firstSixDigits: 'Ingrese los primeros 6 dígitos',
      lastFourDigits: 'Ingrese los últimos 4 dígitos',
      branchName: 'El nombre de la sucursal es obligatorio',
      email: 'correo electronico es requerido',
      accName: 'Se requiere el nombre de la cuenta',
      province: 'La provincia del banco es obligatoria',
      city: 'La ciudad del banco es obligatoria',
      bankCard: 'Por favor seleccione una tarjeta',
      emailFormat: 'Por favor, introduzca una dirección de email correcta',
      amt0: 'La cantidad no puede ser 0',
      ifscCode: 'Se requiere IFSC',
      bitcoin: 'La dirección de la cartera de Bitcoin es obligatoria',
      addressLength: 'La dirección de la cartera de Bitcoin debe tener entre 26 y 42 caracteres',
      usdt: 'La dirección de la cartera de USDT es obligatoria',
      beneficiaryNameReq: 'Se requiere el nombre del beneficiario del banco',
    },
    transfer: {
      info1:
        '{platform} eximirá de los cargos por comisiones bancarias en la primera retirada del mes. Las retiradas adicionales durante el mismo mes incurrirán en una comisión bancaria de 20 unidades de su divisa de retirada (para USC/HKD/JPY, la comisión equivaldrá a 20 USD)',
      info2:
        'Por favor, asegúrese de que el nombre del beneficiario bancario coincide exactamente con el del pasaporte (no se permiten abreviaturas, introduzca el nombre completo conforme al inicial en el extracto)',
      info3:
        'La cuenta bancaria se prefiere con formato IBAN. Si no pertenece a la región de la UE, por favor, introduzca exactamente los datos del extracto bancario',
      label: {
        upload:
          'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
        upload2:
          'Asegúrese de que la fecha del estado de cuenta o la fecha de la última transacción esté entre el mes actual y los últimos tres meses. Si supera este período, vuelva a cargar el estado de cuenta.',
      },
      formValidation: { upload: 'Por favor, envíe un extracto bancario' },
      minAmount: 'La cantidad mínima de retiro es 100 {currency}',
    },
    transitNumber: 'Número de tránsito',
    institutionNumber: 'Número de institución',
    beneficiaryName: 'Nombre del beneficiario',
  },
  security: {
    contactSupport:
      '¿No puede acceder al método de autenticación? Por favor, contacte a {contact_email} o Livechat',
    securityAuthenticator: 'Autenticación de seguridad',
    verificationRequired: 'Por favor, introduzca el código de verificación',
    resend: 'Reenviar',
    authenticatorTooltip:
      'El código de verificación de la aplicación Authenticator se actualiza cada 30 segundos',
    verifyPreEmailNote: 'El código se enviará a {email}',
    verifyPostEmailNote: 'Código enviado a {email}',
    enableNow: 'Activar ahora',
    verificationTitle: 'Verificación requerida',
    verificationDesc:
      'Por favor, habilite la aplicación de autenticación de seguridad antes de continuar.',
  },
  rebate: { status: { processed: 'Procesada', processing: 'Procesando', rejected: 'Rechazado' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'SOLICITUD DE TRANSFERENCIA DE COMISIÓN',
    transferAmountIsGreaterThanAvailableBalance:
      'El monto de su transferencia es mayor que el saldo disponible',
    yourRebateTransferHasBeenProcessed: 'su transferencia de reembolso ha sido enviada',
    PleaseEnterTransferInformation: 'Ingrese información de transferencia precisa',
    tradingAccount: 'Cuenta de operaciones',
    rebateAccount: 'Cuenta de comisíon',
    account: 'Cuenta',
    blackList:
      'Esta cuenta ha sido restringida por hacer una transferencia entre cuentas, por favor, póngase en contacto en {supportEmail}',
  },
  common: {
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    lang: {
      english: 'Inglés',
      chinese: 'chino',
      french: 'francés',
      thai: 'tailandés',
      german: 'alemán',
      spanish: 'Español',
      malay: 'malayo',
      viet: 'vietnamita',
      indo: 'indonesio',
      arabic: 'Arábica',
    },
    button: {
      iKnow: 'Entiendo',
      confirmCamelCase: 'Confirmar',
      confirm: 'CONFIRMAR',
      submit: 'Enviar',
      cancel: 'CANCELAR',
      sendCode: 'Enviar código',
      resendCode: 'Reenviar codigo',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Los retiros no se admiten temporalmente',
      limitPassword:
        'Debido a la modificación de su contraseña de inicio de sesión, los retiros no son compatibles temporalmente durante {day} días para garantizar la seguridad de su cuenta. Se espera que esta restricción se levante en {hour} horas.',
      limitUserLogin:
        'Debido a la modificación de su dirección de correo electrónico/número de teléfono de inicio de sesión, los retiros no son compatibles temporalmente durante {day} días. Se espera que esta restricción se levante en {hour} horas.',
      limitSecurityAuthenticator:
        'Debido a la modificación de su aplicación de Autenticación de Seguridad, los retiros no son temporalmente admitidos por {day} días para asegurar la seguridad de su cuenta. Se espera que esta restricción se levante en {hour} horas.',
      banktransfer: 'Transferencia bancaria internacional',
      aus: 'Transferencia bancaria - Australia',
      int: 'Transferencia bancaria - Internacional',
      chn: 'Transferencia bancaria - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Pago sindical',
      unionpaytransfer: 'Transferencia UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Tarjeta de crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Tarjeta de crédito/débito',
      bankwiretransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferaustralia: 'Transferencia bancaria (Australia)',
      banktransferinternational: 'Transferencia bancaria internacional',
      banktransferchina: 'Transferencia bancaria (Internacional)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferencia de Broker a Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banca online (Tailândia)',
      internetbankingnigeria: 'Banca online (Nigéria)',
      internetbankingvietnam: 'Banca online (Vietname)',
      internetbankingmalaysia: 'Banca online (Malásia)',
      internetbankingindonesia: 'Banca online (Indonésia)',
      internetbankingkorea: 'Banca online (Corea)',
      internetbankingindia: 'Banca online (India)',
      internetbankingphilippine: 'Banca online (Philippine)',
      internetbankingsouthafrica: 'Banca online (Sudáfrica)',
      internetbankinguganda: 'Banca online (Uganda)',
      internetbankingrwanda: 'Banca online (Ruanda)',
      internetbankingzambia: 'Banca online (Zambia)',
      internetbankingcongo: 'Banca online (Congo)',
      internetbankingcameroon: 'Banca online (Camerún)',
      internetbankingburundi: 'Banca online (Burundi)',
      internetbankingkenya: 'Banca online (Kenia)',
      internetbankingghana: 'Banca online (Ghana)',
      internetbankingtanzania: 'Banca online (Tanzania)',
      internetbankingcanada: 'Banca online (Canadá)',
      internetbankingbrazil: 'Banca online (Brasil)',
      skrillneteller: 'Skrill / Neteller',
      polipayment: 'Pagamento con Poli',
      bankwiretransferaustralia: 'Transferencia bancaria (Australia)',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      banktransferbpaypolipay: 'Transferencia bancaria / BPay / PoliPay',
      nigeriainstantbankwiretransfer: 'Transferencia bancaria de Nigeria',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de Vietnam',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      southafricainstantbankwiretransfer: 'Transferencia bancaria instantánea de Sudáfrica',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      indiabanktransfer: 'Transferencia bancaria da India',
      southkoreabanktransfer: 'Transferencia bancaria instantánea de Corea del Sur',
      ugandabanktransfer: 'Transferencia bancaria local de Uganda',
      rwandabanktransfer: 'Transferencia bancaria local de Ruanda',
      zambiabanktransfer: 'Transferencia bancaria local de Zambia',
      congobanktransfer: 'Transferencia bancaria local del Congo',
      cameroonbanktransfer: 'Transferencia bancaria local de Camerún',
      burundibanktransfer: 'Transferencia bancaria local de Burundi',
      kenyabanktransfer: 'Transferencia bancaria local de Kenia',
      ghanabanktransfer: 'Transferencia bancaria local de Ghana',
      tanzaniabanktransfer: 'Transferencia bancaria local de Tanzania',
      brazilbanktransfer: 'Transferencia bancaria en Brasil',
      banktransferbpaypolipayaustralia: 'Transferencia bancaria/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transferencia bancaria/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptodivisa-Bitcoin',
      cryptotetheromni: 'Criptodivisa-Tether(OMNI)',
      cryptotethererc20: 'Criptodivisa-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptodivisa-Bitcoin',
      cryptocurrencyusdt: 'Criptodivisa-USDT',
      cryptocurrencyethcps: 'Criptodivisa-ETH',
      cryptocurrencyusdccps: 'Criptodivisa-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Transferencia a las cuentas',
      E_WALLET: 'CARTERA ELECTRÓNICA',
      CRYPTOCURRENCY: 'CRIPTOMONEDA',
      LOCAL_TRANSFER: 'TRASLADO LOCAL',
      japanbanktransfer: 'Transferencia bancaria en Japón',
      internetbankingmexico: 'Banca online (México)',
      internetbankinguae: 'Banca en línea (EAU)',
      ewallet: 'Cartera electrónica',
      internetbankingtaiwan: 'Banca por Internet (Taiwán)',
      internetbankingeu: 'Banca por Internet (UE)',
    },
    country: { international: 'Internacional', australia: 'Australia' },
    field: {
      country_region: 'País / Región',
      address: 'Direccíon',
      phone: 'Número de teléfono',
      nat: 'Nacionalidad',
      country: 'País de residencia',
      save: 'Inversión',
      annIncome: 'Ingresos anuales',
      emailAdd: 'Dirección de email',
      yes: 'Sí',
      enterVerificationCode: 'Introducir código de verificación',
      ebuyAccName: 'Nombre de la cuenta EBuy',
      ebuyEmailAddress: 'Dirección de correo electrónico de EBuy',
      skrillAccName: 'Nombre de la cuenta Skrill',
      skrillEmailAddress: 'Dirección de correo electrónico de Skrill',
      netellerAccName: 'Nombre de la cuenta Neteller',
      netellerEmailAddress: 'Dirección de correo electrónico de Neteller',
      imptNotes: 'Notas importantes',
      paymentAccName: '{name} Nombre de la cuenta',
      paymentAccNum: '{name} Número de cuenta',
      documentType: 'Dokumenta Tipo',
      accDigit: 'Dígito de la cuenta',
      docID: 'ID del documento',
      bankBranchCode: 'Código de sucursal',
      accType: 'Tipo de cuenta',
      accName: 'Nombre de la cuenta',
      accNum: 'Número de cuenta',
      bankName: 'Nombre del banco',
      swiftCode: 'Código SWIFT',
      bankAddress: 'Dirección bancaria',
      bankHolderAddress: 'Dirección del titular de la cuenta',
      bankBeneficiaryName: 'Nombre del beneficiario del banco',
      bankAccNum: 'Número de cuenta bancaria',
    },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DESDE',
      UPDATE: 'ACTUALIZAR',
      TO: 'HASTA',
      DATE: 'FECHA DE APERTURA',
      pleaseSelect: 'Por favor seleccione',
      amount: 'Cantidad',
      rebateAccount: 'Cuenta de comisíon',
      comingSoon: 'En breve',
      tradingHistory: 'HISTORIAL COMERCIAL',
      noData: 'Sin datos',
      NAME: 'NOMBRE',
      EMAIL: 'CORREO ELECTRÓNICO',
      ACCTYPE: 'TIPO DE CUENTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MONEDA BASE',
      DATERANGE: 'RANGO DE FECHAS',
      STATUS: 'ESTADO',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DE CUENTA',
      BALANCE: 'SALDO',
      PROFITLOST: 'LUCRO',
      MARGINLVL: 'NIVEL DE MARGEN',
      ACCEQUITY: 'PATRIMONIO DE LA CUENTA',
      CREDIT: 'CRÉDITO',
      NONE: 'Ninguno',
      ALL: 'Todas',
      actualAmonut: 'cantidad real',
      currency: 'DIVISA',
      method: 'MÉTODO',
      REGISTRATION: 'FECHA DE REGISTRO',
      FTD: 'FECHA DE DEPÓSITO',
      CURRENTCAMPAIGN: 'CAMPAÑA ACTUAL',
      blacklisted: 'Lista negra',
      FIRST_TRANS_IN: 'PRIMERA TRANS EN LA FECHA',
      createTime: 'Hora de envío',
      lastUpdateTime: 'Tiempo procesado',
    },
    dateRange: {
      today: 'Hoy',
      yesterday: 'Ayer',
      lastSevenDays: 'Los últimos 7 días',
      lastThirtyDays: 'Últimos 30 días',
      weekToDate: 'Semana hasta la fecha',
      monthToDate: 'Mes hasta la fecha',
      quarterToDate: 'Trimestre hasta la fecha',
      previousWeek: 'Semana pasada',
      previousMonth: 'Mes anterior',
      previousQuarter: 'Trimestre anterior',
    },
    products: { forex: 'FOREX', commodities: 'COMMODITIES', indices: 'ÍNDICES', crypto: 'CRYPTO' },
    formValidation: {
      dynamicReq: 'El campo {dynamic} es obligatorio',
      verCodeReq: 'El código de verificación es obligatorio',
      alphanumericReq: 'Por favor, introduzca un alfanumérico',
      answerReq: 'Por favor, seleccione',
      sameAsPOA: 'La entrada debe coincidir con su prueba de dirección (POA)',
      sameAsID:
        'El campo introducido debe coincidir con el que aparece en el documento de identidad',
      notBlank: 'Dejar en blanco si no procede',
      digits12WithoutDash: 'Por favor ingrese 12 dígitos, sin "-".',
      enterAsPerId: 'Ingrese según el ID',
      numberReq: 'Por favor, introduzca un número',
      addressReq: 'Por favor, introduzca su dirección',
      suburbReq: 'Por favor, introduzca su ciudad o barrio',
      stateReq: 'El estado o provincia son obligatorios',
      postcodeReq: 'Por favor, introduzca su código postal',
      countryReq: 'Por favor, seleccione su país de residencia',
      AlphanumericOnlyNotSpace: 'Solo alfanumérico. No se permiten espacios.',
      AlphanumericOnly: 'Solo alfanumérico.',
      NumbersSymbol: 'Solo números y símbolos.',
      NumbersOnlyNotSpace: 'Solo números. No se permiten espacios.',
      NumbersOnly: 'Solo números.',
      LettersSymbol: 'Solo letras y símbolos.',
      LettersSymbolNotSpace: 'Solo letras y símbolos. No se permiten espacios.',
      LettersOnlyNotSpace: 'Solo letras. No se permiten espacios.',
      NotSpace: 'No se permite espacio.',
    },
  },
  report: {
    title: { rebateReport: 'REPORTE DE COMISÍON' },
    rebate: {
      column: {
        name: 'NOMBRE',
        accountNumber: 'NÚMERO DE CUENTA',
        accountType: 'TIPO DE CUENTA',
        volume: 'Volumen',
        rebate: 'COMISÍON',
        totalRebate: 'COMISÍON TOTAL',
        date: 'FECHA',
        instrument: 'INSTRUMENTO',
      },
      dropdown: {
        allInstruments: 'Todos los instrumentos',
        all: 'Todas',
        instruments: 'Instrumentos',
      },
      others: {
        rebate: 'COMISÍON',
        instrumentsTraded: 'INSTRUMENTOS COMERCIADOS',
        lots: 'Lotes',
        totalRebate: 'Reembolso total',
        rebates: 'COMISÍON',
      },
    },
  },
  ibReport: {
    header: 'INFORME IB',
    netFunding: 'FINANCIAMIENTO NETO',
    deposits: 'DEPOSITOS',
    withdraw: 'RETIRAR',
    openedAccs: 'CUENTAS ABIERTAS',
    funding: 'FONDOS',
    withdrawals: 'RETIRAR',
    appliedAmount: 'cantidad aplicada',
    depositAmount: 'Cantidad del depósito',
    withdrawalAmount: 'Cantidad de retiro',
    timeLimitMessage:
      'El rango máximo de consulta para la descarga es de 90 días, por favor actualice el nuevo rango de fechas',
    successInfo: 'Descarga exitosa',
    download: 'DESCARGAR',
    comfirm: 'Confirmar',
    defaultTimeMessage: 'El rango máximo de consulta para la descarga es de 90 días',
  },
  lead: { header: 'LEADS', ibAccount: 'Cuenta IB', demoAccounts: 'CUENTAS DEMO' },
  pendingAccounts: {
    header: 'CUENTAS PENDIENTES',
    errorDoc:
      'Los siguientes solicitantes han completado el formulario de solicitud en línea, pero aún deben proporcionar una prueba de identificación (ID) para finalizar su cuenta.',
    acceptedDocType: 'Las formas de identificación aceptadas incluyen:',
    primaryDoc: 'ID principal',
    passport: 'Una copia de su pasaporte internacional válido (página de firma)',
    id: 'Una copia de la tarjeta de identidad nacional emitida por su gobierno',
    dl: 'Una copia de su licencia de conducir válida.',
    address: 'Identificación secundaria',
    addressList: [
      'Copia de una factura de servicios públicos reciente (no más de 6 meses)',
      'Copia del extracto de la tarjeta de crédito (no más de 6 meses)',
      'Copia de un extracto bancario (no más de 6 meses)',
    ],
    tip: 'Anime a los solicitantes a proporcionar una copia de UNO de los documentos de identificación primarios y secundarios aceptados a ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTORIAL DE TRANSACCIONES',
    rebateHistory: 'COMISÍON',
    withdrawHistory: 'RETIROS',
    transferHistory: 'TRANSFERENCIAS',
    cancelWithdraw: 'Le gustaría cancelar la solicitud de retiro?',
    cancelError:
      'Su retiro se está procesando en este momento. Por lo tanto, no podemos aceptar su solicitud de cancelación. Para obtener más información, puede comunicarse con nosotros mediante la función de chat en vivo.',
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de archivo compatibles: {files}',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
    selectFile: 'Seleccionar archivo',
    maxFileSize: 'El tamaño del archivo enviado no puede exceder los {maxSize}MB',
    sureToDelete: {
      title: '¿Está seguro?',
      content: '¿Está seguro de que desea eliminar el archivo enviado？',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'Procesando',
      rejected: 'Rechazado',
      paid: 'Pagado',
      unpaid: 'No pagado',
    },
  },
  referralLinks: {
    header: 'ENLACES DE REFERENCIA',
    ibAcc: 'Cuenta IB',
    campLang: 'Idioma de la campaña',
    cpy: 'COPIAR',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Cuenta real',
    demoAcc: 'Cuenta demo',
    homePage: 'Página de inicio',
    Share: 'Compartir',
    subIbReferral: 'Referencia Sub IB',
  },
  settings: { ibProfile: 'PERFIL IB', info: 'INFORMACÍON', reg: 'REGISTRADO EN' },
  unfundedAcc: {
    header: 'CUENTAS NO FINANCIADAS',
    allActivationAccs: 'TODAS LAS CUENTAS DE ACTIVACIÓN',
    neverFundedAccs: 'CUENTAS NUNCA FINANCIADAS',
    prevFundedZero: 'SALDO CERO',
  },
  multiib: { level: 'NIVEL {level}', noib: 'No hay sub-IBs en esta cuenta.' },
  header: { bckToCP: 'VOLVER AL PORTAL DEL CLIENTE', logout: 'CERRAR SESIÓN' },
  menu: {
    dashboard: 'PANEL DE CONTROL',
    accManagement: 'CUENTAS',
    ibReport: 'INFORME IB',
    rebateReport: 'REPORTE DE COMISÍON',
    iblevel: 'MULTINIVEL',
    ibAccs: 'CUENTAS ACTIVADAS',
    leads: 'LEADS',
    pendingAccs: 'CUENTAS PENDIENTES',
    unfundedAccs: 'CUENTAS NO FINANCIADAS',
    payHistory: 'HISTORIAL DE TRANSACCIONES',
    ibProfile: 'PERFIL IB',
    refLinks: 'ENLACES DE REFERENCIA',
    contactUs: 'CONTÁCTENOS',
    switchCP: 'CAMBIAR AL PORTAL DEL CLIENTE',
  },
  footer: {
    riskWarn:
      'Advertencia de riesgo: el comercio de contratos por diferencia (CFD) conlleva un alto nivel de riesgo para su capital y puede resultar en pérdidas; solo debe operar con dinero que pueda permitirse perder. El comercio de CFD puede no ser adecuado para todos los inversores, asegúrese de comprender completamente los riesgos involucrados y de tomar las medidas adecuadas para gestionarlos. Lea atentamente el documento de divulgación de riesgos correspondiente.',
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    431: 'El cálculo de la rebaja está en curso, por favor inténtelo de nuevo en unos minutos.',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    530: 'Sesión de inicio de sesión no válida',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    553: 'No puede retirar/transferir debido al saldo de su cuenta. Por favor, póngase en contacto con {email} para más información',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No fue posible procesar su pago, por favor, inténtelo de nuevo. Si el problema persiste, por favor contáctenos por chat, o por correo electrónico.',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    582: 'Como se encuentra en el período de promoción y tiene puestos vacantes, no pudimos procesar su solicitud de retiro.',
    584: 'No tiene ninguna comisíon aplicable',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    595: 'Dado que participa en nuestras actividades, su solicitud de transferencia no se puede procesar en este momento.',
    647: 'El código de verificación es incorrecto',
    1101: 'Existen 10 cuentas, no se pueden agregar más.',
    1102: 'No se puede habilitar, la cuenta se creó como una cuenta real o de demostración.',
    1103: 'La cuenta ha sido deshabilitada',
    1202: 'Verificación caducada, por favor inténtelo de nuevo',
    1204: 'Por favor, intente con otra dirección de correo electrónico',
    1208: 'No se pueden enviar códigos de verificación repetidamente',
    1209: 'Error al enviar el código de verificación',
    session_timeout:
      'La sesión ha llegado al límite de tiempo, serás redirigido a la página de acceso.',
  },
  verificationStatus: {
    title: { default: 'Ya casi ha terminado', LV3: 'Le damos la bienvenida' },
    dueToAML:
      '¡Ya está listo para empezar a operar pronto! Ayúdenos a proteger su cuenta verificando sus datos personales.',
    accOpeningVerification: {
      title: 'Crear una cuenta en vivo',
      content: 'Cree una cuenta en vivo para empezar a añadir fondos a su cuenta.',
    },
    identityVerification: {
      title: 'Verificación de identidad',
      content: 'Envíe y verifique su prueba de identidad para empezar a operar.',
    },
    poaVerification: {
      title: 'Verificación de la dirección',
      content:
        'La prueba de dirección (POA) debe verificarse antes de que pueda efectuar una retirada.',
    },
    kyc: {
      depositNow: 'Depositar ahora',
      withdrawNow: 'Retirar ahora',
      createNow: 'Crear ahora',
      start: 'Empezar',
      complete: 'Completado',
      pending: 'Pendiente',
      rejected: 'Rechazada',
      resubmit: 'Volver a enviar',
    },
    resubmitNote:
      'Pueden que falten sus documentos o que sean incorrectos. Por favor, vuelva a enviarlos',
    rejectedNote:
      '¿Tiene alguna pregunta? Por favor, póngase en contacto con nosotros mediante el {liveChat} .',
    liveChat: 'chat en vivo',
    uploadTemplate: {
      idGuideTitle: 'Directrices para los documentos de identidad',
      uploadGuideTitle: 'Instrucciones de envío',
      desc1: 'Emitidos por el gobierno',
      desc2: 'Documentos originales a tamaño completo, sin editar',
      desc3: 'Coloque los documentos sobre un fondo de un solo color',
      desc4: 'Imagen legible, bien iluminada y a color',
      desc5: 'No se aceptan imágenes en blanco y negro',
      desc6: 'No se acepta ningún documento editado o caducado',
      bottomDesc:
        'El archivo enviado puede ser jpg, png o pdf, y su tamaño no puede superar los 5 MB.',
    },
  },
  register: {
    progressStatus: {
      title: 'Proceso de verificación',
      estimatedTimeTitle: 'Tiempo estimado para completar:',
      min: 'Minutos',
    },
    tabs: {
      personalDetails: 'Datos personales',
      moreAboutYou: 'Más sobre usted',
      accountConfig: 'Configuración de cuenta',
      confirmYourId: 'Confirme su identidad',
      fundYourAcc: 'Financie su cuenta',
      personalInformation: 'Información personal',
      createLiveAccount: 'Crear una cuenta en vivo',
      financialInfo: 'Información financiera',
      iDProof: 'Prueba de identidad',
      addressProof: 'Prueba de dirección',
    },
    btn: {
      next: 'SIGUIENTE',
      back: 'ANTERIOR',
      fundnow: 'AÑADIR FONDOS AHORA',
      upload: 'Enviar',
      done: 'HECHO',
      bTCP: 'Volver al portal del cliente',
      proceedToUpload: 'Proceder a enviar',
      completeID: 'Verificación de identidad completa',
      completePOA: 'Verificación de prueba de dirección completa',
      depositViaOther: 'Depositar mediante otro canal',
      withdrawViaOther: 'Retirar a través de otro canal',
    },
    authority: {
      dialog1: 'Debe tener una cuenta en vivo para acceder al portal de cliente.',
      dialog2:
        'Abra una cuenta real ahora para desbloquear el acceso completo al portal del cliente, que incluye una variedad de herramientas comerciales, promociones y más.',
      openLiveAcc: 'abrir cuenta en vivo',
    },
    demo: {
      congrad: '¡Felicitacion!!',
      title: 'Su cuenta DEMO MT{platform} de 60 días está activa ahora',
      title2: 'Datos de su cuenta DEMO',
      li: {
        login: 'Usuario: ',
        password: 'Contraseña: ',
        srv: 'Servidor:',
        expDate: 'Fecha de vencimiento: ',
      },
      emailSent: 'Los datos de inicio de sesión se han enviado a su correo electrónico también.',
      dl: 'Descargue su plataforma de trading MT{platform}',
      improveQ: '¿Desea mejorar su trading?',
      improveWay:
        'Simplemente, abra una cuenta en vivo en solo unos minutos para conseguir una variedad de promociones y herramientas de trading exclusivas.',
      proTrade: 'Herramientas Pro Trader',
      depositBonus: ' Bono por depósito del 20%',
      smartTradeTool: 'Herramientas Smart Trader',
      openLiveAcc: 'abrir cuenta en vivo',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Datos personales',
        userTitle: 'Título',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'E-mail',
        dob: 'Fecha de nacimiento',
        idType: 'Tipo de identificación',
        referral: '¿Ha sido recomendado por alguien? (opcional)',
        middleName: 'Segundo nombre',
        nationality: 'Nacionalidad',
        phoneNum: 'Número de teléfono',
        date: 'Día',
        month: 'MontMesh',
        year: 'Año',
        placeOfBirth: 'Lugar de nacimiento',
        idNum: 'Número de identificación',
        passportNum: 'Número del pasaporte',
        driverLicenseNum: 'Número del permiso de conducir',
        titleDropdown: { mr: 'Sr', mrs: 'Sra', ms: 'Srto', miss: 'Srta', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Pasaporte',
          idCard: 'Documento nacional de identidad',
          driverLicense: 'Permiso de conducir',
        },
      },
      page2: {
        pageTitle: 'DIRECCIÓN PRINCIPAL',
        mainResidency: 'País principal de residencia',
        streetNumber: 'Número de la calle',
        streetName: 'Nombre de la calle',
        address: 'Dirección',
        provinceOrState: 'Provincia/Estado',
        cityOrSuburb: 'Ciudad/Barrio',
        postcode: 'Código postal',
        usQuestion:
          '¿Es usted ciudadano estadounidense/residente en EE. UU. a efectos fiscales o tiene vínculos con los EE. UU. (como una cuenta bancaria, etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLEO Y DATOS FINANCIEROS',
      pageTitle2: 'Experiencia y conocimiento de trading',
      complianceExplain:
        'Las siguientes preguntas se recopilan para cumplir con los requisitos AML/CTF.',
    },
    accountConfig: {
      pageTitle: 'Configuración de cuenta',
      chooseTradingPlatform: 'Elija una plataforma de trading',
      chooseAccType: 'Elija un tipo de cuenta',
      chooseAccCurrency: 'Elija una divisa de cuenta',
    },
    confirmYourId: {
      pageTitle: 'Confirme su identidad',
      description:
        'Como broker de forex regulado, debemos verificar su identidad. Envíe uno de los siguientes documentos para su verificación de identidad y dirección. También, puede enviar sus documentos de verificación por correo electrónico a {mailTo}',
      poidTitle: 'Prueba de identidad',
      porTitle: 'Prueba de dirección',
      uploadDescription: 'Elija un tipo de documento y envíelo',
      note: 'IMPORTANTE: El documento DEBE mostrar claramente su nombre completo.',
      pending: { upload: 'Pendiente de enviar', verification: 'Pendiente de verificar' },
      poidTypes: {
        photoId: 'Documento de identidad con foto',
        passport: 'Pasaporte',
        drivingLicence: 'Permiso de conducir',
      },
      porTypes:
        '<li>Factura de gastos</li>\n      <li>Extracto bancario</li>\n      <li>Certificado de residencia</li>',
    },
    finishPage: {
      title: '{individualUserName}, ¡gracias por solicitar una cuenta en vivo en {platformName}!',
      contentDefault:
        'Hemos recibido su solicitud y configurado su cuenta en vivo<b>{account}</b>. <br />\n      En breve, recibirá un correo electrónico en <b>{email}</b> con sus datos de inicio de sesión y un enlace para ver el estado de su cuenta. <br />',
      contentWithoutId3WithAccount:
        'Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />\n      Para agilizar el procesamiento de su cuenta real, puede financiar su cuenta ahora y recibir una apertura prioritaria.<br />',
      contentWithoutId3WithoutAccount:
        'Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Verificación de apertura de cuenta',
      personalInformation: {
        narrative: 'Sus datos personales son necesarios para abrir su cuenta en vivo',
        firstName: 'Nombre',
        middleName: 'Segundo nombre',
        lastName: 'Apellidos',
        gender: 'Sexo',
        genderList: { male: 'Hombre', female: 'Mujer', other: 'Otro' },
        dob: 'Fecha de nacimiento',
        day: 'Día',
        month: 'Mes',
        year: 'Año',
        mobile: 'Número de móvil',
        countryName: 'País de residencia',
        nationality: 'Nacionalidad',
      },
      createLiveAccount: {
        narrative:
          'Complete unos pocos pasos para abrir una cuenta y empezar a añadir fondos. Más información sobre nuestros tipos de cuenta <a href="{link}" target="_blank" alt>aquí</a>.',
        choose: 'Elegir {dynamic}',
        tradingPlatform: 'plataforma de trading',
        accountType: 'el tipo de cuenta',
        accountCurrency: 'divisa de la cuenta',
        islamicTooltip:
          '{platform} puede pedirle que aporte documentos justificantes para comprobar que profesa la fe islámica. {platform} se reserva el derecho a modificar la configuración de su cuenta si falla la verificación.',
        successInfo: {
          title: 'Cuenta de trading creada',
          content:
            '¡Enhorabuena! Ha completado con éxito la apertura de la cuenta.  <br /> Para empezar a operar, haga un depósito ahora y complete la verificación de su identidad.',
        },
        successInfo2: {
          title: 'Cuenta de trading creada',
          content:
            'Rellene la información de su perfil financiero para iniciar el siguiente paso de la verificación de su cuenta',
          start: 'Empezar',
        },
        generalInfo: {
          title: 'Su solicitud está siendo revisada.',
          content:
            'Gracias por abrir una cuenta en {platform}. Puede empezar a añadir fondos en su cuenta en cuanto se apruebe su solicitud.',
        },
        rejectedInfo: {
          title: 'Rechazada',
          content:
            'Lamentamos informarle de que su solicitud para abrir una cuenta en {platform} ha sido rechazada.',
        },
      },
    },
    identityVerification: {
      title: 'Verificación de identidad',
      subTitle: 'Información de identidad',
      narrative: 'Rellene los datos',
      firstName: 'Nombre',
      middleName: 'Segundo nombre',
      lastName: 'Apellidos',
      nationality: 'Nacionalidad',
      idType: 'Tipo de documento de identidad',
      idSerialNumber: 'Número de serie del documento de identidad',
      passportNum: 'Número del pasaporte',
      driverLicenseNum: 'Número del permiso de conducir',
      upload: {
        title: 'Enviar documento',
        desc: 'Asegúrese de que el documento muestra su foto, nombre completo, fecha de nacimiento y fecha de expedición.',
        guideline: 'Instrucciones de envío',
        positive: 'Envío correcto',
        signaturePage: 'Enviar la página de la firma',
      },
      idTypeDropdown: {
        passport: 'Pasaporte',
        idCard: 'Documento nacional de identidad',
        driverLicense: 'Permiso de conducir',
        other: 'Otro',
      },
      additionalInfo: {
        title: 'Información adicional requerida',
        content: 'Verifique su identidad para empezar a operar',
      },
      successInfo: {
        title: 'Verificación de documento de identidad completa',
        content:
          '¡Enhorabuena! Su documento de identidad ha sido verificado Ahora puede empezar a operar en MT4/MT5 o en la aplicación {platform} si ya ha efectuado un depósito.',
      },
      generalInfo: {
        title: 'Su solicitud está siendo revisada.',
        content:
          'Gracias por abrir una cuenta en {platform}. Podrá empezar a operar en cuanto se haya verificado su información de identidad.',
      },
      rejectedInfo: {
        title: 'Rechazada',
        content:
          'Lamentamos informarle de que su solicitud para abrir una cuenta en {platform} ha sido rechazada.',
      },
      identifyingRequiredInfo: {
        title: 'Información adicional requerida',
        content:
          'Algunos de sus documentos de identidad pueden faltar o ser incorrectos. Por favor, vuelva a enviarlos para continuar.',
      },
      streetNumber: 'Número de la calle',
      streetName: 'Nombre de la calle',
    },
    poaVerification: {
      proofOfAddressVer: 'Verificación de la prueba de dirección',
      subTitle: 'Información de dirección',
      narrative: 'Rellene los datos',
      dueToAML:
        'Debido a los requisitos AML, su dirección debe ser verificada antes de que pueda efectuar una retirada.',
      nationality: 'Nacionalidad',
      country: 'País de residencia',
      address: 'Dirección',
      postalCode: 'Código postal',
      citySuburb: 'Ciudad/Suburbio',
      city: 'La ciudad',
      provinceState: 'Provincia/Estado',
      upload: {
        title: 'Certificado de prueba de identidad (POA)',
        inst1: 'Factura de gastos (electricidad, agua, gas)',
        inst2: 'Extractos del banco o de tarjeta de crédito',
        desc: 'La información de la prueba de dirección para retiradas debe haberse emitido en los últimos {months} meses.',
        guideline: 'Instrucciones de envío',
        uploadFilesPhoto: 'Enviar archivos/fotos',
      },
      additionalInfo: {
        title: 'Información adicional requerida',
        content:
          'La prueba de dirección (POA) debe verificarse antes de que pueda efectuar una retirada.',
      },
      successInfo: {
        title: 'Prueba de dirección verificada',
        content:
          '¡Enhorabuena! Su prueba de dirección ha sido verificada Ya puede efectuar retiradas.',
      },
      generalInfo: {
        title: 'Su solicitud está siendo revisada.',
        content:
          'Gracias por operar con {platform}. Puede comenzar el proceso de retiro una vez que se apruebe su envío.',
      },
      identifyingRequiredInfo: {
        title: 'Información adicional requerida',
        content:
          'Algunos de sus documentos de identidad pueden faltar o ser incorrectos. Por favor, vuelva a enviarlos para continuar.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Su información financiera',
        subTitle: 'Datos laborales y financieros',
        subTitle2: 'Conocimientos y experiencia de trading',
        employmentStatus: 'Situación laboral',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} a la semana',
        estimatedAnnualIncome: 'Estimación de ingresos anuales',
        estimatedSavingsAndInvestments: 'Estimación de ahorros e inversiones',
        estimatedIntendedDeposit: 'Depósito previsto estimado',
        sourceOfFunds: 'Procedencia de los fondos',
        numberOfTrades: 'Número de operaciones',
        tradingAmount: 'Cantidad negociada',
      },
      iDProof: {
        ver: 'Enviar prueba de identidad',
        subTitle: 'Información de identidad',
        narrative: 'Rellene los datos',
      },
      addressProof: {
        ver: 'Enviar prueba de dirección (POA)',
        subTitle: 'Información de dirección',
        narrative: 'Rellene los datos',
        upload: {
          title: 'Documentos aceptados como prueba de dirección',
          inst1: 'Factura de gastos (electricidad, agua, gas)',
          inst2: 'Extractos del banco o de tarjeta de crédito',
          desc: 'La información de la prueba de dirección para retiradas debe haberse emitido en los últimos {months} meses.',
          guideline: 'Instrucciones de envío',
          uploadFilesPhoto: 'Enviar archivos/fotos',
        },
      },
      successInfo: {
        title: 'Autenticación bancaria completada',
        content: '¡Enhorabuena! Ha verificado sus datos bancarios con éxito.',
      },
      generalInfo: {
        title: 'Su solicitud está siendo revisada.',
        content:
          'Sus documentos para transferencia bancaria aún están en revisión. Si desea comenzar a operar ahora, aún puede realizar un depósito a través de otro canal.',
      },
      rejectedInfo: {
        title: 'Rechazada',
        content: 'Su solicitud de ingreso mediante transferencia bancaria ha sido rechazada.',
      },
      identifyingRequiredInfo: {
        title: 'Información adicional requerida',
        content:
          'Algunos de sus documentos de identidad pueden faltar o ser incorrectos. Por favor, vuelva a enviarlos para continuar.',
      },
    },
  },
  ibProfile: {
    title: 'PERFIL IB',
    subTitle: 'IB Compartido',
    sharedIbList: {
      sharedIbAccount: 'Cuenta IB compartida',
      maxSharedIbAccount: 'Máximo 10 cuentas IB compartidas',
      add: 'Agregar',
      accountHistory: 'Historial de cuenta IB compartida',
      setting: 'Configuración',
      delete: 'Eliminar',
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      enabled: 'Habilitado',
      disabled: 'Deshabilitado',
      sharedAccountEnabled: 'La cuenta IB compartida ha sido habilitada.',
      sharedAccountDisabled: 'La cuenta IB compartida ha sido deshabilitada.',
      sharedAccountCopied: 'Cuenta IB compartida copiada',
      deleteTips:
        'La cuenta IB compartida no podrá iniciar sesión después de la eliminación, ¿desea eliminarla?',
      sharedAccountDeleted: 'La cuenta IB compartida ha sido eliminada.',
      copyTips: 'Cuenta IB compartida copiada',
      remark: 'Observación',
      cancel: 'Cancelar',
      accountData: 'Datos de cuentas',
      IBData: 'Reporte de datos IB',
      fundOperations: 'Permiso de fondos',
      resetPassword: 'Restablecer contraseña',
      accountHistoryBtn: 'Historial de cuenta compartida',
    },
    updateSharedIb: {
      addNewSharedIb: 'Agregar nueva cuenta IB compartida',
      editSharedIb: 'Editar cuenta IB compartida',
      maxSharedIbAccountError: 'Existen 10 cuentas, no se pueden agregar más.',
      email: 'Dirección de correo electrónico',
      emailValidate: 'Ingrese una dirección de correo electrónico válida',
      emailError: 'El correo electrónico ya existe, por favor intente con otro',
      password: 'Contraseña',
      passwordValidate: 'Ingrese la contraseña',
      passwordValidateNum: '8-16 caracteres',
      passwordValidateTips:
        'Mezcla de letras mayúsculas y minúsculas (A-Z, a-z), números (0-9) y caracteres especiales (por ejemplo: !@#S%^&)',
      passwordValidateWarning:
        '8-16 caracteres,Mezcla de letras mayúsculas y minúsculas (A-Z, a-z), números (0-9) y caracteres especiales (por ejemplo: !@#S%^&)',
      sharedIbAccountPermission: 'Permiso de cuenta IB compartida',
      remark: 'Observación',
      remarkTips: 'Puede registrar el propósito de la cuenta o el titular de la cuenta',
      status: 'Estado',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      disabled: 'Deshabilitado',
      enabled: 'Habilitado',
      newPassword: 'Ingrese la nueva contraseña',
      permissionIdsTips:
        'Puede permitir o no las siguientes autorizaciones para cuentas compartidas:',
      permissionIdsFirst:
        '<b>Datos de la cuenta</b>: Ver páginas de datos de la cuenta (Cuentas IB, Cuentas abiertas recientemente). Los datos se ocultarán si no se permite el permiso acceso.',
      permissionIdsSecond:
        '<b>Reporte de datos IB</b>: Ver páginas de datos de IB (Panel de control, IB multinivel: cuenta). Los datos se ocultarán si no se permite el permiso acceso.',
      permissionIdsThird:
        '<b>Permiso de fondos</b>: Operar con fondos IB (Solicitar reembolso, Transferir reembolso, Retirar reembolso),Ver reembolso (informe de reembolso, registros de saldo y fondos). No se pueden realizar operaciones si no se permite el permiso acceso.',
      newPassowrdReset: 'Ingrese la nueva contraseña',
      passwordSame: 'La nueva contraseña no puede ser la misma que la antigua.',
      resetPasswordSuccess: 'Restablecimiento de contraseña exitoso',
      enterNewPassword: 'Por favor ingrese la nueva contraseña',
      changePasswordTips:
        'La antigua contraseña será inválida y se restablecerá con la nueva contraseña',
      fundsChangeTips:
        'Después de permitir la autorización de fondos, la cuenta IB compartida puede transferir y retirar el saldo de su cuenta IB principal. ¿Desea permitir esto?',
      addSuccess: 'Cuenta compartida creada',
      emailPlaceholder: 'Por favor, introduzca su correo electrónico',
      resetPassword: 'Restablecer contraseña',
      editSuccess: 'Modificado con éxito',
    },
    sharedIbHistory: {
      allHistory: 'Todo el historial ha sido mostrado',
      lastUpdate: 'Última actualización',
      refreshTips: 'Historial actualizado',
      refresh: 'Actualizar',
      chooseAccount: 'Elegir cuenta',
      device: 'Dispositivo',
      login: 'Iniciar sesión',
      commission: 'Solicitar reembolso',
      withdraw: 'Retirar reembolso',
      transfer: 'Transferir reembolso',
      fromTo: 'De {fromAccount} a {toAccount}, monto {total}',
      viewMore: 'Ver más',
    },
    npPermissionTips: 'Sin permisos para esta cuenta',
  },
  agreement: {
    title: 'Confirmando Acuerdo de Introducing Broker (IB)',
    rebateAccount: 'Cuenta de reembolso',
    description: 'Al hacer clic en Aceptar, confirma que ha leído, entendido y aceptado toda la información, los términos y condiciones del servicio en el acuerdo.',
    agree: 'Aceptar',
    signed: 'Cuenta de reembolso {accountID}. Firmado el {signedTime}',
    IBAgreement: 'Acuerdo IB',
    confirmAgreement: 'Por favor, contacte al Gerente IB para confirmar el acuerdo IB'
  }
};
