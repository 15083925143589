export default {
  en: 'English',
  home: {
    NewPortal: 'Cổng Mới',
    SecureIBPortal: 'Cổng IB',
    DASHBOARD: 'Bảng Điều Khiển',
    MARKETING_TOOLS: 'Công Cụ Marketing',
    IB_REPORT: 'Báo Cáo IB',
    REBATE_REPORT: 'Báo Cáo Hoa Hồng',
    IB_ACCOUNTS: 'Tài Khoản IB',
    PAYMENT_HISTORY: 'LỊCH SỬ GIAO DỊCH',
    IB_FAQ: 'Câu Hỏi Thường Gặp Về IB',
    RebateAccountNumber: 'Số Tài Khoản Hoa Hồng',
    totalBalance: 'Tổng Số Dư',
    totalCommission: 'Tổng Hoa Hồng',
    APPLY_FOR_A_REBATE: 'ỨNG DỤNG',
    availableBalance: 'Số Dư Khả Dụng',
    WITHDRAW_REBATE: 'Rút',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Chuyển khoản',
    MonthtoDate: 'Từ Đầu Tháng',
    UPDATE: 'CẬP NHẬT',
    REBATE: 'HOA HỒNG',
    TOTAL_VOLUME: 'TỔNG KHỐI LƯỢNG',
    NET_FUNDING: 'TIỀN NẠP RÒNG',
    DEPOSITS: 'Nạp Tiền',
    WITHDRAW: 'Rút Tiền',
    OPEND_ACCOUNT: 'Tài Khoản Đã Mở',
    PERFORMANCE: 'HIỆU SUẤT',
    OpendAccount: 'Tài Khoản Đã Mở',
    select_ib: 'VUI LÒNG CHỌN TÀI KHOẢN IB',
    MOST_TRADED_INSTRUMENTS: 'NHỮNG MÃ GIAO DỊCH NHIỀU NHẤT',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'NHỮNG TÀI KHOẢN HIỆU SUẤT TỐT NHẤT THÁNG NÀY',
    RECENTLY_OPENED_ACCOUNTS: 'NHỮNG TÀI KHOẢN MỚI MỞ',
    ACCOUNT_MANAGER: 'HỖ TRỢ KHÁCH HÀNG',
    Account: 'Tài Khoản',
    Name: 'Tên',
    Volume: 'Khối Lượng',
    Rebate: 'Hoa Hồng',
    Balance: 'Số Dư',
    ContactClient: 'HỖ TRỢ KHÁCH HÀNG',
    YouCannotAskForCommission: 'Bạn không thể yêu cầu một khoản hoa hồng',
    applicationFailedPleaseTryAgain: 'Yêu cầu thất bại, vui lòng thử lại sau',
    applyRebateSuccessTip:
      'Xin chúc mừng, chúng tôi đã nhận được yêu cầu rút hoa hồng của bạn. | {amount} sẽ được chuyển vào Tài khoản hoa hồng {account} trong vòng 1 ngày làm việc. | Bạn có thể rút hoặc chuyển tiền sau khi khoản hoa hồng của bạn được xử lý.',
    HOVER: 'Các khoản hoa hồng được tính toán và xử lý trong khoảng 09:00 - 11:00 AEST hàng ngày.',
    blacklist:
      'Tài khoản này hiện không thể nhận chiết khấu, vui lòng liên hệ với người quản lý tài khoản của bạn.',
    ACCOUNTS_PROFIT:
      'Tổng lãi/lỗ trong lịch sử của tất cả các tài khoản giao dịch có tài khoản IB này. Quá trình tải có thể mất một chút thời gian.',
    PROFIT: 'TÀI KHOẢN LỢI NHUẬN',
    reload: 'Tải lại',
  },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    header: 'Liên Hệ Với Chúng Tôi',
    followUs: 'theo chúng tôi',
    tip: 'Nhóm hỗ trợ khách hàng chuyên nghiệp đa ngôn ngữ của {platform} sẵn sàng hỗ trợ bạn và giúp cung cấp cho bạn trải nghiệm giao dịch không đâu có thể sánh bằng.',
    chatNow: 'Hãy Trò chuyện ngay bây giờ',
    globalOffice: 'Văn phòng Toàn cầu',
    liveChat: 'Trò chuyện trực tiếp',
  },
  ibAccounts: {
    header: 'Tài Khoản IB',
    ibAccount: 'Tài Khoản IB',
    subIbs: 'Tài Khoản IB Phụ',
    tradinglist: 'Danh sách các giao dịch',
    openlist: 'Mở danh sách',
    closedlist: 'Danh sách đã đóng',
    tradinglistColumn: {
      ticketid: 'Ticket ID',
      opentime: 'Thời gian mở',
      type: 'Loại',
      volume: 'Khối lượng',
      item: 'Khối lượng',
      openprice: 'Mở giá',
      closeprice: 'Đóng giá',
      closetime: 'Thời gian đóng',
      commission: 'Hoa hồng',
      profit: 'Lợi nhuận',
      buy: 'Mua',
      sell: 'Bán',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Chọn thẻ tín dụng',
      selectBA: 'Chọn tài khoản ngân hàng',
      anotherCC: 'Nhập thẻ tín dụng bằng cách thủ công',
      anotherBA: 'Thêm tài khoản ngân hàng',
      remember: 'Ghi nhớ tài khoản của tôi',
      verificationCode:
        'Mã xác minh được gửi đến {email}. Vui lòng liên hệ {supportEmail} nếu bạn cần hỗ trợ thêm',
      fetchTradeAccFailed: 'Tìm nạp tài khoản giao dịch không thành công',
      failed: 'Rút tiền thất bại. Vui lòng thử lại sau',
      tokenExpired: 'Token đã hết hạn',
    },
    alert:
      'Do luật AML, phương thức bạn sử dụng để rút phải giống với phương thức được sử dụng để gửi tiền của bạn. Nếu số tiền rút ít hơn 100 đơn vị tiền tệ cơ sở trong tài khoản của bạn, bạn sẽ bị ngân hàng tính 20 đơn vị phí xử lý.',
    NetellerChargeAlert: 'Neteller tính phí giao dịch {percentageFee}% cho mỗi lần rút tiền.',
    FasaPayChargeAlert: 'FasaPay tính phí giao dịch 0,5% cho mỗi lần rút tiền.',
    SkrillChargeAlert: 'Skrill tính phí giao dịch {percentageFee}% cho mỗi lần rút tiền.',
    cryptoAlert:
      'Xin lưu ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT, vui lòng đảm bảo địa chỉ và Tiền điện tử khớp với chuỗi và đơn vị tiền tệ mà chúng tôi chấp nhận, nếu không bạn có thể bị mất tiền.',
    REBATE_WITHDRAW_REQUEST: 'YÊU CẦU RÚT HOA HỒNG',
    PleaseSelectDesiredWithdrawMethod: 'Vui lòng chọn phương thức rút tiền',
    BankName: 'Tên Ngân Hàng',
    BankAccountsName: 'Tên Tài Khoản Ngân Hàng',
    FasaPayAccountName: 'Tên Tài Khoản FasaPay',
    AustralianBankName: 'Tên Ngân Hàng Úc',
    BankAccountNumber: 'Số Tài Khoản Ngân Hàng',
    FasaPayAccountNumber: 'Số Tài Khoản FasaPay',
    BankAccountBeneficiary: 'Tài Khoản Thụ Hưởng',
    BSB: 'BSB',
    skrillEmail: 'Skrill Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    SticpayEmail: 'SticPay Email',
    BitcoinEmail: 'Địa chỉ ví Bitcoin',
    USDTEmail: 'Địa chỉ ví Tether',
    BankBranch: 'Chi Nhánh Ngân Hàng',
    Province: 'Tỉnh',
    City: 'Thành Phố',
    BankAddress: 'Địa Chỉ Ngân Hàng',
    bankCity: 'Tỉnh',
    bankProvince: 'Thành Phố',
    AccountNumberIBAN: 'Số Tài Khoản/IBAN',
    BankBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    BankAccountBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    AccountHoldersAddress: 'Tên Chủ Tài Khoản',
    SelectWithdrawCard: 'Chọn Loại Thẻ Bạn Muốn Rút',
    Swift: 'Mã SWIFT',
    SwiftAud: 'Mã SWIFT (Không phải tài khoản AUD)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: 'Ghi Chú Quan Trọng',
    YourPaymentGreaterThanAvailableBalance: 'Khoản thanh toán của bạn lớn hơn số dư khả dụng',
    CannotUseMoreThanTwoDecimalValues:
      'Bạn không thể sử dụng Số và Số âm có nhiều hơn hai chữ số thập phân',
    YourPaymentIsBeingProcessed: 'Đơn xin rút tiền của bạn đã được gửi đi.',
    Itcannotbe0: 'Không thể điền 0.',
    noCardWarn:
      'Bạn không có thẻ nào để rút tiền, vui lòng liên kết thẻ của bạn trong Chi Tiết Rút Tiền trước',
    amtLarger: 'Số tiền không thể để trống và phải lớn hơn hoặc bằng {minLimit} {currency}',
    blackList:
      'Rất tiếc, bạn hiện không thể gửi yêu cầu rút tiền. Vui lòng liên hệ {supportEmail} để biết thêm chi tiết.',
    status: {
      withdrawalSubmitted: 'Đã gửi',
      withdrawalCanceled: 'Đã hủy',
      withdrawalAuditing: 'Đang Xử Lý',
      withdrawalRejected: 'Từ Chối',
      withdrawalAccepted: 'Đang Xử Lý',
      withdrawalPayFailed: 'Lỗi',
      withdrawalSuccess: 'Đã Xử Lý',
      withdrawalFailed: 'Lỗi',
      withdrawalPending: 'Tạm Ngưng',
      withdrawalPartialFailed: 'Lỗi',
      transferSubmitted: 'Đang Xử Lý',
      transferSuccess: 'Đã Xử Lý',
      transferRejected: 'Từ Chối',
      transferFailed: 'Lỗi',
      transferProcessing: 'Đang Xử Lý',
      withdrawalReversedFailed: 'Đảo ngược thất bại',
    },
    formErrorMsg: {
      bankName: 'Tên Ngân Hàng là bắt buộc',
      bsb: 'BSB là bắt buộc',
      beneName: 'Tên người thụ hưởng ngân hàng là bắt buộc',
      accNum: 'Số tài khoản ngân hàng là bắt buộc',
      swift: 'Mã Swift là bắt buộc',
      bankAddress: 'Địa chỉ ngân hàng là bắt buộc',
      holderAddress: 'Địa chỉ chủ tài khoản là bắt buộc',
      amt: 'Số tiền là bắt buộc',
      firstSixDigits: 'Vui lòng nhập 6 chữ số đầu tiên',
      lastFourDigits: 'Vui lòng nhập 4 chữ số cuối cùng',
      branchName: 'Tên chi nhánh là bắt buộc',
      email: 'Email là bắt buộc',
      accName: 'Tên tài khoản là bắt buộc',
      province: 'Địa chỉ ngân hàng (Tỉnh) là bắt buộc',
      city: 'Địa chỉ ngân hàng (Thành Phố) là bắt buộc',
      bankCard: 'Vui lòng chọn một thẻ',
      emailFormat: 'Vui lòng nhập địa chỉ email chính xác',
      amt0: 'Số tiền không thể là 0',
      bitcoin: 'Địa chỉ ví Bitcoin là bắt buộc',
      addressLength: 'Địa chỉ ví Bitcoin phải chứa từ 26-42 ký tự',
      usdt: 'Địa chỉ ví USDT là bắt buộc',
      beneficiaryNameReq: 'Tên người thụ hưởng ngân hàng là bắt buộc',
    },
    transfer: {
      info1:
        '{platform} sẽ không trừ phí ngân hàng trong lần đầu rút tiền trong tháng. Các lần rút tiền tiếp theo trong cùng tháng sẽ phát sinh phí ngân hàng là 20 đơn vị tiền tệ rút tiền của bạn (Đối với USC/HKD/JPY, phí sẽ tương đương 20 USD)',
      info2:
        'Vui lòng đảm bảo Tên người thụ hưởng ngân hàng được điền chính xác như trên hộ chiếu (không viết tắt, và điền đầy đủ họ tên theo chữ cái đầu trên sao kê)',
      info3:
        'Ưu tiên tài khoản ngân hàng có định dạng IBAN, nếu bạn không thuộc khu vực châu Âu, vui lòng điền chính xác với sao kê ngân hàng',
      label: {
        upload: 'Vui lòng tải lên bản sao bảng sao kê ngân hàng của bạn (trong vòng 3 tháng)',
        upload2:
          'Vui lòng đảm bảo rằng ngày sao kê hoặc ngày giao dịch cuối cùng nằm trong khoảng từ tháng hiện tại đến ba tháng trước. Nếu vượt quá khoảng thời gian này, vui lòng tải lại sao kê.',
      },
      formValidation: { upload: 'Vui lòng tải lên một sao kê ngân hàng' },
      minAmount: 'Số tiền rút tối thiểu là 100 {currency}',
    },
    transitNumber: 'Số quá cảnh',
    institutionNumber: 'Số tổ chức',
    beneficiaryName: 'Tên người hưởng',
  },
  security: {
    contactSupport:
      'Không thể truy cập phương thức xác thực? Vui lòng liên hệ {contact_email} hoặc Livechat',
    securityAuthenticator: 'Xác thực bảo mật',
    verificationRequired: 'Vui lòng nhập mã xác minh',
    resend: 'Gửi lại',
    authenticatorTooltip: 'Mã xác minh của ứng dụng Authenticator sẽ được làm mới mỗi 30 giây',
    verifyPreEmailNote: 'Mã xác thực sẽ được gửi đến {email}',
    verifyPostEmailNote: 'Mã xác thực đã được gửi đến {email}',
    enableNow: 'Kích hoạt ngay bây giờ',
    verificationTitle: 'Yêu cầu xác minh',
    verificationDesc: 'Vui lòng bật ứng dụng Security Authenticator trước khi tiếp tục.',
  },
  rebate: { status: { processed: 'Đã Xử Lý', processing: 'Đang Xử Lý', rejected: 'Từ Chối' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'YÊU CẦU CHUYỂN HOA HỒNG',
    transferAmountIsGreaterThanAvailableBalance: 'Số tiền chuyển của bạn lớn hơn số dư khả dụng',
    yourRebateTransferHasBeenProcessed: 'Chuyển khoản hoa hồng của bạn đã được gửi',
    PleaseEnterTransferInformation: 'Vui lòng nhập thông tin chuyển khoản chính xác',
    tradingAccount: 'Tài khoản giao dịch',
    blackList:
      'Tài khoản này đã bị hạn chế thực hiện chuyển khoản giữa các tài khoản, vui lòng liên hệ với {supportEmail}',
  },
  common: {
    lang: {
      english: 'English',
      chinese: 'Tiếng Trung',
      french: 'Tiếng Pháp',
      thai: 'Tiếng Thái Lan',
      german: 'Tiếng Đức',
      spanish: 'Tiếng Tây Ban Nha',
      malay: 'Tiếng Malaysia',
      viet: 'Tiếng Việt',
      indo: 'Tiếng Indonesia',
      arabic: 'Tiếng Ả Rập',
    },
    button: {
      iKnow: 'Tôi xác nhận',
      confirmCamelCase: 'Xác nhận',
      confirm: 'XÁC NHẬN',
      submit: 'Gửi',
      cancel: 'HỦY BỎ',
      sendCode: 'Gửi mã code',
      resendCode: 'Mã gửi lại',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Việc rút tiền tạm thời không được hỗ trợ',
      limitPassword:
        'Do việc thay đổi mật khẩu đăng nhập của bạn, việc rút tiền tạm thời không được hỗ trợ trong {day} ngày vì lý do an toàn. Hạn chế này dự kiến sẽ được khắc phục trong {hour} giờ。',
      limitUserLogin:
        'Do việc sửa đổi email/số điện thoại đăng nhập của bạn, việc rút tiền tạm thời không được hỗ trợ trong {day} ngày. Hạn chế dự kiến sẽ được khắc phục sau {hour} giờ.',
      limitSecurityAuthenticator:
        'Do sửa đổi của ứng dụng Xác thực Bảo mật của bạn, việc rút tiền tạm thời không được hỗ trợ trong {day} ngày để đảm bảo an ninh tài khoản của bạn. Hạn chế này dự kiến sẽ được gỡ bỏ trong vòng {hour} giờ.',
      banktransfer: 'Chuyển khoản ngân hàng quốc tế',
      aus: 'Chuyển khoản ngân hàng - Úc',
      int: 'Chuyển khoản ngân hàng - Quốc Tế',
      chn: 'Chuyển khoản ngân hàng - Trung Quốc',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Chuyển khoản UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Thẻ tín dụng',
      bpay: 'Bpay',
      creditdebitcard: 'Thẻ tín dụng / thẻ ghi nợ',
      bankwiretransferinternational: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      banktransferinternational: 'Chuyển khoản ngân hàng quốc tế',
      banktransferchina: 'Chuyển khoản ngân hàng (quốc tế)',
      mobilepay: 'Thanh toán di động',
      brokertobrokertransfer: 'Chuyển từ nhà môi giới khác',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Ngân Hàng Điện Tử (Thái Lan)',
      internetbankingnigeria: 'Ngân Hàng Điện Tử (Nigeria)',
      internetbankingvietnam: 'Ngân Hàng Điện Tử (Việt Nam)',
      internetbankingmalaysia: 'Ngân Hàng Điện Tử (Malaysia)',
      internetbankingindonesia: 'Ngân Hàng Điện Tử (Indonesia)',
      internetbankingkorea: 'Ngân Hàng Điện Tử (Korea)',
      internetbankingphilippine: 'Ngân Hàng Điện Tử (Philippines)',
      internetbankingsouthafrica: 'Ngân Hàng Điện Tử (Nam Phi)',
      internetbankinguganda: 'Ngân Hàng Điện Tử (Uganda)',
      internetbankingrwanda: 'Ngân Hàng Điện Tử (Rwanda)',
      internetbankingzambia: 'Ngân Hàng Điện Tử (Zambia)',
      internetbankingcongo: 'Ngân Hàng Điện Tử (Congo)',
      internetbankingcameroon: 'Ngân Hàng Điện Tử (Cameroon)',
      internetbankingburundi: 'Ngân Hàng Điện Tử (Burundi)',
      internetbankingkenya: 'Ngân Hàng Điện Tử (Kenya)',
      internetbankingghana: 'Ngân Hàng Điện Tử (Ghana)',
      internetbankingtanzania: 'Ngân Hàng Điện Tử (Tanzania)',
      internetbankingcanada: 'Ngân Hàng Điện Tử (Canada)',
      internetbankingbrazil: 'Ngân Hàng Điện Tử (Brazil)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Chuyển khoản ngân hàng Việt Nam',
      nigeriabanktransfer: 'Chuyển khoản ngân hàng Nigeria',
      polipayment: 'Thanh toán Poli',
      bankwiretransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      thailandinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Thái Lan',
      malaysiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Malaysia',
      banktransferbpaypolipay: 'Chuyển khoản ngân hàng / BPay / PoliPay',
      cryptocurrencybitcoin: 'Tiền điện tử-Bitcoin',
      cryptocurrencyusdt: 'Tiền điện tử-USDT',
      cryptocurrencyethcps: 'Tiền điện tử-ETH',
      cryptocurrencyusdccps: 'Tiền điện tử-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'Chuyển khoản ngân hàng Nigeria',
      vietnaminstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Việt Nam',
      indonesiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Indonesia',
      philippinesinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Philippines',
      southafricainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nam Phi',
      indiainstantbanktransfer: 'Chuyển khoản nhanh ngân hàng Ấn độ',
      indiabanktransfer: 'Chuyển khoản ngân hàng Ấn Độ ',
      southkoreabanktransfer: 'Chuyển khoản ngân hàng tức thì của Hàn Quốc',
      ugandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Uganda',
      rwandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Rwanda',
      zambiabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Zambia',
      congobanktransfer: 'Chuyển khoản Ngân hàng Địa phương Congo',
      cameroonbanktransfer: 'Chuyển khoản Ngân hàng Địa phương Cameroon',
      burundibanktransfer: 'Chuyển khoản Ngân hàng Địa phương Burundi',
      kenyabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Kenya',
      ghanabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Ghana',
      tanzaniabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Tanzania',
      brazilbanktransfer: 'Chuyển khoản nhanh ngân hàng Brazil',
      banktransferbpaypolipayaustralia: 'Chuyển khoản ngân hàng / BPay / PoliPay (Úc)',
      banktransferbpaypolipayinternational: 'Chuyển khoản ngân hàng / BPay / PoliPay (Quốc tế)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Chuyển khoản Ngân hàng Nội địa Canada',
      cryptobitcoin: 'Tiền điện tử-Bitcoin',
      cryptotetheromni: 'Tiền điện tử-Tether(OMNI)',
      cryptotethererc20: 'Tiền điện tử-Tether(ERC20)',
      cryptotethertrc20: 'Tiền điện tử-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Chuyển vào tài khoản',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'TIỀN TỆ',
      LOCAL_TRANSFER: 'CHUYỂN KHOẢN',
      japanbanktransfer: 'Chuyển khoản ngân hàng Nhật Bản',
      internetbankingmexico: 'Ngân Hàng Điện Tử (Mexico)',
      internetbankinguae: 'Ngân hàng trực tuyến (UAE)',
      ewallet: 'Ví điện tử',
      internetbankingtaiwan: 'Ngân hàng trực tuyến (Đài Loan)',
      internetbankingeu: 'Ngân hàng trực tuyến (EU)',
    },
    country: { international: 'Quốc Tế', australia: 'Úc' },
    field: {
      country_region: 'Quốc Gia / Khu Vực',
      address: 'Địa Chỉ ',
      phone: 'Số điện thoại',
      nat: 'Quốc tịch',
      country: 'Quốc gia cư trú',
      save: 'Đầu tư',
      annIncome: 'Thu nhập hàng năm',
      emailAdd: 'Địa chỉ email',
      yes: 'Có',
      no: 'Không',
      enterVerificationCode: 'Nhập mã xác minh',
      ebuyAccName: 'Tên tài khoản EBuy',
      ebuyEmailAddress: 'Địa chỉ email EBuy',
      skrillAccName: 'Tên tài khoản Skrill',
      skrillEmailAddress: 'Địa chỉ email Skrill',
      netellerAccName: 'Tên tài khoản Neteller',
      netellerEmailAddress: 'Địa chỉ email Neteller',
      imptNotes: 'Ghi Chú Quan Trọng',
      paymentAccName: '{name} Tên Tài Khoản',
      paymentAccNum: '{name} Số Tài Khoản',
      documentType: 'loại tài liệu',
      accDigit: 'Số Tài Khoản',
      docID: 'ID Giấy Tờ',
      bankBranchCode: 'Mã Chi Nhánh',
      accType: 'Loại tài khoản',
      accName: 'Tên Tài Khoản',
      accNum: 'Số Tài Khoản',
      bankName: 'Tên Ngân Hàng',
      swiftCode: 'Mã SWIFT',
      bankAddress: 'Địa Chỉ Ngân Hàng',
      bankHolderAddress: 'Địa Chỉ Chủ Tài Khoản',
      bankBeneficiaryName: 'Tên Người Thụ Hưởng',
      bankAccNum: 'Số Tài Khoản Ngân Hàng',
    },
    keys: {
      SUBMIT: 'GỬI',
      FROM: 'TỪ',
      UPDATE: 'CẬP NHẬT',
      TO: 'ĐẾN',
      DATE: 'NGÀY MỞ',
      pleaseSelect: 'Vui lòng chọn',
      amount: 'Số Tiền',
      rebateAccount: 'Tài Khoản Hoa Hồng',
      comingSoon: 'Sẽ sớm ra mắt.',
      tradingHistory: 'Lịch Sử Giao Dịch',
      noData: 'Không có dữ liệu',
      NAME: 'TÊN',
      EMAIL: 'EMAIL',
      ACCTYPE: 'LOẠI TÀI KHOẢN',
      PLATFORM: 'NỀN TẢNG',
      BASECURRENCY: 'TIỀN TỆ CƠ SỞ',
      DATERANGE: 'KHOẢNG THỜI GIAN',
      STATUS: 'TRẠNG THÁI',
      DEST: 'ĐIỂM ĐẾN',
      ACCNUM: 'SỐ TÀI KHOẢN',
      BALANCE: 'SỐ DƯ',
      PROFITLOST: 'LỢI NHUẬN',
      MARGINLVL: 'CẤP MARGIN',
      ACCEQUITY: 'VỐN TÀI KHOẢN',
      CREDIT: 'TÍN DỤNG',
      NONE: 'None',
      ALL: 'Tất Cả',
      actualAmonut: 'số tiền thực tế',
      currency: 'TIỀN TỆ',
      method: 'PHƯƠNG THỨC',
      REGISTRATION: 'NGÀY ĐĂNG KÝ',
      FTD: 'NGÀY FTD',
      CURRENTCAMPAIGN: 'CHƯƠNG TRÌNH HIỆN TẠI',
      blacklisted: 'Danh sách đen',
      FIRST_TRANS_IN: 'CHUYỂN ĐỔI ĐẦU TIÊN VÀO NGÀY',
      createTime: 'Thời gian nộp',
      lastUpdateTime: 'Thời gian xử lý',
    },
    dateRange: {
      today: 'Hôm Nay',
      yesterday: 'Hôm Qua',
      lastSevenDays: '7 ngày trước',
      lastThirtyDays: '30 ngày trước',
      weekToDate: 'Tuần này',
      monthToDate: 'Tháng này',
      quarterToDate: 'Quý này',
      previousWeek: 'Tuần Trước',
      previousMonth: 'Tháng Trước',
      previousQuarter: 'Qúy Trước',
    },
    products: {
      forex: 'NGOẠI HỐI',
      commodities: 'HÀNG HÓA',
      indices: 'CHỈ SỐ',
      crypto: 'TIỀN ĐIỆN TỬ',
    },
    formValidation: {
      dynamicReq: '{dynamic} là bắt buộc',
      verCodeReq: 'Mã xác minh bắt buộc',
      alphanumericReq: 'Vui lòng nhập chữ và số',
      answerReq: 'Vui lòng chọn',
      sameAsPOA: 'Thông tin phải giống như trên POA',
      sameAsID: 'Thông tin phải giống như trên ID',
      notBlank: 'Để trống nếu không áp dụng',
      digits12WithoutDash: 'Vui lòng nhập 12 chữ số, không có dấu "-"',
      enterAsPerId: 'Nhập theo ID',
      numberReq: 'Vui lòng nhập số',
      addressReq: 'Vui lòng nhập địa chỉ của bạn',
      suburbReq: 'Vui lòng nhập thành phố hoặc vùng ngoại ô',
      stateReq: 'Tiểu bang hoặc tỉnh là bắt buộc',
      postcodeReq: 'Vui lòng nhập mã bưu điện của bạn',
      countryReq: 'Vui lòng chọn quốc gia cư trú của bạn',
      AlphanumericOnlyNotSpace: 'Chỉ chấp nhận chữ cái và số. Không cho phép khoảng trắng.',
      AlphanumericOnly: 'Chỉ chấp nhận chữ cái và số.',
      NumbersSymbol: 'Chỉ chấp nhận số và ký tự.',
      NumbersOnlyNotSpace: 'Chỉ chấp nhận số. Không cho phép khoảng trắng.',
      NumbersOnly: 'Chỉ chấp nhận số.',
      LettersSymbol: 'Chỉ chấp nhận chữ cái và ký tự.',
      LettersSymbolNotSpace: 'Chỉ chấp nhận chữ cái và ký tự. Không cho phép khoảng trắng.',
      LettersOnlyNotSpace: 'Chỉ chấp nhận chữ cái. Không cho phép khoảng trắng.',
      NotSpace: 'Không được phép sử dụng khoảng trắng.',
    },
  },
  report: {
    title: { rebateReport: 'Báo Cáo Hoa Hồng' },
    rebate: {
      column: {
        name: 'TÊN',
        accountNumber: 'SỐ TÀI KHOẢN',
        accountType: 'LOẠI TÀI KHOẢN',
        volume: 'Khối Lượng',
        rebate: 'Hoa Hồng',
        totalRebate: 'TỔNG HOA HỒNG',
        date: 'NGÀY',
        instrument: 'SẢN PHẨM GIAO DỊCH',
      },
      dropdown: {
        allInstruments: 'Tất cả sản phẩm',
        all: 'Tất Cả',
        instruments: 'SẢN PHẨM GIAO DỊCH',
      },
      others: {
        rebate: 'HOA HỒNG',
        instrumentsTraded: 'SẢN PHẨM ĐÃ GIAO DỊCH',
        lots: 'Lô',
        totalRebate: 'TỔNG HOA HỒNG',
        rebates: 'Hoa Hồng',
      },
    },
  },
  ibReport: {
    header: 'Báo Cáo IB',
    netFunding: 'TIỀN NẠP RÒNG',
    deposits: 'Nạp Tiền',
    withdraw: 'Rút Tiền',
    openedAccs: 'TÀI KHOẢN ĐÃ MỞ',
    funding: 'VỐN',
    withdrawals: 'RÚT TIỀN',
    appliedAmount: 'số tiền áp dụng',
    depositAmount: 'Số tiền đặt cọc',
    withdrawalAmount: 'Số tiền rút',
    timeLimitMessage:
      'Phạm vi truy vấn tối đa để tải xuống là 90 ngày, vui lòng cập nhật phạm vi ngày mới',
    successInfo: 'Tải xuống thành công',
    download: 'TẢI XUỐNG',
    comfirm: 'Xác nhận',
    defaultTimeMessage: 'Phạm vi truy vấn tối đa để tải xuống là 90 ngày',
  },
  lead: { header: 'LEADS', ibAccount: 'Tài Khoản IB', demoAccounts: 'TÀI KHOẢN DEMO' },
  pendingAccounts: {
    header: 'TÀI KHOẢN TẠM NGƯNG',
    errorDoc:
      'Những người nộp đơn sau đây đã điền vào mẫu đơn trực tuyến nhưng chưa cung cấp bằng chứng nhận dạng (xác nhận ID) để hoàn tất tài khoản của họ.',
    acceptedDocType: 'Các hình thức ID được chấp nhận bao gồm:',
    primaryDoc: 'Chứng Minh Nhân Dân',
    passport: '1 bản copy hộ chiếu (Trang có chữ ký)',
    id: '1 bản copy thẻ căn cước',
    dl: '1 bản copy giấy phép lái xe vẫn còn hạn sử dụng',
    address: 'ID thứ hai',
    addressList: [
      'Bản sao Hóa đơn Tiện ích gần đây (không quá 6 tháng)',
      'Bản sao sao kê thẻ tín dụng (không quá 6 tháng)',
      'Bản sao bảng sao kê ngân hàng (không quá 6 tháng)',
    ],
    tip: 'Vui lòng khuyến khích người nộp đơn cung cấp bản sao của MỘT trong số các tài liệu ID chính và phụ được chấp nhận cho',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'LỊCH SỬ GIAO DỊCH',
    rebateHistory: 'LỊCH SỬ HOA HỒNG',
    withdrawHistory: 'LỊCH SỬ RÚT TIỀN',
    transferHistory: 'LỊCH SỬ CHUYỂN TIỀN',
    cancelWithdraw: 'Bạn có muốn Hủy Yêu cầu Rút tiền không?',
    cancelError:
      'Việc rút tiền của bạn đang được xử lý vào lúc này. Vì vậy, chúng tôi không thể chấp nhận yêu cầu hủy của bạn. Để biết thêm thông tin, bạn có thể liên hệ với chúng tôi bằng cách sử dụng tính năng trò chuyện trực tiếp.',
  },
  fileUploader: {
    supportedFormat: 'Các loại tệp được hỗ trợ: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Các loại tệp được hỗ trợ: {files}',
    maxSize: 'Kích thước tệp tải lên tối đa: {maxSize} MB',
    maxFileNum: 'Số tệp tối đa: {maxFileNum}',
    selectFile: 'Chọn tập tin',
    maxFileSize: 'Kích thước tệp không được vượt quá {maxSize}MB',
    sureToDelete: {
      title: 'Bạn có chắc chắn',
      content: 'Bạn có chắc chắn muốn xoá tệp đã tải lên?',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'Đang Xử Lý',
      paid: 'Đã thanh toán',
      unpaid: 'Chưa thanh toán',
      rejected: 'Từ Chối',
    },
  },
  referralLinks: {
    header: 'LINKS GIỚI THIỆU',
    ibAcc: 'Tài Khoản IB',
    campLang: 'Ngôn ngữ chiến dịch',
    cpy: 'SAO CHÉP',
    alreadyCPY: 'ĐÃ SAO CHÉP',
    liveAcc: 'Tài Khoản Live',
    demoAcc: 'Tài Khoản Demo',
    homePage: 'Trang Chủ',
    Share: 'Cổ phiếu',
    subIbReferral: 'Giới thiệu Sub IB',
  },
  settings: { ibProfile: 'HỒ SƠ IB', info: 'THÔNG TIN', reg: 'ĐÃ ĐĂNG KÝ TRÊN' },
  myProfile: {
    changeDetails:
      'Nếu bạn muốn cập nhật chi tiết hồ sơ hiện tại của mình, đừng ngần ngại liên hệ với {mailTo}',
  },
  unfundedAcc: {
    header: 'TÀI KHOẢN CHƯA NẠP TIỀN',
    allActivationAccs: 'TẤT CẢ TÀI KHOẢN KÍCH HOẠT',
    neverFundedAccs: 'TÀI KHOẢN CHƯA TỪNG NẠP TIỀN',
    prevFundedZero: 'SỐ DƯ LÀ 0',
  },
  multiib: { level: 'CẤP ĐỘ {level}', noib: 'Không có IB cấp dưới của tài khoản này' },
  header: { bckToCP: 'TRỞ LẠI CỔNG KHÁCH HÀNG', logout: 'ĐĂNG XUẤT' },
  menu: {
    dashboard: 'Bảng Điều Khiển',
    ibReport: 'Báo Cáo IB',
    rebateReport: 'Báo Cáo Hoa Hồng',
    accManagement: 'TÀI KHOẢN',
    iblevel: 'ĐA CẤP ĐỘ',
    ibAccs: 'TÀI KHOẢN ĐÃ KÍCH HOẠT',
    leads: 'LEADS',
    pendingAccs: 'TÀI KHOẢN TẠM NGƯNG',
    unfundedAccs: 'TÀI KHOẢN CHƯA NẠP TIỀN',
    payHistory: 'LỊCH SỬ GIAO DỊCH',
    ibProfile: 'HỒ SƠ IB',
    refLinks: 'LINKS GIỚI THIỆU',
    contactUs: 'Liên Hệ Với Chúng Tôi',
    switchCP: 'ĐỔI SANG CỔNG KHÁCH HÀNG',
  },
  footer: {
    riskWarn:
      'Cảnh báo Rủi ro: Giao dịch Hợp đồng Chênh lệch (CFD) mang lại mức độ rủi ro cao cho số vốn của bạn và có thể dẫn đến thua lỗ, bạn chỉ nên giao dịch với số tiền mà bạn có thể chịu được. Giao dịch CFD có thể không phù hợp với tất cả các nhà đầu tư, vui lòng đảm bảo rằng bạn hiểu đầy đủ các rủi ro liên quan và thực hiện các biện pháp thích hợp để quản lý nó. Vui lòng đọc kỹ tài liệu Tiết lộ Rủi ro có liên quan.',
  },
  responseMsg: {
    410: 'Xác thực thông số không thành công',
    411: 'Mật khẩu không đúng',
    420: 'Người dùng không tồn tại',
    421: 'Đặt lại mật khẩu bị từ chối',
    431: 'Hoa hồng đang còn trong quá trình tính toán, vui lòng thực hiện lại yêu cầu của quý vị sau một lúc nữa',
    490: 'Vui lòng không gửi thông tin thẻ trùng lặp',
    500: 'Một lỗi đã xảy ra.',
    501: 'Đăng ký thất bại. Vui lòng thử lại sau.',
    505: 'Tải lên tệp không thành công',
    520: 'Đăng nhập thất bại. Vui lòng thử lại.',
    521: 'Một người dùng đã đăng nhập vào thiết bị này!',
    522: 'Xin vui lòng đăng nhập lại',
    523: 'Xin vui lòng đăng nhập lại',
    524: 'Thật không may, địa chỉ email của bạn không tìm thấy.',
    525: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    527: 'Người dùng không phải là một IB',
    528: 'Người dùng không tồn tại',
    529: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    530: 'Phiên đăng nhập không hợp lệ',
    540: 'Không thể tìm thấy tài khoản giao dịch của bạn ',
    541: 'Không thể tìm thấy tài khoản nhận hoa hồng',
    542: 'Bạn không được phép mở thêm tài khoản',
    544: 'Bạn không được phép đăng ký thêm tài khoản cho đến khi Bằng chứng ID của bạn được xác minh',
    550: 'Số tài khoản không chính xác',
    551: 'Số dư của bạn không đủ',
    553: 'Bạn không thể rút tiền do khoản tín dụng trong tài khoản của bạn. Vui lòng liên hệ với {email} để được trợ giúp thêm',
    554: 'Số lượng yêu cầu là 0 hoặc trống',
    564: 'Quốc gia không khớp với kênh thanh toán',
    565: 'Mã ngân hàng không chính xác',
    566: 'Không tìm thấy phương thức thanh toán',
    581: 'Bạn đã chọn tham gia chương trình khuyến mãi thành công. Xin lưu ý rằng bạn phải đặt cọc để rút tiền.',
    582: 'Bạn đang tham gia sự kiện và có các chức vụ nên không thể gửi đơn rút tiền.',
    584: 'Bạn không có bất kỳ khoản hoa hồng nào',
    590: 'Không thể tìm thấy tài khoản chuyển khoản đi',
    591: 'Không thể tìm thấy tài khoản chuyển khoản vào',
    593: 'Chúng tôi không thể xử lý yêu cầu chuyển tiền của bạn từ tài khoản đã chọn do tài khoản của bạn đang chứa tín dụng.',
    594: 'Chuyển tiền từ tài khoản này không được cho phép',
    595: 'Vì bạn đang tham gia vào các hoạt động của chúng tôi, nên không thể xử lý yêu cầu chuyển tiền của bạn vào lúc này.',
    647: 'Mã xác minh không đúng',
    1101: 'Đã có 10 tài khoản, không thể thêm.',
    1102: 'Không thể kích hoạt, tài khoản đã được tạo dưới dạng tài khoản thực hoặc tài khoản demo.',
    1103: 'Tài khoản đã bị vô hiệu hóa',
    1202: 'Mã xác minh đã hết hạn, vui lòng thử lại',
    1204: 'Vui lòng thử lại với địa chỉ email khác',
    1208: 'Không thể gửi mã xác minh lại',
    1209: 'Lỗi gửi mã xác minh',
    session_timeout: 'Qúa thời gian chờ, chuyển hướng đến trang đăng nhập.',
  },
  verificationStatus: {
    title: { default: 'Bạn sắp hoàn thành rồi', LV3: 'Chào mừng' },
    dueToAML:
      'Bạn sẽ sớm có thể bắt đầu giao dịch! Giúp chúng tôi đảm bảo an toàn cho tài khoản của bạn bằng cách xác minh thông tin cá nhân.',
    accOpeningVerification: {
      title: 'Mở tài khoản thực',
      content: 'Mở tài khoản thực để bắt đầu nạp tiền cho tài khoản của bạn.',
    },
    identityVerification: {
      title: 'Xác minh danh tính',
      content: 'Tải lên và xác thực chứng minh danh tính của bạn để bắt đầu giao dịch',
    },
    poaVerification: { title: 'Xác minh địa chỉ', content: 'Cần xác minh POA để có thể rút tiền.' },
    kyc: {
      depositNow: 'Nạp tiền ngay',
      withdrawNow: 'Rút tiền Ngay',
      createNow: 'Tạo ngay',
      start: 'Bắt đầu',
      complete: 'Đã hoàn thành',
      pending: 'Đang chờ xử lý',
      rejected: 'Đã bị Từ chối',
      resubmit: 'Gửi lại',
    },
    resubmitNote: 'Tài liệu của bạn có thể bị thiếu hoặc không chính xác. Vui lòng nộp lại.',
    rejectedNote: 'Bạn có câu hỏi? Vui lòng liên hệ chúng tôi qua {liveChat} .',
    liveChat: 'Trò chuyện Trực tiếp',
    uploadTemplate: {
      idGuideTitle: 'Hướng dẫn Tài liệu ID',
      uploadGuideTitle: 'Tải lên Tài liệu Hướng dẫn',
      desc1: 'Do Chính phủ ban hành',
      desc2: 'Tài liệu có kích cỡ chuẩn, chưa qua chỉnh sửa',
      desc3: 'Đặt tài liệu trên nền đơn sắc',
      desc4: 'Hình ảnh có màu, đủ sáng và đọc được mọi thông tin',
      desc5: 'Không dùng ảnh đen trắng',
      desc6: 'Không dùng tài liệu đã qua chỉnh sửa hoặc quá hạn',
      bottomDesc: 'Tệp tải lên có thể ở dạng jpg, png hoặc pdf, và kích cỡ không vượt quá 5MB',
    },
  },
  register: {
    progressStatus: {
      title: 'Tiến trình xác minh',
      estimatedTimeTitle: 'thời gian dự kiến',
      min: 'phút',
    },
    tabs: {
      personalDetails: 'Thông tin cá nhân',
      moreAboutYou: 'Thêm về bạn',
      accountConfig: 'Cấu hình tài khoản',
      confirmYourId: 'Xác nhận ID của bạn',
      fundYourAcc: 'Nạp tiền vào tài khoản của bạn',
      personalInformation: 'Thông tin cá nhân',
      createLiveAccount: 'Mở tài khoản thực',
      financialInfo: 'Thông tin tài chính',
      iDProof: 'Chứng minh ID',
      addressProof: 'Chứng minh Địa chỉ',
    },
    btn: {
      next: 'TIẾP',
      back: 'QUAY LẠI',
      fundnow: 'NẠP NGAY',
      upload: 'Tải Lên',
      done: 'ĐÃ XONG',
      bTCP: 'Quay lại Cổng thông tin Khách hàng',
      proceedToUpload: 'Tiếp tục tải lên',
      completeID: 'Hoàn thành xác minh ID',
      completePOA: 'Hoàn thành xác minh POA',
      depositViaOther: 'Nạp tiền qua các kênh khác',
      withdrawViaOther: 'Rút tiền qua kênh khác',
    },
    authority: {
      dialog1: 'Bạn phải có một tài khoản thực để truy cập phần này của cổng thông tin khách hàng.',
      dialog2:
        'Mở một tài khoản thực ngay bây giờ để mở khóa toàn quyền truy cập vào cổng khách hàng của bạn, bao gồm một loạt các công cụ giao dịch, chương trình khuyến mãi và nhiều hơn nữa.',
      openLiveAcc: 'mở tài khoản thực',
    },
    demo: {
      congrad: 'Xin chúc mừng!',
      title: 'Tài khoản DEMO MT{platform} 60 ngày của bạn hiện đang hoạt động',
      title2: 'Chi tiết tài khoản DEMO của bạn',
      li: { login: 'Đăng nhập', srv: 'Server', expDate: 'Ngày hết hạn' },
      emailSent: 'Chi tiết đăng nhập cũng đã được gửi đến email đã đăng ký của bạn.',
      dl: 'Tải xuống nền tảng giao dịch MT {platform} của bạn',
      improveQ: 'Bạn có muốn cải thiện cách giao dịch của mình ?',
      improveWay:
        'Đơn giản, hãy mở một tài khoản thực chỉ trong vài phút để mở khóa một loạt các công cụ giao dịch và khuyến mãi độc quyền.',
      proTrade: 'Báo cáo marketing',
      customerService: 'Dịch vụ chăm sóc khách hàng trực tuyến 24/7',
      competitivePromotionals: 'Khuyến mãi cạnh tranh',
      secure: 'Giao dịch an tòan 3D',
      openLiveAcc: 'mở tài khoản thực',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Thông tin cá nhân',
        userTitle: 'Chức danh',
        firstName: 'Tên',
        lastName: 'Họ',
        email: 'E-mail',
        dob: 'Ngày sinh',
        idType: 'Loại giấy tờ chứng minh',
        referral: 'Bạn có được ai giới thiệu ? (không bắt buộc)',
        middleName: 'Tên đệm',
        nationality: 'Quốc tịch',
        phoneNum: 'Số điện thoại',
        date: 'Ngày',
        month: 'Tháng',
        year: 'Năm',
        placeOfBirth: 'Nơi Sinh',
        idNum: 'Số Chứng Minh Nhân Dân',
        passportNum: 'Số Hộ Chiếu',
        driverLicenseNum: 'Mã số Bằng Lái',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Hộ Chiếu',
          idCard: 'Chứng minh nhân dân',
          driverLicense: 'Bằng Lái Xe',
        },
      },
      page2: {
        pageTitle: 'ĐỊA CHỈ THƯỜNG TRÚ',
        mainResidency: 'Quốc gia thường trú',
        streetNumber: 'Số đường',
        streetName: 'Tên đường',
        address: 'Địa Chỉ ',
        provinceOrState: 'Tỉnh / bang',
        cityOrSuburb: 'Thành phố / ngoại ô',
        postcode: 'Mã bưu điện',
        usQuestion:
          'Bạn có phải là Công dân / Thường trú tại Hoa Kỳ vì mục đích thuế hay bạn có bất kỳ liên kết / quan hệ nào của Hoa Kỳ không (ví dụ: Tài khoản ngân hàng, v.v.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'CHI TIẾT LÀM VIỆC VÀ TÀI CHÍNH',
      pageTitle2: 'Kiến thức và kinh nghiệm giao dịch',
      complianceExplain: 'Các câu hỏi sau đây được thu thập để tuân thủ các yêu cầu AML / CTF.',
    },
    accountConfig: {
      pageTitle: 'Cấu hình tài khoản',
      chooseTradingPlatform: 'Chọn Nền Tảng giao dịch',
      chooseAccType: 'Chọn loại tài khoản',
      chooseAccCurrency: 'Chọn loại tiền tệ cho tài khoản',
    },
    confirmYourId: {
      pageTitle: 'Xác nhận ID của bạn',
      description:
        'Là một nhà môi giới ngoại hối được cấp phép, chúng tôi được yêu cầu xác minh danh tính của bạn. Vui lòng tải lên một trong các tài liệu sau đây để xác minh ID và bằng chứng Địa chỉ của bạn. Ngoài ra, bạn có thể gửi email tài liệu xác minh của mình tới {mailTo}',
      poidTitle: 'Bằng chứng nhận dạng',
      porTitle: 'Bằng chứng xác minh cư trú',
      uploadDescription: 'Chọn một loại tài liệu và tải lên',
      note: 'QUAN TRỌNG: Tên trên tài liệu PHẢI hiển thị rõ ràng tên đầy đủ của bạn.',
      pending: { upload: 'Đang chờ tải lên', verification: 'Đang chờ xác minh' },
      poidTypes: {
        photoId: 'Ảnh Chứng Minh Nhân Dân',
        passport: 'Hộ Chiếu',
        drivingLicence: 'Bằng Lái Xe',
      },
      porTypes:
        '\n        <li> Hóa đơn tiện ích </ li>\n       <li> Sao kê ngân hàng </ li>\n      <li> Giấy chứng nhận cư trú </ li>\n      ',
    },
    finishPage: {
      title: '{individualUserName}, cảm ơn bạn đã đăng ký tài khoản trực tuyến {platformName}!',
      contentDefault:
        'Chúng tôi đã nhận được đơn đăng ký của bạn và thiết lập tài khoản trực tiếp của bạn <b> {account} </ b>. <br />\n      Trong giây lát, bạn sẽ nhận được email tại <b> {email} </ b> có chứa thông tin đăng nhập và liên kết để xem trạng thái tài khoản của bạn. <br />',
      contentWithoutId3WithAccount:
        'Tài khoản giao dịch của bạn hiện đang được xử lý và bạn sẽ sớm nhận được email chứa thông tin thêm. <br /><br />\n      Để nhanh chóng theo dõi quá trình xử lý tài khoản giao dịch, bạn có thể nạp tiền cho tài khoản của mình ngay bây giờ và nhận ưu tiên xác nhận.<br />',
      contentWithoutId3WithoutAccount:
        'Tài khoản giao dịch của bạn hiện đang được xử lý và bạn sẽ sớm nhận được email chứa thông tin. <br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Xác minh mở tài khoản',
      personalInformation: {
        narrative: 'Thông tin cá nhân của bạn cần thiết cho quá trình mở Tài khoản Thực',
        firstName: 'Tên',
        middleName: 'Tên đệm',
        lastName: 'Họ',
        gender: 'Giới tính',
        genderList: { male: 'Nam', female: 'Nữ', other: 'Khác' },
        dob: 'Ngày sinh',
        day: 'Ngày',
        month: 'Tháng',
        year: 'Năm',
        mobile: 'Số điện thoại di động',
        countryName: 'Quốc gia cư trú',
        nationality: 'Quốc tịch',
      },
      createLiveAccount: {
        narrative:
          'Hoàn thành một số bước để mở tài khoản và bắt đầu nạp tiền Thêm thông tin về các loại tài khoản của chúng tôi <a href="{link}" target="_blank" alt>ở đây</a>.',
        choose: 'Lựa chọn {dynamic}',
        tradingPlatform: 'Nền tảng giao dịch',
        accountType: 'loại tài khoản',
        accountCurrency: 'loại tiền tệ của tài khoản',
        islamicTooltip:
          '{platform} có thể yêu cầu bổ sung thêm các tài liệu nhằm xác minh bạn theo Đạo Hồi. {platform}  có quyền được điều chỉnh cấu hình tài khoản của bạn nếu quá trình xác minh thất bại.',
        successInfo: {
          title: 'Tài khoản giao dịch đã được tạo',
          content:
            'Xin chúc mừng Bạn đã mở tài khoản thành công. <br /> Để bắt đầu giao dịch, vui lòng nạp tiền và hoàn tất xác minh ID của bạn.',
        },
        successInfo2: {
          title: 'Tài khoản giao dịch đã được tạo',
          content: 'Điền thông tin tài chính để bắt đầu các bước xác minh tài khoản',
          start: 'Bắt đầu',
        },
        generalInfo: {
          title: 'Thông tin của bạn đang được xét duyệt.',
          content:
            'Cảm ơn đã mở tài khoản với {platform}. Bạn có thể bắt đầu nạp tiền vào tài khoản khi đăng kí được phê duyệt',
        },
        rejectedInfo: {
          title: 'Đã bị Từ chối',
          content:
            'Chúng tôi rất tiếc phải thông báo rằng yêu cầu mở tài khoản {platform} của bạn đã bị từ chối.',
        },
      },
    },
    identityVerification: {
      title: 'Xác minh danh tính',
      subTitle: 'Thông tin danh tính',
      narrative: 'Điền thông tin',
      firstName: 'Tên',
      middleName: 'Tên đệm',
      lastName: 'Họ',
      nationality: 'Quốc tịch',
      idType: 'Loại ID',
      idSerialNumber: 'Số ID',
      passportNum: 'Số Hộ Chiếu',
      driverLicenseNum: 'Mã số Bằng Lái',
      upload: {
        title: 'Tải lên Tài liệu',
        desc: 'Đảm bảo các tài liệu thể hiện rõ ảnh, tên đầy đủ, ngày sinh và ngày cấp/phát hành.',
        guideline: 'Tải lên Tài liệu Hướng dẫn',
        positive: 'Tải lên thành công',
        signaturePage: 'Tải lên trang chữ ký',
      },
      idTypeDropdown: {
        passport: 'Hộ Chiếu',
        idCard: 'Chứng minh nhân dân',
        driverLicense: 'Bằng Lái Xe',
        other: 'Khác',
      },
      additionalInfo: {
        title: 'Yêu cầu bổ sung thông tin',
        content: 'Xác minh danh tính để bắt đầu giao dịch',
      },
      successInfo: {
        title: 'Hoàn thành xác minh ID',
        content:
          'Xin chúc mừng ID của bạn đã được xác minh Bạn có thể bắt đầu giao dịch trên MT4/MT5 hoặc ứng dụng {platform} nếu bạn đã hoàn tất nạp tiền.',
      },
      generalInfo: {
        title: 'Thông tin của bạn đang được xét duyệt.',
        content:
          'Cảm ơn đã mở tài khoản với {platform}. Bạn có thể bắt đầu giao dịch khi quá trình xác danh tính hoàn tất',
      },
      rejectedInfo: {
        title: 'Đã bị Từ chối',
        content:
          'Chúng tôi rất tiếc phải thông báo rằng yêu cầu mở tài khoản {platform} của bạn đã bị từ chối.',
      },
      identifyingRequiredInfo: {
        title: 'Yêu cầu bổ sung thông tin',
        content:
          'Một số tài liệu xác minh của bạn bị thiếu hoặc không chính xác. Vui lòng tải lên lại tài liệu để tiếp tục.',
      },
      streetNumber: 'Số đường',
      streetName: 'Tên đường',
    },
    poaVerification: {
      proofOfAddressVer: 'Xác minh địa chỉ',
      subTitle: 'Thông tin địa chỉ',
      narrative: 'Điền thông tin',
      dueToAML:
        'Do quy định AML, địa chỉ của bạn phải được xác minh trước khi thực hiện yêu cầu rút tiền.',
      nationality: 'Quốc tịch',
      country: 'Quốc gia cư trú',
      address: 'Địa chỉ',
      postalCode: 'Mã bưu cục',
      citySuburb: 'Thành phố',
      city: 'Thành phố',
      provinceState: 'Tỉnh/Bang',
      upload: {
        title: 'Chứng nhận POA',
        inst1: 'Hoá đơn tiện ích (Điện, nước, ga)',
        inst2: 'Sao kê ngân hàng hoặc thẻ tín dụng',
        desc: 'Thông tin POA để yêu cầu rút tiền phải được phát hành trong vòng {months} tháng trở lại đây',
        guideline: 'Tải lên Tài liệu Hướng dẫn',
        uploadFilesPhoto: 'Tải lên Tệp/Ảnh',
      },
      additionalInfo: {
        title: 'Yêu cầu bổ sung thông tin',
        content: 'Cần xác minh POA để có thể rút tiền.',
      },
      successInfo: {
        title: 'Đã xác minh Chứng minh địa chỉ',
        content:
          'Xin chúc mừng POA của bạn đã được xác minh Bạn giờ đây có thể thực hiện yêu cầu rút tiền.',
      },
      generalInfo: {
        title: 'Thông tin của bạn đang được xét duyệt.',
        content:
          'Cảm ơn bạn đã giao dịch với {platform}. Bạn có thể bắt đầu quá trình rút tiền sau khi đệ trình của bạn được phê duyệt.',
      },
      identifyingRequiredInfo: {
        title: 'Yêu cầu bổ sung thông tin',
        content:
          'Một số tài liệu xác minh của bạn bị thiếu hoặc không chính xác. Vui lòng tải lên lại tài liệu để tiếp tục.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Thông tin Tài chính của bạn',
        subTitle: 'Thông tin nghề nghiệp và tài chính',
        subTitle2: 'Kiến thức và Kinh nghiệm giao dịch',
        employmentStatus: 'Tình trạng nghề nghiệp',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} mỗi tuần',
        estimatedAnnualIncome: 'Thu nhập thường niên ước tính',
        estimatedSavingsAndInvestments: 'Khoản tiết kiệm và đầu tư ước tính',
        estimatedIntendedDeposit: 'Khoản tiền nạp ước tính',
        sourceOfFunds: 'Nguồn tiền nạp',
        numberOfTrades: 'Tổng số giao dịch',
        tradingAmount: 'Số tiền giao dịch',
      },
      iDProof: {
        ver: 'Tải lên Chứng minh Danh tính',
        subTitle: 'Thông tin danh tính',
        narrative: 'Điền thông tin',
      },
      addressProof: {
        ver: 'Tải lên Chứng minh Địa chỉ (POA)',
        subTitle: 'Thông tin địa chỉ',
        narrative: 'Điền thông tin',
        upload: {
          title: 'Tài liệu Chứng minh Địa chỉ đã được phê duyệt',
          inst1: 'Hoá đơn tiện ích (Điện, nước, ga)',
          inst2: 'Sao kê ngân hàng hoặc thẻ tín dụng',
          desc: 'Thông tin POA để yêu cầu rút tiền phải được phát hành trong vòng {months} tháng trở lại đây',
          guideline: 'Tải lên Tài liệu Hướng dẫn',
          uploadFilesPhoto: 'Tải lên Tệp/Ảnh',
        },
      },
      successInfo: {
        title: 'Xác thực Ngân hàng đã hoàn tất',
        content: 'Xin chúc mừng Bạn đã xác thực thông tin Ngân hàng thành công.',
      },
      generalInfo: {
        title: 'Thông tin của bạn đang được xét duyệt.',
        content:
          'Tài liệu chuyển khoản ngân hàng của bạn đang được xem xét. Nếu bạn muốn bắt đầu giao dịch ngay bây giờ, bạn vẫn có thể thực hiện một khoản tiền gửi qua kênh khác.',
      },
      rejectedInfo: {
        title: 'Đã bị Từ chối',
        content: 'Yêu cầu nạp tiền qua chuyển khoản ngân hàng của bạn đã bị từ chối.',
      },
      identifyingRequiredInfo: {
        title: 'Yêu cầu bổ sung thông tin',
        content:
          'Một số tài liệu xác minh của bạn bị thiếu hoặc không chính xác. Vui lòng tải lên lại tài liệu để tiếp tục.',
      },
    },
  },
  ibProfile: {
    title: 'HỒ SƠ IB',
    subTitle: 'IB đồng sở hữu',
    sharedIbList: {
      sharedIbAccount: 'Tài khoản IB đồng sở hữu',
      maxSharedIbAccount: 'Tối đa 10 tài khoản IB đồng sở hữu',
      add: 'Thêm vào',
      accountHistory: 'Lịch sử tài khoản IB đồng sở hữu',
      setting: 'Cài đặt',
      delete: 'Xóa bỏ',
      enable: 'Cho phép',
      disable: 'Vô hiệu hóa',
      enabled: 'Đã bật',
      disabled: 'Tàn tật',
      sharedAccountEnabled: 'Tài khoản IB đồng sở hữu đã được kích hoạt.',
      sharedAccountDisabled: 'Tài khoản IB đồng sở hữu đã bị vô hiệu hóa.',
      sharedAccountCopied: 'Đã sao chép tài khoản IB đồng sở hữu',
      deleteTips:
        'Tài khoản IB đồng sở hữu sau khi xóa sẽ không đăng nhập được, bạn có muốn xóa không?',
      sharedAccountDeleted: 'Tài khoản IB đồng sở hữu đã bị xóa.',
      copyTips: 'Đã sao chép tài khoản IB đồng sở hữu',
      remark: 'Nhận xét',
      cancel: 'Hủy bỏ',
      accountData: 'Dữ liệu tài khoản',
      IBData: 'Dữ liệu báo cáo IB',
      fundOperations: 'Quyền truy cập vốn',
      resetPassword: 'Đặt lại mật khẩu',
      accountHistoryBtn: 'Lịch sử tài khoản đồng sở hữu',
    },
    updateSharedIb: {
      addNewSharedIb: 'Thêm tài khoản IB đồng sở hữu mới',
      editSharedIb: 'Chỉnh sửa tài khoản IB đồng sở hữu',
      maxSharedIbAccountError: '10 tài khoản tồn tại, không thể thêm.',
      email: 'Địa chỉ email',
      emailValidate: 'Vui lòng nhập địa chỉ email hợp lệ',
      emailError: 'Email đã tồn tại, vui lòng thử email khác',
      password: 'Mật khẩu',
      passwordValidate: 'Xin vui lòng nhập mật khẩu',
      passwordValidateNum: '8-16 ký tự',
      passwordValidateTips:
        'Kết hợp chữ hoa và chữ thường (A-Z, a-z), số (0-9) và các ký tự đặc biệt (ví dụ: !@#S%^&)',
      passwordValidateWarning:
        '8-16 ký tự,Kết hợp chữ hoa và chữ thường (A-Z, a-z), số (0-9) và các ký tự đặc biệt (ví dụ: !@#S%^&)',
      sharedIbAccountPermission: 'Quyền tài khoản IB đồng sở hữu',
      remark: 'Nhận xét',
      remarkTips: 'Bạn có thể ghi lại mục đích tài khoản hoặc chủ tài khoản',
      status: 'Trạng thái',
      cancel: 'Hủy bỏ',
      confirm: 'Xác nhận',
      disabled: 'Vô hiệu hóa',
      enabled: 'Đã kích hoạt',
      resetPassword: 'Đặt lại mật khẩu',
      emailPlaceholder: 'Vui lòng nhập email',
      addSuccess: 'Đã tạo tài khoản đồng sở hữu',
      permissionIdsTips:
        'Bạn có thể cho phép các quyền sau đối với tài khoản đồng sở hữu hoặc không:',
      permissionIdsFirst:
        '<b>Dữ liệu tài khoản</b>: Xem trang dữ liệu tài khoản (Tài khoản IB, Tài khoản mới mở). Dữ liệu sẽ bị ẩn nếu không được phép.',
      permissionIdsSecond:
        '<b>Dữ liệu báo cáo IB</b>: Xem các trang dữ liệu IB (Bảng điều khiển, IB đa cấp - Tài khoản). Dữ liệu sẽ bị ẩn nếu không được phép.',
      permissionIdsThird:
        '<b>Quyền truy cập vốn</b>: Vận hành vốn IB (Áp dụng với chiết khấu, Chuyển tiền chiết khấu, Rút tiền chiết khấu), Xem giảm giá (Báo cáo giảm giá, số dư và hồ sơ quỹ). Các hoạt động không thể được thực hiện nếu không được phép.',
      newPassword: 'Vui lòng nhập mật khẩu mới',
      newPassowrdReset: 'Vui lòng nhập mật khẩu mới',
      passwordSame: 'Mật khẩu mới không được giống mật khẩu cũ',
      resetPasswordSuccess: 'Đặt lại mật khẩu thành công',
      enterNewPassword: 'Vui lòng nhập mật khẩu mới',
      changePasswordTips: 'Mật khẩu cũ sẽ không hợp lệ và được đặt lại mật khẩu mới',
      fundsChangeTips:
        'Sau khi được phép truy cập vốn, tài khoản IB đồng sở hữu có thể chuyển và rút số dư trong tài khoản IB chính của bạn. Bạn có muốn cho phép điều này?',
      editSuccess: 'Đã sửa đổi thành công',
    },
    sharedIbHistory: {
      allHistory: 'Tất cả lịch sử đã được hiển thị',
      lastUpdate: 'Cập nhật mới nhất',
      refreshTips: 'Đã cập nhật lịch sử',
      refresh: 'Làm mới',
      chooseAccount: 'Chọn tài khoản',
      device: 'Thiết bị',
      login: 'Đăng nhập',
      commission: 'Áp dụng chiết khấu',
      withdraw: 'Rút tiền chiết khấu',
      transfer: 'Chuyển tiền chiết khấu',
      fromTo: 'Từ {fromAccount} đến {toAccount}, số tiền {total}',
      viewMore: 'Xem thêm',
    },
    npPermissionTips: 'Không có quyền cho tài khoản này',
  },
  agreement: {
    title: 'Xác nhận Thỏa thuận Giới thiệu Nhà môi giới',
    rebateAccount: 'Tài khoản hoàn tiền',
    description: 'Bằng cách nhấp vào Đồng ý, bạn xác nhận rằng bạn đã đọc, hiểu và đồng ý với tất cả các thông tin, điều khoản và điều kiện dịch vụ trong thỏa thuận',
    agree: 'Đồng ý',
    signed: 'Rebate account {accountID}. Signed at {signedTime}',
    signed: 'Tài khoản hoàn tiền {accountID}. Ký ngày {signedTime}',
    IBAgreement: 'Thỏa thuận Giới thiệu Nhà môi giới',
    confirmAgreement: 'Vui lòng liên hệ với Quản lý Giới thiệu Nhà môi giới để xác nhận thỏa thuận'
  }
};
