import axios from 'axios'
import { Message } from 'element-ui'
import { startLoading, endAjaxLoading } from '@/util/loading'
import respHandler from './respHandler'
import DOMPurify from 'dompurify'
import allowedHtmlTag from '@/util/allowedHtmlTag'
import qs from 'qs'
import { partialRequest } from '@/resource'
import store from '@/store'

const instance = axios.create()
const instanceExternal = axios.create()

const iterateData = obj => {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      iterateData(obj[key])
      return
    }
    if (typeof obj[key] === 'string') {
      obj[key] = DOMPurify.sanitize(obj[key], { ALLOWED_TAGS: allowedHtmlTag })
    }
  })
}

const getRelatedAuths = url => {
  const requiredAuths = store.state.common.requiredAuths
  switch (url) {
    case '/api/withdrawal/applyWithdrawal':
      return requiredAuths['withdraw']
  }
}

export const getLocalLoading = url => {
  return partialRequest.includes(url.split('?')[0])
}

// 修改post请求数据格式
instance.interceptors.request.use(
  async config => {
    if(!getLocalLoading(config.url)) startLoading('ajax');
    let headerValues = store.state.common.authHeaders
    const requiredAuths = (await getRelatedAuths(config.url, config.data)) || []
    headerValues = {
      etxId: requiredAuths.filter(item => item == 'email').length > 0 ? headerValues.etxId : '',
      eCode: requiredAuths.filter(item => item == 'email').length > 0 ? headerValues.eCode : '',
      tCode: requiredAuths.filter(item => item == 'totp').length > 0 ? headerValues.tCode : '',
      ptxId: headerValues?.ptxId,
      pCode: headerValues?.pCode,
    }

    config.headers['Multi-Auth-Validate'] = [
      headerValues.etxId,
      headerValues.eCode,
      headerValues.tCode,
      headerValues.ptxId,
      headerValues.pCode,
    ]

    config.withCredentials = true

    if (config.method === 'post') {
      const data = config.data

      if (typeof data === 'string') {
        const parseData = qs.parse(data)
        iterateData(parseData)
        config.data = DOMPurify.sanitize(qs.stringify(parseData), { ALLOWED_TAGS: allowedHtmlTag })
      }

      if (typeof data === 'object') {
        iterateData(config.data)
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 修改post请求数据格式
instance.interceptors.response.use(
  resp => respHandler(resp),
  function(error) { 
    console.log(error)
    // 401 鉴权处理，需要正常的提示message
    if (error.response && error.response.status === 401 ) {
      return Promise.resolve(respHandler(error.response))
    } else {
      // 不确定下面代码是否有特殊作用，先保留
      Message({
        type: 'error',
        message:
          error.response && error.response.data && error.response.data.length <= 50
            ? error.response.data
            : 'Error occurred while processing your request.',
      })
    }
    
    endAjaxLoading()

    return Promise.reject(error.response)
  }
)

export { instance, instanceExternal }
